import { AfterViewInit, Component, OnInit } from '@angular/core';
import { SidenavExtendService } from 'src/app/core/services/sidenav-extend/sidenav-extend.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NavigationFacade } from 'src/app/core/redux/navigation/facades/navigation-facade';

@UntilDestroy()
@Component({
  selector: 'app-unitec-shield',
  templateUrl: './unitec-shield.component.html',
  styleUrls: ['./unitec-shield.component.scss']
})
export class UnitecShieldComponent implements OnInit, AfterViewInit {

  sidebarOpened = true;

  constructor(
    private navigationFacade: NavigationFacade
  ) { }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.navigationFacade.sidebarOpened$.pipe(untilDestroyed(this)).subscribe(opened => this.sidebarOpened = opened);
  }

}
