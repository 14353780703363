import { Component, Input, OnInit, ViewChild, Renderer2, AfterViewChecked, EventEmitter, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { INavigationNode, INavigationNodeModel } from '../../../../core/redux/navigation/interfaces/inavigation-node';
import { NavigationFacade } from '../../../../core/redux/navigation/facades/navigation-facade';
import { ActionType, INavigationNodeAdditionalData, INavigationNodeData, INodeTimer } from '../../../../core/redux/navigation/interfaces/inavigation-node-data';
import { animateText, animateFadeInOut } from 'src/app/ui/animations/animations';
import { SidenavExtendService } from 'src/app/core/services/sidenav-extend/sidenav-extend.service';
import { Observable, Subscription } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { map, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MenuMessageComponent } from '../menu-message/menu-message.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IUserData } from 'src/app/core/redux/user/interfaces/iuser-data';
import { UserFacade } from 'src/app/core/redux/user/facades/user-facade';
import { INavigationTree } from 'src/app/core/redux/navigation/interfaces/inavigation-tree';
import * as _ from 'lodash';

@UntilDestroy()
@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
  animations: [animateText, animateFadeInOut]
})
export class NavItemComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

  actionTypes = ActionType;
  @Input('node') public node: INavigationNodeModel;
  @Input('parent') public parent: INavigationNodeModel;
  @Output('opened') opened: EventEmitter<INavigationNodeModel> = new EventEmitter<INavigationNodeModel>();
  @Output('activated') activated: EventEmitter<INavigationNodeModel> = new EventEmitter<INavigationNodeModel>();
  rules: any;

  loadMobileContent = false;
  isExtended = false;

  isHandset$: Observable<boolean> =
    this.breakpointObserver.observe(
      [Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.TabletPortrait,
      Breakpoints.HandsetPortrait,
      Breakpoints.Handset])
      .pipe(map(result => result.matches));


  modulesList: Array<any>;
  enteredButton = false;
  isMatMenuOpen = false;
  isMatMenu2Open = false;
  prevButtonTrigger;

  levelOneProp: any;
  levelTwoProp: any;
  levelThreeProp: any;
  buttonProp: any;

  // 1 menu extendido y no mobile
  // 2 menu no extendido y no mobile
  // 3 menu no mobile
  displayContent: number = 0;

  show = false;

  @ViewChild('levelOneTrigger') levelOneTrigger: any;
  @ViewChild('button') button: any;

  profile: IUserData;

  activeChildrenNodes: INavigationNodeModel[];
  hidenChildrenNodes: INavigationNodeModel[];

  ett: NodeJS.Timeout;
  stnat: NodeJS.Timeout;
  etnat: NodeJS.Timeout;
  menuSub: NodeJS.Timeout;
  actualActionData: INavigationNodeAdditionalData;
  actualAction: ActionType;

  childrenEtt: INodeTimer[] = [];

  watcher: Subscription;
  sidebarOpened = true;

  constructor(
    private router: Router,
    private navigationFacade: NavigationFacade,
    private sidenavExtendService: SidenavExtendService,
    private breakpointObserver: BreakpointObserver,
    private ren: Renderer2,
    public dialog: MatDialog,
    private userFacade: UserFacade,
  ) {
    this.userFacade.profile$.subscribe(profile => this.profile = profile);
  }
  ngOnDestroy(): void {

  }
  ngAfterViewInit(): void {
    this.navigationFacade.current$.pipe(untilDestroyed(this)).subscribe(current => this.validateActiveNode(current));
    this.navigationFacade.sidebarOpened$.pipe(untilDestroyed(this)).subscribe(opened => this.sidebarOpened = opened);
  }

  ngOnInit() {
    //console.log('ngOnInit', this.node.id, this.node.getDisplayText());
    this.activeChildrenNodes = this.node.children.filter(node => node.action != ActionType.hide);
    this.hidenChildrenNodes = this.node.children.filter(node => node.action == ActionType.hide);

    this.sidenavExtendService.extend$.pipe(untilDestroyed(this)).subscribe(isExtended => {
      this.isExtended = isExtended;
      if (!this.isExtended && this.parent) {
        this.close();
      }
      this.selectComponent();
    });
    this.watcher = this.breakpointObserver.observe(
      [Breakpoints.XSmall,
      Breakpoints.Handset])
      .pipe(map(result => result.matches)).subscribe(mobile => {
        this.loadMobileContent = mobile;
        if (this.loadMobileContent) {
          this.sidenavExtendService.extend();
        }
        this.selectComponent();
      });
    this.setScheduleHandler();
    this.middlewareHidenNodes();
  }

  ngAfterViewChecked() {
    this.show = true;
    if (this.node.hasStyle()) {
      let ele = document.getElementById(this.node.id);
      if (ele != null && typeof ele !== 'undefined')
        ele.setAttribute('style', this.node.getStyle());
    }
  }

  validateActiveNode(current: INavigationNode) {
    if (this.node.id == current.id) {
      if (!this.node.isDisabled() && !this.node.hasChildren() && !this.node.hasExternalUrl()) {
        this.node.active();
        if (this.parent) {
          this.parent.open();
        }
      }
    } else {
      this.node.desactive();
      this.validateActivateNodeChildren(this.node, current);
    }
  }

  validateActivateNodeChildren(node: INavigationNodeModel, current: INavigationNode) {

    if (node.hasChildren()) {
      for (let i = 0; i < node.children.length; i++) {
        if (node.children[i].id == current.id) {
          if (!node.children[i].isDisabled() && !node.children[i].hasChildren() && !node.children[i].hasExternalUrl()) {
            node.children[i].active();
            this.node.open();
            break;
          }
        } else {
          this.validateActivateNodeChildren(node.children[i], current);
        }
      }
    }
  }

  setScheduleHandler() {
    if (this.node.getScheduledEndTime()) {
      let time = this.node.getScheduledEndTime().split(':');
      let currentDate = new Date();
      let endTime = new Date();
      endTime.setHours(Number(time[0]), Number(time[1]), Number(time[2]));
      if (endTime.getTime() <= currentDate.getTime()) {
        console.log('NO SE APLICA REGLA [ScheduledEndTime]');
        return;
      }
      let executeTime = endTime.getTime();
      let currentTime = (new Date()).getTime();
      //console.log(`menu ${this.node.id} should ${this.node.getNextAction()} in ${executeTime} from ${currentTime} so in ${(executeTime - currentTime) / 1000} `);
      if (this.node.hasNextAction()) {
        if (this.ett) {
          clearTimeout(this.ett);
        }
        this.ett = setTimeout(() => {
          this.executeNextStatus(this.node.getNextAction(), this.node.getNextActionData());
        }, (executeTime - currentTime))
      }

    } else {
      if (this.node.hasNextAction()) {
        if (this.node.getNextActionScheduledStartTime()) {
          //console.log(`menu ${this.node.id} `, this.node.getNextActionScheduledStartTime());
          let time = this.node.getNextActionScheduledStartTime().split(':');
          let currentDate = new Date();
          let startTime = new Date();
          startTime.setHours(Number(time[0]), Number(time[1]), Number(time[2]));
          if (startTime.getTime() <= currentDate.getTime()) {
            console.log('NO SE APLICA REGLA [NextActionScheduledStartTime]', startTime, currentDate);
            return;
          }
          let executeTime = startTime.getTime();
          let currentTime = currentDate.getTime();
          //console.log(`menu ${this.node.id} should be ruled as ${this.node.getNextAction()} in ${executeTime} from ${currentTime} so in ${(executeTime - currentTime) / 1000} `);
          if (this.stnat) {
            clearTimeout(this.stnat);
          }
          this.stnat = setTimeout(() => {
            //console.log('will executeNextStatus for getNextActionScheduledStartTime');
            this.executeNextStatus(this.node.getNextAction(), this.node.getNextActionData());
          }, (executeTime - currentTime))
        }
        if (this.node.getNextActionScheduledEndTime()) {
          let time = this.node.getNextActionScheduledEndTime().split(':');
          let currentDate = new Date();
          let endTime = new Date();
          endTime.setHours(Number(time[0]), Number(time[1]), Number(time[2]));
          if (endTime.getTime() <= currentDate.getTime()) {
            console.log('NO SE APLICA REGLA [NextActionScheduledEndTime]');
            return;
          }
          let executeTime = endTime.getTime();
          let currentTime = (new Date()).getTime();
          //console.log(`menu ${this.node.id} should ${this.actualAction} in ${executeTime} from ${currentTime} so in ${(executeTime - currentTime) / 1000} `);
          if (this.node.action) {
            if (this.etnat) {
              clearTimeout(this.etnat);
            }
            this.etnat = setTimeout(() => {
              //console.log('will executeNextStatus for getNextActiongetScheduledEndTime');
              this.executeNextStatus(this.actualAction, this.actualActionData);
            }, (executeTime - currentTime))
          }
        }
      }
    }

  }

  executeNextStatus(action: ActionType, actionData: INavigationNodeAdditionalData) {
    console.log('executeNextStatus ', action, actionData, this.node);
    this.actualAction = _.clone(this.node.action);
    this.actualActionData = _.clone(this.node.data);
    this.navigationFacade.configState$.pipe(take(1)).subscribe((navigationNodes: INavigationTree<INavigationNodeData>) => {
      this.changeNextStatus(_.cloneDeep(navigationNodes), action, actionData);
    });
  }

  changeNextStatus(navigationNodes: INavigationTree<INavigationNodeData>, action: ActionType, actionData: INavigationNodeAdditionalData) {
    console.log('changeNextStatus ', navigationNodes, action, actionData, this.node);
    let currentNavigation: INavigationTree<INavigationNodeData> = JSON.parse(JSON.stringify(navigationNodes));
    if (currentNavigation.main.find(n => n.id == this.node.id) != undefined) {
      currentNavigation.main.find(n => n.id == this.node.id).action = action;
      currentNavigation.main.find(n => n.id == this.node.id).data = actionData;
    }
    else {
      let parentId = this.getParentId(this.node.id);
      currentNavigation.main.find(n => n.id == parentId).children.find(node => node.id == this.node.id).action = action;
      currentNavigation.main.find(n => n.id == parentId).children.find(node => node.id == this.node.id).data = actionData;
    }

    this.navigationFacade.setConfig(currentNavigation);
    if (this.menuSub) {
      return;
    }
    let menuSub = setTimeout(() => {
      this.navigationFacade.restoreNavigation(this.profile.id);
      clearTimeout(menuSub);
      menuSub = null;
    }, 30000)
  }

  middlewareHidenNodes() {
    this.hidenChildrenNodes.forEach(node => {
      this.handleChildrenNodeSchedule(node)
    });
  }

  handleChildrenNodeSchedule(node: INavigationNodeModel) {
    if (node.hasNextAction()) {
      if (node.getScheduledEndTime()) {
        let time = node.getScheduledEndTime().split(':');
        let currentDate = new Date();
        let endTime = new Date();
        endTime.setHours(Number(time[0]), Number(time[1]), Number(time[2]));
        if (endTime.getTime() <= currentDate.getTime()) {
          //console.log('NO SE APLICA REGLA');
          return;
        }
        let executeTime = endTime.getTime();
        let currentTime = (new Date()).getTime();
        //console.log(`menu ${this.node.id} should ${this.actualAction} in ${executeTime} from ${currentTime} so in ${(executeTime - currentTime) / 1000} `);
        let tidindex = this.childrenEtt.findIndex(n => n.id == node.id);
        if (tidindex > 1) {
          clearTimeout(this.childrenEtt[tidindex].tid);
          this.childrenEtt.splice(tidindex, 1);
        }

        let timeToExecute = executeTime - currentTime;
        let currentTTNode = <INodeTimer>{
          id: node.id
        }
        currentTTNode.tid = setTimeout(() => {
          this.executeChildNextStatus(node);
        }, timeToExecute);
      }
    }
  }

  executeChildNextStatus(node: INavigationNodeModel) {
    this.navigationFacade.configState$.pipe(take(1)).subscribe((navigationNodes: INavigationTree<INavigationNodeData>) => {
      this.changeChildNextStatus(_.cloneDeep(navigationNodes), node);
    })
  }

  changeChildNextStatus(navigationNodes: INavigationTree<INavigationNodeData>, node: INavigationNodeModel) {
    let currentNavigation: INavigationTree<INavigationNodeData> = JSON.parse(JSON.stringify(navigationNodes))
    if (node.hasNextAction()) {
      if (currentNavigation.main.find(n => n.id == node.id) != undefined) {
        currentNavigation.main.find(n => n.id == node.id).action = node.getNextAction();
        currentNavigation.main.find(n => n.id == node.id).data = node.getNextActionData();
      }
      else {
        let parentId = this.getParentId(node.id);
        currentNavigation.main.find(n => n.id == parentId).children.find(nd => nd.id == node.id).action = node.getNextAction();
        currentNavigation.main.find(n => n.id == parentId).children.find(nd => nd.id == node.id).data = node.getNextActionData();
      }
      this.navigationFacade.setConfig(currentNavigation);
      let tidindex = this.childrenEtt.findIndex(n => n.id == node.id);
      if (tidindex > 1) {
        clearTimeout(this.childrenEtt[tidindex].tid);
        this.childrenEtt.splice(tidindex, 1);
      }
      let menuSub = setTimeout(() => {
        this.navigationFacade.restoreNavigation(this.profile.id);
        clearTimeout(menuSub);
        menuSub = null;
      }, 30000)
    }
  }

  getParentId(id: string) {
    let idSplited = id.split('.');
    idSplited.pop();
    return idSplited.join('.');
  }

  selectComponent() {
    if (!this.loadMobileContent && this.isExtended) {
      this.displayContent = 1;
    }
    else if (!this.loadMobileContent && !this.isExtended) {
      if (this.node.hasChildren()) {
        this.displayContent = 3;
      }
      else {
        this.displayContent = 2;
      }
    }
    else if (this.loadMobileContent) {
      this.displayContent = 4;
    }
    else {
      this.displayContent = 0;
    }
  }

  handleClick(event: Event) {
    if (this.node.isDisabled())
      return;

    if (this.node.isMessage()) {
      this.close();
      return this.message();
    }

    if (!this.node.hasChildren()) {
      if (this.node.hasExternalUrl()) {
        return window.open(this.node.getUrl(), this.node.getTarget());
      }
      this.setActivate();
      return this.router.navigate([this.node.getUrl()]);
    }

    if (this.node.hasChildren()) {
      event.stopPropagation();
      if (this.node.isOpen) {
        //console.log('close node');
        return this.node.close();
      }
      //console.log('open node');
      this.node.open();
      this.opened.emit(this.node);
      return;
    }
    return this.close();
  }

  setActivate() {
    this.navigationFacade.setCurrent(this.node.config);
    //this.node.active();
    //this.activated.emit(this.node);
  }

  onActivated(openedNode: INavigationNodeModel) {
    this.node.children.forEach(node => {
      if (node.id !== openedNode.id) {
        node.desactive();
      }
    });
    this.activated.emit(openedNode);
  }

  navigate() {
    if (this.node.hasExternalUrl()) {
      return window.open(this.node.getUrl(), this.node.getTarget());
    }
    return this.router.navigate([this.node.getUrl()]);
  }

  close() {
    this.node.close();
    // if (this.parent) {
    //   this.parent.close();
    // }
  }

  back(event: Event) {
    event.stopPropagation();
    this.node.close();
  }

  message() {
    if (this.node.hasMessage()) {
      //this.messageService.info(this.node.getMessage());
      this.openMessage(this.node.getMessage());
    }
  }

  openMessage(message: string) {
    const dialogRef = this.dialog.open(MenuMessageComponent, {
      minWidth: '30%',
      maxWidth: '80%',
      width: 'auto',
      data: { message: message }
    });

    dialogRef.afterClosed().pipe(untilDestroyed(this)).subscribe(() => {

    });
  }




}
