/**
 * Dependencies
 */
import { Routes } from '@angular/router';

/**
 * Components
 */
import { LandingLayoutComponent } from '../../shared/layouts/pages/landing-layout/landing-layout.component';
import { SiteLayoutComponent } from '../../shared/layouts/pages/site-layout/site-layout.component';
import { PublicLayoutComponent } from '../../shared/layouts/pages/public-layout/public-layout.component';

/**
 * Guards
 */
import { AuthGuard } from '../session/guards/auth/auth.guard';

/**
 * Routes
 */
import { AUTH_ROUTES } from '../../modules/auth/auth.routes';
import { ServerBusyPageComponent } from '../../modules/auth/pages/server-busy-page/server-busy-page.component';
import { LEGAL_ROUTES } from '../../modules/legal/legal.routes';
import { ImpersonateResolve } from '../routing/resolver/impersonate/impersonate.resolve';
import { MsalGuard } from '@azure/msal-angular';

export const appRoutes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  {
    path: '',
    component: SiteLayoutComponent,
    canActivateChild: [MsalGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        data: {
          id: 'home',
          title: 'Inicio',
          icon: 'home',
          requirePermissions: false,
        },
        redirectTo: 'user/profile',
      },
      {
        path: 'server-busy',
        pathMatch: 'full',
        component: ServerBusyPageComponent,
      },
      {
        path: 'impersonate',
        loadChildren: () => import('../../modules/impersonate/impersonate.module').then(m => m.ImpersonateModule),
        resolve: [ImpersonateResolve]
      },
      {
        path: 'user',
        loadChildren: () => import('../../modules/user/user.module').then(m => m.UserModule),
      },
      {
        path: 'financial-info',
        loadChildren: () => import('../../modules/financial/financial.module').then(m => m.FinancialModule),
      },
      {
        path: 'school-services',
        loadChildren: () => import('../../modules/school-services/school-services.module').then(m => m.SchoolServicesModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('../../modules/admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: 'service-window',
        loadChildren: () => import('../../modules/service-window/service-window.module').then(m => m.ServiceWindowModule)
      },
      {
        path: 'payment',
        loadChildren: () => import('../../modules/payment/payment.module').then(m => m.PaymentModule)
      },
      {
        path: 'restore-procedure/:id',
        loadChildren: () => import('../../modules/restore-procedure/restore-procedure.module').then(m => m.RestoreProcedureModule)
      },
      {
        path: 'additional-services',
        loadChildren: () => import('../../modules/additional-services/additional-services.module').then(m => m.AdditionalServicesModule)
      }
    ]
  },
  {
    path: '',
    component: LandingLayoutComponent,
    children: [
      ...LEGAL_ROUTES,
      ...AUTH_ROUTES,
    ]
  },
  {
    path: '',
    component: PublicLayoutComponent,
    loadChildren: () => import('../../modules/public/public-pages.module').then(m => m.PublicPagesModule)

  },
  /** Default route **/

  { path: '**', redirectTo: 'login', data: { navigable: false } }
];
