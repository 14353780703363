import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IMenuAdminState } from "../interfaces/imenu-state";

export const selectMenuAdminState = createFeatureSelector<IMenuAdminState>('menuAdmin');

export const getState = createSelector(selectMenuAdminState, (state: IMenuAdminState) => state);

export const getConfigState = createSelector(selectMenuAdminState, (state: IMenuAdminState) => state.config);

export const getCurrentState = createSelector(selectMenuAdminState, (state: IMenuAdminState) => state.current);

export const getActionState = createSelector(selectMenuAdminState, (state: IMenuAdminState) => state.action);

export const getCurrentIdState = createSelector(selectMenuAdminState, (state: IMenuAdminState) => state.currentId);

export const getAdditionalState = createSelector(selectMenuAdminState, (state: IMenuAdminState) => state.additional);
