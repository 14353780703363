import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/core/config/services/config.service';
import { AuthService } from 'src/app/core/session/services/auth/auth.service';
import { IBillBoxData, IBillBoxFilters, IBillingData, IBillingMessages, IBillingState, IRebillingData } from '../interfaces/ibilling-status';
import * as BILLING_ACTIONS from '../store/billing.actions';
import { getState } from '../store/billing.selectors';

@Injectable()
export class BillingFacade {

  private state$: Observable<IBillingState> = this.store.select(getState);

  billbox$: Observable<IBillBoxData> = this.state$.pipe(select(state => state.billbox));
  rebilling$: Observable<IRebillingData> = this.state$.pipe(select(state => state.rebilling));
  billingdata$: Observable<IBillingData> = this.state$.pipe(select(state => state.billingData));
  billingMessages$: Observable<IBillingMessages> = this.state$.pipe(select(state => state.billingMessages));

  constructor(
    private store: Store<IBillingState>,
    private authService: AuthService,
    private configService: ConfigService
  ) { }

  setBillbox(billbox: IBillBoxData) {
    this.store.dispatch(BILLING_ACTIONS.SET_BILLBOX({ payload: billbox }));
  }

  setBillboxFilter(filter: IBillBoxFilters) {
    this.store.dispatch(BILLING_ACTIONS.SET_BILLBOX_FILTERS({ payload: filter }));
  }

  setBillboxBills(bills: any[]) {
    this.store.dispatch(BILLING_ACTIONS.SET_BILLBOX_BILLS({ payload: bills }));
  }

  setBillboxTransactionId(transactionId: string) {
    this.store.dispatch(BILLING_ACTIONS.SET_BILLBOX_TRNS_ID({ payload: transactionId }));
  }

  removeBillbox(remove: boolean) {
    this.store.dispatch(BILLING_ACTIONS.REMOVE_BILLBOX({ payload: remove }));
  }

  setBillingData(billingdata: IBillingData) {
    this.store.dispatch(BILLING_ACTIONS.SET_BILLINGDATA({ payload: billingdata }));
  }

  setBillingdataData(data: any) {
    this.store.dispatch(BILLING_ACTIONS.SET_BILLINGDATA_DATA({ payload: data }));
  }

  setBillingdataActive(active: boolean) {
    this.store.dispatch(BILLING_ACTIONS.SET_BILLINGDATA_ACTIVE({ payload: active }));
  }

  setBillingdataTransactionId(transactionId: string) {
    this.store.dispatch(BILLING_ACTIONS.SET_BILLINGDATA_TRNS_ID({ payload: transactionId }));
  }

  removeBillingdata(remove: boolean) {
    this.store.dispatch(BILLING_ACTIONS.REMOVE_BILLINGDATA({ payload: remove }));
  }

  setRebilling(rebilling: IRebillingData) {
    this.store.dispatch(BILLING_ACTIONS.SET_REBILLING({ payload: rebilling }));
  }

  setRebillingFolio(folio: string) {
    this.store.dispatch(BILLING_ACTIONS.SET_REBILLING_FOLIO({ payload: folio }));
  }

  setRebillingUuid(uuid: string) {
    this.store.dispatch(BILLING_ACTIONS.SET_REBILLING_UUID({ payload: uuid }));
  }

  setRebillingActive(active: boolean) {
    this.store.dispatch(BILLING_ACTIONS.SET_REBILLING_ACTIVE({ payload: active }));
  }

  setRebillingData(data: any) {
    this.store.dispatch(BILLING_ACTIONS.SET_REBILLING_DATA({ payload: data }));
  }

  setRebillingAdditional(additionaldata: any) {
    this.store.dispatch(BILLING_ACTIONS.SET_REBILLING_ADDITIONALDATA({ payload: additionaldata }));
  }

  setRebillingTransactionId(transactionId: string) {
    this.store.dispatch(BILLING_ACTIONS.SET_REBILLING_TRNS_ID({ payload: transactionId }));
  }

  removeRebilling(remove: boolean) {
    this.store.dispatch(BILLING_ACTIONS.REMOVE_REBILLING({ payload: remove }));
  }

  setBillingdataMessage(active: { [name: string]: boolean }) {
    this.store.dispatch(BILLING_ACTIONS.SET_BILLING_MESSAGE({ payload: active }));
  }

  removeBillMessages(remove: boolean) {
    this.store.dispatch(BILLING_ACTIONS.REMOVE_BILLING_MESSAGE({ payload: remove }));
  }

  isAllowedTenant(groupsFromApi: string[] = []): boolean {
    let isAllowed = false;
    const tenants: { [name: string]: string } = this.configService.get('oauth2.rebillingTenants', {});

    let alloweTenants: string[] = [];
    Object.keys(tenants).forEach(key => {
      alloweTenants.push(tenants[key]);
    });


    let allMyGroups: string[] = groupsFromApi;
    /*const myGroups: string[] = this.authService.decodeToken()['groups'];
    if(myGroups){
      allMyGroups.push(...myGroups);
    }*/

    allMyGroups.push(...this.authService.groupsFromStorage());

    if (this.authService.isStudentTenant()) {
      isAllowed = alloweTenants.includes(this.authService.getTenant());
    }

    if (allMyGroups.length) {
      for (let i = 0; i < allMyGroups.length; i++) {
        if (alloweTenants.includes(allMyGroups[i])) {
          isAllowed = true;
          break;
        }
      }
    }

    return isAllowed;
  }


}
