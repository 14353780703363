import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMiniFabButton } from '@angular/material/button';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { FileSelectEvent } from 'primeng/fileupload';

@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit, AfterViewInit {

    @Input('accept') accept: string = 'image/*';
    @Input('maxFileSize') maxFileSize: number = 2097160;
    @Input('multiple') multiple: boolean = false;

    @Output('onFileChanged') onFileChanged: EventEmitter<File | null> = new EventEmitter<File | null>();
    files = [];
    totalSize: number = 0;
    totalSizePercent: number = 0;

    @ViewChild('fuclearBtn', { static: false }) fuclearBtn: MatMiniFabButton;

    invalidMessage = {
        size: 'Archivo muy pesado',
        sizeDetail: 'El tamaño del archivo excede lo permitido',
        type: 'Archivo no válido',
        typeDetail: 'Solo se permiten archivos de imagen',
    };

    constructor(
        private config: PrimeNGConfig,
        private messageService: MessageService
    ) {
        console.log('FileUploaderComponent', this.accept);
    }

    ngOnInit(): void {
        console.log('FileUploaderComponent', this.accept);
    }

    ngAfterViewInit(): void {
    }

    clearByBtn() {
        if (this.fuclearBtn) {
            this.fuclearBtn._elementRef.nativeElement.click();
        }
    }

    choose(event, callback) {
        callback();
    }

    onRemoveTemplatingFile(event, file, removeFileCallback, index) {
        removeFileCallback(event, index);
        this.totalSize -= parseInt(this.formatSize(file.size));
        this.totalSizePercent = this.totalSize / 10;
    }

    onClearTemplatingUpload(clear) {
        clear();
        this.totalSize = 0;
        this.totalSizePercent = 0;
    }

    onTemplatedUpload() {
        this.messageService.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });
    }

    onClearFiles(callback) {
        callback();
        this.onFileChanged.emit(null);
    }

    onSelectedFiles(event: FileSelectEvent) {
        this.files = event.currentFiles;
        this.files.forEach((file) => {
            this.totalSize += parseInt(this.formatSize(file.size));
        });
        this.totalSizePercent = this.totalSize / 10;
        this.onFileChanged.emit(this.files[0]);
    }

    uploadEvent(callback) {
        callback();
    }

    formatSize(bytes) {
        const k = 1024;
        const dm = 3;
        const sizes = this.config.translation.fileSizeTypes;
        if (bytes === 0) {
            return `0 ${sizes[0]}`;
        }

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

        return `${formattedSize} ${sizes[i]}`;
    }
}
