import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { ConfigService } from 'src/app/core/config/services/config.service';
import { Endpoint } from 'src/app/core/http/models/endpoint/endpoint';
import { AppHttp } from 'src/app/core/http/services/app-http/app-http.service';
import { EndpointsService } from 'src/app/core/http/services/endpoints/endpoints.service';
import { IBillDataFile } from '../../../modules/financial/billing/interfaces/ibill-data';
import { IBillbox, IInvoiceFile, IRebillingResponse, IThirdPartyDatum } from '../../../modules/financial/billing/interfaces/ibillbox';
import { IBillRfc } from '../../../modules/financial/billing/interfaces/irfc';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  billsEndpoints: {
    requestMailBox: Endpoint,
    getMailBox: Endpoint,
    requestBillboxInvoiceFile: Endpoint,
    requestInvoiceFile: Endpoint,
    getInvoiceFiles: Endpoint,
    searchRfc: Endpoint,
    getRfc: Endpoint,
    searchForeignRfc: Endpoint,
    getForeignRfc: Endpoint,
    requestUpdateRfc: Endpoint,
    responseUpdateRfc: Endpoint,
    getReceiptThirdsChanges: Endpoint,
    requestRebilling: Endpoint,
    responseRebilling: Endpoint,
    sendEmail: Endpoint,
    validateRequestStatus: Endpoint,
  }

  constructor(
    private http: AppHttp,
    private endpoints: EndpointsService,
    private _http: HttpClient,
    private configService: ConfigService
  ) {
    this.billsEndpoints = this.endpoints.billing;
  }

  /**
   *
   * MailBox Billing
   */

  requestMailBox(profileId: string, body: any): Observable<string> {
    this.billsEndpoints.requestMailBox.useWildcardParams({ profileId });
    return this.http.post(this.billsEndpoints.requestMailBox, body)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fue posible solicitar las facturas.')));
  }

  getMailBox(profileId: string, executionId: string): Observable<IBillbox> {
    this.billsEndpoints.getMailBox.useWildcardParams({ executionId, profileId });
    return this.http.get(this.billsEndpoints.getMailBox)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fue posible solicitar información las facturas.')));
  }

  requestBillboxInvoiceFile(invoiceId: string, profileId: string, mailboxRequestId: string, rebilling: boolean = false): Observable<string> {
    this.billsEndpoints.requestBillboxInvoiceFile.useWildcardParams({ invoiceId, profileId, mailboxRequestId, rebilling });
    return this.http.post(this.billsEndpoints.requestBillboxInvoiceFile, {})
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fue posible recuperar la información de las facturas.')));
  }

  requestInvoiceFile(invoiceId: string, profileId: string, transactionId: string, rebilling: boolean = false): Observable<string> {
    this.billsEndpoints.requestInvoiceFile.useWildcardParams({ invoiceId, profileId, transactionId, rebilling });
    return this.http.post(this.billsEndpoints.requestInvoiceFile, {})
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fue posible solicitar la factura.')));
  }

  getInvoiceFiles(invoiceId: string, profileId: string, requestId: string, rebilling: boolean = false): Observable<IInvoiceFile> {
    this.billsEndpoints.getInvoiceFiles.useWildcardParams({ invoiceId, profileId, requestId, rebilling });
    return this.http.get(this.billsEndpoints.getInvoiceFiles)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fue posible recuperar la factura.')));
  }


  /**
   *
   * RFC Services
   */
  /**
   *
   * @param Rfc
   * @param profileId
   * @param mailBoxRequestId
   * @returns
   */

  searchRfc(rfc: string, profileId: string, mailBoxRequestId: string): Observable<string> {
    this.billsEndpoints.searchRfc.useWildcardParams({ rfc, profileId, mailBoxRequestId });
    return this.http.post(this.billsEndpoints.searchRfc, {})
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fue posible solicitar información del rfc.')));
  }

  getRfc(RequestId: string, profileId: string): Observable<IBillRfc> {
    this.billsEndpoints.getRfc.useWildcardParams({ RequestId, profileId });
    return this.http.get(this.billsEndpoints.getRfc)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fue posible recuperar la información del rfc.')));
  }

  requestUpdateRfc(profileId: string, mailBoxRequestId: string, body: any): Observable<string> {
    this.billsEndpoints.requestUpdateRfc.useWildcardParams({ profileId, mailBoxRequestId });
    return this.http.post(this.billsEndpoints.requestUpdateRfc, body)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fue posible solicitar la actualización de los terceros.')));
  }

  responseUpdateRfc(profileId: string, requestId: string) {
    this.billsEndpoints.responseUpdateRfc.useWildcardParams({ profileId, requestId });
    return this.http.get(this.billsEndpoints.responseUpdateRfc)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fue posible recuperar la información de actualización de los terceros.')));
  }

  getReceiptThirdsChanges(profileId: string, requestId: string): Observable<IBillDataFile> {
    this.billsEndpoints.getReceiptThirdsChanges.useWildcardParams({ profileId, requestId });
    return this.http.get(this.billsEndpoints.getReceiptThirdsChanges)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fue posible recuperar el comprobante de actualización de terceros.')));
  }

  searchForeignRfc(profileId: string, mailBoxRequestId: string): Observable<string> {
    /*return of('f9125f5c-560e-4efb-ad1e-30bdd420fd18').pipe(delay(1000));*/
    this.billsEndpoints.searchForeignRfc.useWildcardParams({ profileId, mailBoxRequestId });
    return this.http.post(this.billsEndpoints.searchForeignRfc, {})
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fue posible solicitar información del rfc extranjero.')));
  }

  getForeignRfc(RequestId: string, profileId: string): Observable<IThirdPartyDatum> {
    /*return of({
      cfdiUseId: '',
      rfc: 'XEXX010101000',
      taxRegimeId: '',
      address:'',
      bussinesName: '',
      country:'',
      id: '',
      isActive: false,
      lastName: '',
      name: '',
      personType: '',
      onlyForNextBill: false
    } as IThirdPartyDatum).pipe(delay(1000));*/
    this.billsEndpoints.getForeignRfc.useWildcardParams({ RequestId, profileId });
    return this.http.get(this.billsEndpoints.getForeignRfc)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fue posible recuperar la información del rfc extranjero.')));
  }


  /** Rebilling */

  requestRebilling(profileId: string, requestId: string, invoiceId: string, body: any) {
    this.billsEndpoints.requestRebilling.useWildcardParams({ profileId, requestId, invoiceId });
    return this.http.post(this.billsEndpoints.requestRebilling, body)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fue posible solicitar la refacturación.')));
  }

  responseRebilling(profileId: string, requestId: string): Observable<IRebillingResponse> {
    this.billsEndpoints.responseRebilling.useWildcardParams({ profileId, requestId });
    return this.http.get(this.billsEndpoints.responseRebilling)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fue posible recuperar la información de refacturación.')));
  }






  sendEmail(invoiceId: string, profileId: string, RequestId: string): Observable<boolean> {
    this.billsEndpoints.sendEmail.useWildcardParams({ invoiceId, profileId, RequestId });
    return this.http.get(this.billsEndpoints.sendEmail)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fue posible enviar tu factura por correo.')));
  }

  validateRequestStatus(RequestId: string, profileId: string, bypass = false): Observable<{ data: boolean; message: string; }> {
    if(bypass){
      return of({ data: true, message: '' }).pipe(delay(1000));
    }
    this.billsEndpoints.validateRequestStatus.useWildcardParams({ RequestId, profileId });
    return this.http.get(this.billsEndpoints.validateRequestStatus)
      .pipe(map(response => ({ data: response.data, message: response.message })))
      .pipe(catchError(response => this.http.handleError(response, 'No fue posible validar el estatus de la transacción.')));
  }

  getRebillingRulesStorage(_url = '/financial/rebilling_rule.html') {
    let url = this.configService.get('storage.blob.url') + _url
    return this._http.get(url, {
      responseType: 'text'
    });
  }

}
