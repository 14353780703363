import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationNodeFactory } from './factories/NavigationNodeFactory';
import { NavigationFacade } from './facades/navigation-facade';
import { NavigationService } from '../../api/navigation/navigation.service';
import { StoreModule } from '@ngrx/store';
import { NavigationReducer } from './store/navigation.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('navigation', NavigationReducer),
  ],
  providers: [
    NavigationNodeFactory,
    NavigationFacade,
    NavigationService,
  ],
})
export class NavigationStoreModule { }
