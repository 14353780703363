import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EarlyEnrollmentFacade } from './facades/early-enrollment-facade';
import { StoreModule } from '@ngrx/store';
import { EarlyEnrollmentReducer } from './store/early-enrollment.reducer';


@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('earlyEnrollment', EarlyEnrollmentReducer),
  ],
  declarations: [],
  providers: [
    EarlyEnrollmentFacade
  ]
})
export class EarlyEnrollmentStoreModule { }
