import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { refactorIconKey } from 'src/app/core/redux/navigation/factories/NavigationNodeFactory';

@UntilDestroy()
@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  iconConfig: string;
  @Input('icon') icon: string | string[];
  @Input('size') size: string = '';
  @Input('spin') spin: boolean = false;
  constructor() { }

  ngOnInit() {
    this.iconConfig = this.getIconConfig();
  }

  getIconConfig() {
    if (typeof this.icon === 'string') {
      return refactorIconKey(this.icon, this.size, this.spin);
    } else {
      this.icon[1] = `fa-${this.icon[1]}`;
      return refactorIconKey(this.icon.join(' '), this.size, this.spin);
    }
  }
}
