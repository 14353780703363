import { createReducer, on } from '@ngrx/store';
import { DOCUMENT_STATE } from './../interfaces/document.state';
import { IDocumentState } from '../interfaces/idocument-status';
import * as DOCUMENT_ACTIONS from './document.actions';

const _documentReducer = createReducer(DOCUMENT_STATE,
  on(DOCUMENT_ACTIONS.SET_DOCUMENT, (state: IDocumentState, { payload }) => {
    return { ...payload };
  }),
  on(DOCUMENT_ACTIONS.SET_METADATA, (state: IDocumentState, { payload }) => {
    return { ...state, metadata: { ...payload } };
  }),
  on(DOCUMENT_ACTIONS.SET_DOCUMENT_ID, (state: IDocumentState, { payload }) => {
    return { ...state, idDocument: payload.documentId };
  }),
  on(DOCUMENT_ACTIONS.SET_DOCUMENT_TYPE, (state: IDocumentState, { payload }) => {
    return { ...state, documentType: payload.type };
  }),
  on(DOCUMENT_ACTIONS.SET_DOCUMENT_REGULATION, (state: IDocumentState, { payload }) => {
    return { ...state, regulation: payload };
  }),
  on(DOCUMENT_ACTIONS.SET_DOCUMENTS, (state: IDocumentState, { payload }) => {
    return { ...state, documents: [...payload] };
  }),
  on(DOCUMENT_ACTIONS.SET_SESSION, (state: IDocumentState, { payload }) => {
    return { ...state, idSession: payload.sessionId, sessionActive: payload.sessionActive };
  }),
  on(DOCUMENT_ACTIONS.REMOVE_DOCUMENT, (state: IDocumentState, { payload }) => DOCUMENT_STATE),
);

export function DocumentReducer(state, action) {
  return _documentReducer(state, action);
}
