import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Observable, interval, timer } from 'rxjs';
import { OrderDetail } from '../../interfaces/order-detail-interface';
import { ActivatedRoute } from '@angular/router';
import { SupportingDocumentService } from 'src/app/core/api/supporting-documents/supporting-document.service';
import { StringService } from 'src/app/core/services/string/string.service';
import { GenericDocumentService } from 'src/app/core/api/generic-document/generic-document.service';
import { IDocumentsPreview, IDocumentsQr } from 'src/app/core/redux/document/interfaces/idocuments-preview';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseComponent } from 'src/app/shared/layouts/components/base-component/base-component.component';

@UntilDestroy()
@Component({
  selector: 'app-document-order-detail',
  templateUrl: './document-order-detail.component.html',
  styleUrls: ['./document-order-detail.component.scss']
})
export class DocumentOrderDetailComponent extends BaseComponent implements OnInit {

  @ViewChild('HtmlContainer') HtmlContainer: ElementRef;

  TransactionId: string;
  captchaString: string;

  Data: OrderDetail;
  Loading: boolean;
  timeBeforeRender: number = 1500;
  HtmlElement: HTMLElement;

  ZoomInit = 0.9;


  HtmlData: string = null;
  PdfData: Uint8Array = null;

  captcha = {
    lang: 'es',
    size: 'Normal',
    theme: 'ligth',
    type: 'image',
  };

  documentsLoaded: boolean[] = [];
  documents: IDocumentsPreview[] = [];
  isExpired: boolean = false;
  ShowOrder: boolean = false;
  NotFound: boolean = false;
  slectedTab: number = 0;
  orderId: any;

  constructor(
    private route: ActivatedRoute,
    private stringService: StringService,
    private genericDocumentService: GenericDocumentService
  ) { 
    super();
  }

  ngOnInit() {
    this.TransactionId = encodeURIComponent(decodeURIComponent(this.route.snapshot.queryParamMap.get('t')));
    this.orderId = this.TransactionId;
  }

  GetOrderDetail() {
    this.Loading = true;
    this.genericDocumentService.getDocumentsQr(this.TransactionId, this.captchaString).pipe(untilDestroyed(this)).subscribe((documents: IDocumentsQr) => {
      //console.log(documents);
      if (documents) {
        this.isExpired = documents.isExpired;
        if (!this.isExpired) {
          this.documents = documents.documentSource;
          this.ShowOrder = true;
          this.NotFound = false;
          this.initDocumentsView();
        }
      } else {
        this.NotFound = true;
        this.Loading = false;
      }
    }, error => {
      this.NotFound = true;
      this.Loading = false;
    });
  }

  initDocumentsView() {
    this.documents.forEach((k: IDocumentsPreview, i: number) => {
      this.documentsLoaded[i] = true;
    })

  }

  handleCaptchaExpire() { }

  handleCaptchaLoad() { }

  handleCaptchaSuccess(event) {
    this.captchaString = event;
    this.GetOrderDetail();
  }

  RenderData(Html: string, PdfBase64: string) {
    if (Html !== null) {
      this.HtmlData = Html.replace('div', 'section');
    }

    if (PdfBase64 !== null) {
      this.PdfData = this.stringService.base64ToUint8Array(PdfBase64);
    }

    setTimeout(() => {
      this.HtmlElement = document.getElementById('htmlContent');
      console.log(this.HtmlElement);
    }, this.timeBeforeRender + 100);
  }

  ZoomInTest() {
    if (this.ZoomInit < 2) {
      this.ZoomInit = this.ZoomInit + 0.1;
      this.HtmlElement.style['zoom'] = '' + this.ZoomInit;
    }
  }

  ZoomOutTest() {
    if (this.ZoomInit > 0.1) {
      this.ZoomInit = this.ZoomInit - 0.1;
      this.HtmlElement.style['zoom'] = '' + this.ZoomInit;
    }
  }

  setTabValue(event: number) {
    setTimeout(() => {
      this.slectedTab = event;
    }, 300);
  }

}
