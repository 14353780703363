// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iframe-full {
  display: flex;
  border: none;
  height: 100vh;
  width: 100%;
}

.hidden {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/layouts/components/iframe/iframe.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,aAAA;EACA,WAAA;AACF;;AACA;EACE,aAAA;AAEF","sourcesContent":[".iframe-full {\r\n  display: flex;\r\n  border: none;\r\n  height: 100vh;\r\n  width: 100%;\r\n}\r\n.hidden {\r\n  display: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
