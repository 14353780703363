import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentFacade } from './facades/payment-facade';
import { StoreModule } from '@ngrx/store';
import { PaymentReducer } from './store/payment.reducer';


@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('payment', PaymentReducer),
  ],
  declarations: [],
  providers: [
    PaymentFacade
  ]
})
export class PaymentStoreModule { }
