import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, EMPTY, exhaustMap, map } from "rxjs";
import * as LOGGER_ACTIONS from './logs.actions';
import { UserFacade } from "../../user/facades/user-facade";
import { IUserData } from "../../user/interfaces/iuser-data";
import { LogService } from "src/app/core/api/log/log.service";

@Injectable()
export class LogsEffects {

    saveLog$ = createEffect(() => this.actions$.pipe(
        ofType(LOGGER_ACTIONS.SAVE_LOG),
        exhaustMap((payload) => this.userFacade.profile$
            .pipe(
                map((profile: IUserData) =>
                    payload.log.level == 'ERROR' ? LOGGER_ACTIONS.UPLOAD_LOG({ log: { ...payload.log, profileId: profile.id } }) : LOGGER_ACTIONS.ADD_LOG({ log: payload.log })),
                catchError(() => EMPTY)
            ))
    )
    );

    uploadLog$ = createEffect(() => this.actions$.pipe(
        ofType(LOGGER_ACTIONS.UPLOAD_LOG),
        exhaustMap((payload) => this.logService.saveLog(payload.log)
            .pipe(
                map((saved: boolean) => LOGGER_ACTIONS.ADD_LOG({ log: { ...payload.log, saved } })),
                catchError(() => EMPTY)
            ))
    )
    );

    constructor(
        private actions$: Actions,
        private userFacade: UserFacade,
        private logService: LogService
    ) { }
}