import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserFacade } from './facades/user-facade';
import { StoreModule } from '@ngrx/store';
import { UserReducer } from './store/user.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('user', UserReducer),
  ],
  declarations: [],
  providers: [
    UserFacade,
  ]
})
export class UserStoreModule { }
