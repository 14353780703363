import { Component, OnInit, Input } from '@angular/core';
import { ITransactionData } from 'src/app/core/redux/transactions-engine/interfaces/itransactions-filter';
import { Router } from '@angular/router';
import { PaymentFacade } from 'src/app/core/redux/payment/facades/payment-facade';
import { IPaymentData, IPaymentState } from 'src/app/core/redux/payment/interfaces/ipayment-status';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent implements OnInit {

  isCollapsed: boolean = true;
  @Input('transaction') transaction: ITransactionData;
  paymentSuscribe: Subscription;

  showContinuar = true;

  constructor(
    private paymentFacade: PaymentFacade,
    private router: Router
  ) { }

  ngOnInit() {
    if (this.transaction.transactionStatus != null && typeof this.transaction.transactionStatus !== 'undefined') {
      if (this.transaction.transactionStatus.stepPath == null) {
        this.showContinuar = false;
      }
    }
  }

  toggle(event: Event) {
    event.stopPropagation();
    this.isCollapsed = !this.isCollapsed;
  }

  continue(transactionId: number) {
    if (transactionId != null && typeof (transactionId) !== 'undefined') {
      this.paymentSuscribe = this.paymentFacade.payment$.pipe(untilDestroyed(this)).subscribe(payment => {
        if (payment) {
          setTimeout(() => {
            this.navigateToRestoreProcedure(payment, transactionId);
          }, 10);
        }
      });
    }
  }

  navigateToRestoreProcedure(payment: any, transactionId: any) {
    this.unsuscribe();
    this.paymentFacade.removePayment(payment.data);
    this.paymentFacade.setPayment(
      <IPaymentData>{ transactionId: transactionId },
      true, transactionId.toString());
    this.router.navigate(['restore-procedure', transactionId]);
  }

  unsuscribe() {
    if (this.paymentSuscribe) {
      this.paymentSuscribe.unsubscribe();
    }
  }
}
