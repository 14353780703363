import { createReducer, on } from '@ngrx/store';
import { NOTIFICATIONS_STATE } from './../interfaces/notification.state';
import { INotificationState } from '../interfaces/inotification-state';
import * as NOTIFICATION_ACTIONS from './notification.actions';

const _notificationReducer = createReducer(NOTIFICATIONS_STATE,
    on(NOTIFICATION_ACTIONS.ENABLE, (state: INotificationState) => ({ ...state, enabled: true })),
    on(NOTIFICATION_ACTIONS.DISABLE, (state: INotificationState) => ({ ...state, enabled: false })),
    on(NOTIFICATION_ACTIONS.OPEN_CONTAINER, (state: INotificationState) => ({ ...state, unread: 0, containerIsActive: true })),
    on(NOTIFICATION_ACTIONS.CLOSE_CONTAINER, (state: INotificationState) => ({ ...state, unread: 0, containerIsActive: false })),
    on(NOTIFICATION_ACTIONS.ADD, (state: INotificationState, { payload }) => {
        const unread = state.containerIsActive ? 0 : (payload.notificationType != 'alert' ? state.unread + 1 : state.unread);
        const notifications = [...state.notifications, payload];
        return { ...state, notifications, unread };
    }),
    on(NOTIFICATION_ACTIONS.REMOVE, (state: INotificationState, { payload }) => {
        const notifications = [...state.notifications];
        const index = notifications.indexOf(payload);
        notifications.splice(index, 1);
        return { ...state, notifications };
    })
);

export function NotificationReducer(state, action) {
    return _notificationReducer(state, action);
}