import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogsFacade } from './facades/logs.facade';
import { StoreModule } from '@ngrx/store';
import { LogsReducer } from './store/logs.reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('logs', LogsReducer),
  ],
  declarations: [],
  providers: [
    LogsFacade
  ]
})
export class LogsStoreModule { }
