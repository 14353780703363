import { Injectable } from '@angular/core';
import { UserFacade } from '../../redux/user/facades/user-facade';
import { NavigationService } from '../../api/navigation/navigation.service';
import { MessageService } from '../../services/message/message.service';
import { NavigationFacade } from '../../redux/navigation/facades/navigation-facade';
import { DEFAULT_NAVIGATION_CONFIG } from '../../redux/navigation/default.menu';
import { SidenavExtendService } from '../../services/sidenav-extend/sidenav-extend.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class NavigationInitializer {

  profile$ = this.userFacade.profile$;
  menuSub: NodeJS.Timeout;

  constructor(
    private userFacade: UserFacade,
    private navigationService: NavigationService,
    private navigationFacade: NavigationFacade,
    private messenger: MessageService,
    private sidenavExtendService: SidenavExtendService
  ) { }

  init() {
    this.profile$.pipe(untilDestroyed(this)).subscribe(profile => this.loadMenu(profile.id));
  }

  loadMenu(userId) {
    this.navigationFacade.setConfig(DEFAULT_NAVIGATION_CONFIG);
    this.getMenu(userId);
  }

  getMenu(userId: string) {
    this.navigationService.getMenu(userId)
      .pipe(untilDestroyed(this)).subscribe({
        next: config => {
          this.navigationFacade.setConfig(config);
          setTimeout(() => {
            this.sidenavExtendService.extend();
          }, 200);
        },
        error: response => this.handleError(response)
      });
    this.menuTimer(userId);
  }

  menuTimer(userId: string) {
    console.log('menuTimer: ', userId);
    if (this.menuSub) {
      clearTimeout(this.menuSub);
    }

    this.menuSub = setTimeout(() => {
      this.getMenu(userId);
    }, (1000 * 60 * 50));
  }

  handleError(response) {
    (response.error && response.error.message)
      ? this.messenger.error(response.error.message, response.error.error)
      : this.messenger.error('Ocurrió un error al intentar cargar el menú del usuario');
  }
}
