import { createAction, props } from '@ngrx/store';
import { INotificationData } from '../interfaces/inotification-data';

const ENABLE = createAction('[NOTIFICATION] enable');

const DISABLE = createAction('[NOTIFICATION] disable');

const OPEN_CONTAINER = createAction('[NOTIFICATION] open container');

const CLOSE_CONTAINER = createAction('[NOTIFICATION] close container');

const ADD = createAction('[NOTIFICATION] add', props<{ payload: INotificationData }>());

const REMOVE = createAction('[NOTIFICATION] remove', props<{ payload: INotificationData }>());

export { ENABLE, DISABLE, OPEN_CONTAINER, CLOSE_CONTAINER, ADD, REMOVE }