import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IImpersonateState } from "../interfaces/iimpersonate-state";

export const selectApplicationState = createFeatureSelector<IImpersonateState>('impersonate');

export const getState = createSelector(selectApplicationState, (state: IImpersonateState) => state);

export const getBackupState = createSelector(selectApplicationState, (state: IImpersonateState) => state.backup);

export const getPictureBackup = createSelector(selectApplicationState, (state: IImpersonateState) => state.pictureBackup);

