import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { IUserData } from '../../../../core/redux/user/interfaces/iuser-data';
import { UserFacade } from 'src/app/core/redux/user/facades/user-facade';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit, OnChanges {

  avatar: any;
  @Input('user') user: IUserData;
  @Input('flat') flat: boolean = false;

  avatars = {
    man: 'assets/img/avatars/avatar-man.png',
    woman: 'assets/img/avatars/avatar-woman.png',
    generic: 'assets/img/avatars/avatar-generic.png',
  };

  constructor(
    private userFacade: UserFacade,
    private sanitizer: DomSanitizer,
    private navigationService: NavigationService
  ) { }

  ngOnInit() {
    this.avatar = this.getAvatar();
    this.userFacade.picture$.pipe(untilDestroyed(this)).subscribe((picture: string) => {
      if (this.navigationService.getCurrentUrl().includes('user/profile')) {
        this.avatar = this.sanitizer.bypassSecurityTrustUrl(picture);
      }
    });

  }

  ngOnChanges() {
    this.avatar = this.getAvatar();
  }

  getAvatar() {
    if (this.user.gender) {
      return this.user.gender.toLowerCase() === 'f'
        ? this.avatars.woman
        : this.avatars.man;
    }
    return this.avatars.generic;
  }

}
