import { Injectable } from '@angular/core';
import { AppHttp } from '../../../../http/services/app-http/app-http.service';
import { EndpointsService } from '../../../../http/services/endpoints/endpoints.service';
import { Endpoint } from '../../../../http/models/endpoint/endpoint';
import { IResponse } from '../../../../http/interfaces/iresponse';
import { ISchoolData } from '../../interfaces/ischool-data';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ILevel } from '../../interfaces/ilevel-data';

@Injectable({
  providedIn: 'root'
})
export class LevelService {

  levelEndpoint: Endpoint;
  levelByWordEndpoint: Endpoint;
  constructor(
    private http: AppHttp,
    private endpoints: EndpointsService
  ) {
    this.levelEndpoint = this.endpoints.catalogs.levels;
    this.levelByWordEndpoint = this.endpoints.catalogs.levelsByWord;
  }

  getLevelList(campusId: string): Observable<ILevel[]> {
    this.levelEndpoint.useWildcardParams({ campusId });
    return this.http.get<IResponse<any>>(this.levelEndpoint)
      .pipe(map(response => response.data));
  }

  getLevelByWordList(word: string) {
    this.levelByWordEndpoint.useWildcardParams({ word });
    return this.http.get<IResponse<any[]>>(this.levelByWordEndpoint)
      .pipe(map(response => response.data));
  }

}
