import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ISubjectComment } from 'src/app/modules/service-window/class-withdraw/interfaces/isubject';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-translate',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.scss']
})
export class TranslateComponent implements OnInit {

  @Input('texto') texto: ISubjectComment;
  @Input('modulo') modulo: string = 'ClassWithdraw.SubjectMsgs';

  protected _url = 'es-MX/VirtualTeller/';
  param = { value: '' };

  show = false;

  constructor(
    translate: TranslateService
  ) {
    translate.setDefaultLang(`${this._url}${this.modulo}`);
    translate.use(`${this._url}${this.modulo}`);
  }

  ngOnInit() {
    if (this.texto != null) {
      this.param.value = this.texto.msgDescription;
    }
    this.show = true;
  }

}
