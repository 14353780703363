import { IMenuAdminState } from "../interfaces/imenu-admin.state";

export const MENU_ADMIN_STATE: IMenuAdminState = {
    current: undefined,
    campuses: [],
    levels: [],
    careers: [],
    downTypes: [],
    studentStatus: [],
    sessionActive: false,
    isFullControl: false
}