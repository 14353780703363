import { Injectable } from "@angular/core";
import { Endpoint } from "../../http/models/endpoint/endpoint";
import { AppHttp } from "../../http/services/app-http/app-http.service";
import { EndpointsService } from "../../http/services/endpoints/endpoints.service";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { IHistoryLogServer } from "../../redux/logs/interfaces/ilogger-state";

@Injectable({
    providedIn: 'root'
})
export class LogService {

    logsEndpoint: {
        saveLog: Endpoint
    }

    constructor(
        private http: AppHttp,
        private endpoints: EndpointsService,
    ) {
        this.logsEndpoint = this.endpoints.logs;
    }

    public saveLog(log: IHistoryLogServer): Observable<boolean> {
        window.console.log('saveLog: ', log);
        return of(true);
        return this.http
            .post(this.logsEndpoint.saveLog, log)
            .pipe(map((response) => response.data));
    };

}
