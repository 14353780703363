// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.htmlRender {
  z-index: 10;
  white-space: pre-wrap;
  overflow: auto;
  padding: 40px;
}

.htmlContent {
  zoom: 90%;
}

.ZoomButtons {
  z-index: 80;
  max-height: 53px;
  padding: 7px;
  align-content: center;
  text-align: center;
  color: white;
  border-radius: 10px;
}

@media screen and (max-width: 1000px) {
  .htmlRender {
    padding: 20px;
  }
  .htmlContent {
    zoom: 75%;
  }
  .BodyContainer {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (max-width: 858px) {
  .htmlContent {
    zoom: 50%;
  }
}
@media screen and (max-width: 600px) {
  .htmlRender {
    padding: 20px;
  }
  .htmlContent {
    zoom: 35%;
  }
  .htmlRender {
    padding: 30px;
  }
}
@media screen and (max-width: 452px) {
  .htmlContent {
    zoom: 20%;
  }
  .htmlRender {
    padding: 20px;
  }
}
.mlr-10 {
  margin-left: 5px;
  margin-right: 5px;
}

.plr-10 {
  padding: 8px;
  border-radius: 2.5px;
}

.bg-color {
  color: #2b3657;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/service-window/document-generator/components/document-viewer-html/document-viewer-html.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,qBAAA;EACA,cAAA;EACA,aAAA;AACF;;AAGA;EACE,SAAA;AAAF;;AAGA;EACE,WAAA;EACA,gBAAA;EACA,YAAA;EACA,qBAAA;EACA,kBAAA;EACA,YAAA;EACA,mBAAA;AAAF;;AAGA;EACE;IACE,aAAA;EAAF;EAIA;IACE,SAAA;EAFF;EAKA;IACE,kBAAA;IACA,mBAAA;EAHF;AACF;AAMA;EACE;IACE,SAAA;EAJF;AACF;AAOA;EACE;IACE,aAAA;EALF;EASA;IACE,SAAA;EAPF;EAUA;IACE,aAAA;EARF;AACF;AAWA;EACE;IACE,SAAA;EATF;EAYA;IACE,aAAA;EAVF;AACF;AAaA;EACE,gBAAA;EACA,iBAAA;AAXF;;AAcA;EACE,YAAA;EACA,oBAAA;AAXF;;AAcA;EACE,cAAA;AAXF","sourcesContent":[".htmlRender {\r\n  z-index: 10;\r\n  white-space: pre-wrap;\r\n  overflow: auto;\r\n  padding: 40px;\r\n  \r\n}\r\n\r\n.htmlContent {\r\n  zoom: 90%;\r\n}\r\n\r\n.ZoomButtons {\r\n  z-index: 80;\r\n  max-height: 53px;\r\n  padding: 7px;\r\n  align-content: center;\r\n  text-align: center;\r\n  color: white;\r\n  border-radius: 10px;\r\n}\r\n\r\n@media screen and (max-width: 1000px) {\r\n  .htmlRender {\r\n    padding: 20px;\r\n    \r\n  }\r\n\r\n  .htmlContent {\r\n    zoom: 75%;\r\n  }\r\n\r\n  .BodyContainer {\r\n    padding-left: 10px;\r\n    padding-right: 10px;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 858px) {\r\n  .htmlContent {\r\n    zoom: 50%;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 600px) {\r\n  .htmlRender {\r\n    padding: 20px;\r\n    \r\n  }\r\n\r\n  .htmlContent {\r\n    zoom: 35%;\r\n  }\r\n\r\n  .htmlRender {\r\n    padding: 30px;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 452px) {\r\n  .htmlContent {\r\n    zoom: 20%;\r\n  }\r\n\r\n  .htmlRender {\r\n    padding: 20px;\r\n  }\r\n}\r\n\r\n.mlr-10 {\r\n  margin-left: 5px;\r\n  margin-right: 5px;\r\n}\r\n\r\n.plr-10 {\r\n  padding: 8px;\r\n  border-radius: 2.5px;\r\n}\r\n\r\n.bg-color {\r\n  color: #2b3657;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
