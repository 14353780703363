// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.finalize .mat-step-icon {
  background-color: rgb(0, 0, 0);
  color: white;
}

.mat-card {
  padding: 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/layouts/components/transaction-tracker/transaction-tracker.component.scss"],"names":[],"mappings":"AACI;EACI,8BAAA;EACA,YAAA;AAAR;;AAIA;EACI,wBAAA;AADJ","sourcesContent":[".finalize {\r\n    .mat-step-icon {\r\n        background-color: rgb(0, 0, 0);\r\n        color: white;\r\n    }\r\n}\r\n\r\n.mat-card {\r\n    padding: 10px !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
