import { LevelData } from './../../../../../core/interfaces/catalogues/ilevel-data';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserFacade } from 'src/app/core/redux/user/facades/user-facade';
import { StringService } from 'src/app/core/services/string/string.service';
import { Observable, of } from 'rxjs';
import { IUserData } from 'src/app/core/redux/user/interfaces/iuser-data';
import { first } from 'rxjs/operators';
import { PaymentService } from '../../../../../core/api/payment/payment.service';
import { NavigationService } from '../../../../../core/services/navigation/navigation.service';
import { postRoutes, Origins } from '../../../catalogs/services';
import { MessageService } from 'src/app/core/services/message/message.service';
import { IMinResponse } from 'src/app/core/http/interfaces/iresponse';
import { IAdditionalData } from '../../../interfaces/iadditional-data';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FileSaverService } from 'ngx-filesaver';

export const _PREVIUS_URL: string = 'payment/pay/apply/';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseComponent } from 'src/app/shared/layouts/components/base-component/base-component.component';

@UntilDestroy()
@Component({
  selector: 'app-payment-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class PaymentSummaryComponent extends BaseComponent implements OnInit {

  profile$: Observable<IUserData>;
  pdfURLReady: boolean = false;
  paymentId: any;
  downloaded = false;
  pdfBase64Array: string[] = [];
  step = 0;
  origin: IAdditionalData;
  takeOutHere: boolean = false;
  originModule: string = '';

  constructor(
    private paymentService: PaymentService,
    private userFacade: UserFacade,
    private stringService: StringService,
    private route: ActivatedRoute,
    private router: Router,
    private navigationService: NavigationService,
    private messageService: MessageService,
    private deviceDetector: DeviceDetectorService,
    private fileSaver: FileSaverService
  ) {
    super();
    const profile = this.userFacade.profile$;
    profile.pipe(untilDestroyed(this)).subscribe(profile => {
      this.profile$ = of(profile);
    });
    const navigation = this.router.getCurrentNavigation();
    if (navigation == null || typeof navigation === 'undefined') {
      this.takeOutHere = true;
    } else {
      const state = navigation.extras.state as { module: string };
      if (state == null || typeof state === 'undefined') {
        this.takeOutHere = true;
      } else {
        this.originModule = state.module;
      }
    }
  }

  ngOnInit() {
    if (!this.navigationService.getPreviousUrl().includes(_PREVIUS_URL)) {
      this.router.navigateByUrl('/user/profile');
      return;
    }
    this.init();
  }

  init() {
    if (this.takeOutHere) {
      this.router.navigate(['/user/profile']);
    }
    this.route.params.pipe(untilDestroyed(this)).subscribe(params => {
      this.paymentId = params.id;
      this.downloadPDF();
    });
  }

  downloadPDF() {
    this.profile$.pipe(first()).pipe(untilDestroyed(this)).subscribe(profile => {
      this.paymentService.proofOfPayment(this.paymentId, profile.id)
        .pipe(untilDestroyed(this)).subscribe((resp: IMinResponse<any, IAdditionalData>) => {
          this.pdfBase64Array = [...this.pdfBase64Array, ...resp.data];
          this.origin = resp.additionalData;
          this.handleTime();
        });
    });
  }

  handleTime() {
    setTimeout(() => {
      this.pdfURLReady = true;
    }, 0);
  }

  handleDownloadPdf() {
    this.pdfBase64Array.forEach(pdf => {
      this.handleDownloadPdfs(pdf);
    });
  }

  handleDownloadPdfs(base64PDF: string) {
    const pdfBase64 = base64PDF;
    const file = this.stringService.base64toBlob(pdfBase64);
    //const deviceInfo = this.deviceDetector.getDeviceInfo();

    this.fileSaver.save(file, 'Comprobante-de-Pago.pdf');

    // if (deviceInfo.os.toLowerCase().startsWith('ios')
    //     || deviceInfo.os.toLowerCase().startsWith('macos')){
    //       const reader = new FileReader();
    //       reader.onload = function(e){
    //         //console.log(reader.result.toString());
    //         window.open(reader.result.toString());

    //         let a = document.createElement('a');
    //         a.href = reader.result.toString();
    //         a.download = 'Comprobante-Compra-Libros.pdf';
    //         document.body.appendChild(a);
    //         a.click();
    //         setTimeout(function () {
    //           document.body.removeChild(a);
    //           window.URL.revokeObjectURL(reader.result.toString());
    //         }, 0);
    //       }
    //       reader.readAsDataURL(file);
    // }
    // else{
    //   if (window.navigator.msSaveOrOpenBlob) {
    //     window.navigator.msSaveOrOpenBlob(file, 'Comprobante-de-Pago.pdf');
    //   } else {
    //     let a = document.createElement('a');
    //     let url = URL.createObjectURL(file);
    //     a.href = url;
    //     a.download = 'Comprobante-de-Pago.pdf';
    //     document.body.appendChild(a);
    //     a.click();
    //     setTimeout(function () {
    //       document.body.removeChild(a);
    //       window.URL.revokeObjectURL(url);
    //     }, 0);
    //   }
    // }

    this.downloaded = true;
  }

  finishTransaction() {
    this.goTo();
  }

  goTo() {
    const module = typeof Origins.find(o => o.id == this.origin.originService && o.module == this.originModule) === 'undefined' ? null : Origins.find(o => o.id == this.origin.originService && o.module == this.originModule).module;
    if (module == null || typeof module !== 'string' || module == '') {
      this.messageService.success('El proceso de pago finalizó exitosamente', null, () => {
        this.router.navigate(['/user/profile'])
      });
      return;
    }
    const toGo = typeof postRoutes.find(r => r.id == module) === 'undefined' ? null : postRoutes.find(r => r.id == module).route;
    if (toGo == null) {
      this.messageService.success('El proceso de pago finalizó exitosamente', null, () => {
        this.router.navigate(['/user/profile'])
      });
      return;
    }
    this.router.navigate([toGo, this.origin.transactionId]);
  }

  setStep(index: number) {
    this.step = index;
  }

}
