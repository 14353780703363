// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-unitec-logo {
  height: 25px;
}

::ng-deep.mat-drawer.miniSidenav::ng-deep .nav-unitec-logo {
  height: 10px;
}

::ng-deep.mat-drawer.miniSidenav:hover::ng-deep .nav-unitec-logo {
  height: 25px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/layouts/components/unitec-shield/unitec-shield.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAIE;EACE,YAAA;AADJ;;AAME;EACE,YAAA;AAHJ","sourcesContent":[".nav-unitec-logo {\r\n  height: 25px;\r\n}\r\n\r\n\r\n::ng-deep.mat-drawer.miniSidenav {\r\n  &::ng-deep .nav-unitec-logo {\r\n    height: 10px;\r\n  }\r\n}\r\n\r\n::ng-deep.mat-drawer.miniSidenav:hover {\r\n  &::ng-deep .nav-unitec-logo {\r\n    height: 25px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
