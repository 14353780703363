import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ICalculatorResponse, IEnrollmentData, IEnrollmentPaymentDetails, IEnrollmentSubjectsBox } from 'src/app/core/interfaces/early-enrollment/iearly-enrollment';
import { IEarlyEnrollmentState } from '../interfaces/iearly-enrollment-status';
import * as EARLY_ENROLLMENT_ACTIONS from '../store/early-enrollment.actions';
import { getState } from '../store/early-enrollment.selectors';

@Injectable()
export class EarlyEnrollmentFacade {

  private state$: Observable<IEarlyEnrollmentState> = this.store.select(getState);

  enrollment$: Observable<IEarlyEnrollmentState> = this.state$.pipe(select(state => state));
  active$: Observable<boolean> = this.state$.pipe(select(state => state.active));
  studentData$: Observable<any> = this.state$.pipe(select(state => state.studentData));
  subjectsData$: Observable<any> = this.state$.pipe(select(state => state.subjectsData));
  paymentResumeData$: Observable<any> = this.state$.pipe(select(state => state.paymentResumeData));
  paymentData$: Observable<any> = this.state$.pipe(select(state => state.paymentData));

  constructor(
    private store: Store<IEarlyEnrollmentState>
  ) { }

  setEnrollmentData(data: IEarlyEnrollmentState) {
    this.store.dispatch(EARLY_ENROLLMENT_ACTIONS.SET_ENROLLMENT_DATA({ payload: data }));
  }

  setActive(active: boolean) {
    this.store.dispatch(EARLY_ENROLLMENT_ACTIONS.SET_ACTIVE({ payload: active }));
  }

  setStudentData(data: IEnrollmentData) {
    this.store.dispatch(EARLY_ENROLLMENT_ACTIONS.SET_STUDENT_DATA({ payload: data }));
  }

  setSubjectData(data: IEnrollmentSubjectsBox[]) {
    this.store.dispatch(EARLY_ENROLLMENT_ACTIONS.SET_SUBJECTS_DATA({ payload: data }));
  }

  setPaymentResumeData(data: ICalculatorResponse) {
    this.store.dispatch(EARLY_ENROLLMENT_ACTIONS.SET_PAYMENT_RESUME_DATA({ payload: data }));
  }

  setPaymentData(data: any) {
    this.store.dispatch(EARLY_ENROLLMENT_ACTIONS.SET_PAYMENT_DATA({ payload: data }));
  }

  removeData(active: boolean) {
    this.store.dispatch(EARLY_ENROLLMENT_ACTIONS.REMOVE_DATA({ payload: active }));
  }

}
