import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionFacade } from './facades/transaction-facade';
import { StoreModule } from '@ngrx/store';
import { TransactionReducer } from './store/transaction.reducer';


@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('transaction', TransactionReducer),
  ],
  declarations: [],
  providers: [
    TransactionFacade
  ]
})
export class TransactionStoreModule { }
