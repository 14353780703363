import { NgModule } from '@angular/core';

/**
 * Modules
 */
import { SharedModule } from '../../shared/shared.module';
import { CommonModule } from '@angular/common';

/**
 * Components
 */
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { SliderComponent } from './components/slider/slider.component';
import { WelcomeDialogComponent } from './components/welcome-dialog/welcome-dialog.component';
import { LogoutPageComponent } from './pages/logout-page/logout-page.component';
import { PasswordRecoveryComponent } from './pages/password-recovery/password-recovery.component';
import { ChangeUserPageComponent } from './pages/change-user-page/change-user-page.component';

/**
 * Services
 */
import { AuthService } from '../../core/session/services/auth/auth.service';
import { ServerBusyPageComponent } from './pages/server-busy-page/server-busy-page.component';
import { TutorDialogComponent } from './components/tutor-dialog/tutor-dialog.component';
import { ConfirmationTokenPageComponent } from './pages/confirmation-token-page/confirmation-token-page.component';
import { MfaDialogComponent } from './components/mfa-dialog/mfa-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [
    LoginPageComponent,
    SliderComponent,
    WelcomeDialogComponent,
    LogoutPageComponent,
    PasswordRecoveryComponent,
    ServerBusyPageComponent,
    TutorDialogComponent,
    ChangeUserPageComponent,
    ConfirmationTokenPageComponent,
    MfaDialogComponent
  ],
  providers: [AuthService]
})
export class AuthModule { }
