import { LogLevel } from "@azure/msal-browser";

export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(`${logLevel} - ${message}`);
}

export var _log = window.console ?
    window.console.log : function () { };

export var _log_error = window.console ?
    window.console.error : function () { };

export var _log_warn = window.console ?
    window.console.warn : function () { };

export var _log_info = window.console ?
    window.console.info : function () { };

export var _log_debug = window.console ?
    window.console.debug : function () { };

export var _log_trace = window.console ?
    window.console.trace : function () { };

export var _log_clear = window.console ?
    window.console.clear : function () { };

// console.log = function () {
//     var args = Array.prototype.slice.call(arguments);
//     _log.apply(window.console, args);
// }