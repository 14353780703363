import { Action, createReducer, on } from '@ngrx/store';
import * as APPLICATION_ACTIONS from "./application.actions";
import { APPLICATION_STATE, IApplicationState } from '../interfaces/application.state';

const _applicationReducer = createReducer(APPLICATION_STATE,
    on(APPLICATION_ACTIONS.INIT, (state: IApplicationState) => ({ ...state, init: true })),
    on(APPLICATION_ACTIONS.SET_TITLE, (state: IApplicationState, { title }) => ({ ...state, title })),
    on(APPLICATION_ACTIONS.SET_IP, (state: IApplicationState, { ipInfo }) => ({ ...state, ip: ipInfo }))
);

export function ApplicationReducer(state: IApplicationState, action: Action) {
    return _applicationReducer(state, action);
}
