import { createReducer, on } from '@ngrx/store';
import { CHATBOT_STATE } from './../interfaces/chatbot.state';
import * as CHATBOT_ACTIONS from './chatbot.actions';
import { IChatbotState } from '../interfaces/ichatbot-status';

const _chatbotReducer = createReducer(CHATBOT_STATE,
    on(CHATBOT_ACTIONS.SET_CHATBOT, (state: IChatbotState) => ({ ...state, active: true })),
    on(CHATBOT_ACTIONS.ADD_CHANELID, (state: IChatbotState, { payload }) => ({ ...state, chanelId: payload, active: true })),
    on(CHATBOT_ACTIONS.ADD_AZUREAD, (state: IChatbotState, { payload }) => ({ ...state, azureAD: payload, active: true })),
    on(CHATBOT_ACTIONS.ADD_AUTHBOT, (state: IChatbotState, { payload }) => ({ ...state, botAuth: payload, active: true })),
    on(CHATBOT_ACTIONS.REMOVE_CHATBOT, (state: IChatbotState, { payload }) => ({ ...state, chanelId: null, botAuth: null, active: payload }))
);

export function ChatbotReducer(state, action) {
    return _chatbotReducer(state, action);
}
