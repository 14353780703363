import { createAction, props } from '@ngrx/store';
import { IAzureAD, IAuthBot } from '../interfaces/ichatbot-status';

const SET_CHATBOT = createAction('[CHATBOT] set CHATBOT data', props<{ payload: boolean }>());

const ADD_CHANELID = createAction('[CHATBOT] add CHANELID data', props<{ payload: string }>());

const ADD_AZUREAD = createAction('[CHATBOT] add AZUREAD data', props<{ payload: IAzureAD }>());

const ADD_AUTHBOT = createAction('[CHATBOT] add AUTHBOT data', props<{ payload: IAuthBot }>());

const REMOVE_CHATBOT = createAction('[CHATBOT] remove CHATBOT data', props<{ payload: boolean }>());

export { SET_CHATBOT, ADD_CHANELID, ADD_AZUREAD, ADD_AUTHBOT, REMOVE_CHATBOT }