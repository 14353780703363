// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
  margin-right: 5px;
}
.btn:last-child {
  margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/layouts/components/login-button/login-button.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAAE;EACE,eAAA;AAEJ","sourcesContent":[".btn {\r\n  margin-right: 5px;\r\n  &:last-child {\r\n    margin-right: 0;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
