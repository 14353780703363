import {
  Component, OnInit, AfterViewInit, Input, OnDestroy
} from '@angular/core';
import { IStatusData } from 'src/app/core/redux/transactions-engine/interfaces/itransactions-filter';
import { IProcedureData } from 'src/app/core/redux/transactions-engine/interfaces/IProcedureData';
import { TransactionsService } from 'src/app/core/api/transactions-engine/transactions.service';
import { UserFacade } from 'src/app/core/redux/user/facades/user-facade';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-transaction-tracker',
  templateUrl: './transaction-tracker.component.html',
  styleUrls: ['./transaction-tracker.component.scss']
})
export class TransactionTrackerComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input('transaction') transaction?: string;
  @Input('procedure') procedure?: IProcedureData;
  @Input('trackerName') trackerName?: string;
  isLoading = true;
  byTrackerName = false;

  profile$ = this.userFacade.profile$;
  constructor(
    private transactionsService: TransactionsService,
    private userFacade: UserFacade
  ) { }

  ngOnInit() {
    if (this.transaction != null && this.transaction !== 'undefined') {
      this.profile$.pipe(untilDestroyed(this)).subscribe(profile => {
        this.transactionsService.getTransactionTracker(this.transaction, profile.id).pipe(untilDestroyed(this)).subscribe(tracker => {
          if (tracker) {
            if (tracker.additionalData) {
              const statusTracker = this.order(tracker.additionalData);
              let status: Array<IStatusData> = [];
              tracker.additionalData.forEach(step => {
                const s = <IStatusData>{
                  statusId: step.statusId,
                  statusName: step.statusName,
                  statusNameTracker: step.statusNameTracker,
                  message: step.statusName,
                  procedureID: tracker.data.procedureId,
                  color: '',
                  orderStatus: step.order,
                  stepPath: '',
                  active: step.isActive,
                  editable: false,
                  completed: step.isComplete
                };
                status.push(s);
              });
              const procedure: IProcedureData = {
                procedureId: tracker.data.procedureId,
                procedureName: tracker.data.documentName,
                active: true,
                frontendValue: tracker.data.documentName,
                procedureType: tracker.data.documentName,
                transactionsStatus: status,
              };
              this.procedure = procedure;
              this.isLoading = false;
            }
          }
        });
      });
    } else {
      if (this.procedure != null && typeof this.procedure !== 'undefined') {
        this.isLoading = false;
      } else {
        if(this.trackerName != null && typeof this.trackerName !== 'undefined' && this.trackerName != '') {
          this.byTrackerName = true;
        }
      }
    }
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    // console.log('DESTROYING COMPONENT ');
  }

  order(status: Array<any>) {
    let order: Array<any> = [];
    order = status.sort((a, b) => {
      if (a.order > b.order) {
        return 1;
      }
      if (a.order < b.order) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return order;
  }

}
