import { createReducer, on } from "@ngrx/store";
import { IReportReenrollmentState } from "../interfaces/ireport-reenrollment-status";
import * as REPORT_REENROLLMENT_ACTIONS from "./report-reenrollment.actions";
import { REPORT_REENROLLMENT_STATE } from "./../interfaces/report-reenrollment.state";

const _reportReenrollmentReducer = createReducer(
  REPORT_REENROLLMENT_STATE,
  on(REPORT_REENROLLMENT_ACTIONS.SET_TOKEN_DATA, (state: IReportReenrollmentState, { report }) => ({
    ...state,
    tokenData: report,
  })),
  on(REPORT_REENROLLMENT_ACTIONS.REMOVE_DATA, (state: IReportReenrollmentState, { report }) => ({
    ...REPORT_REENROLLMENT_STATE,
    active: false,
  }))
);

export function ReportReenrollmentReducer(state, action) {
  return _reportReenrollmentReducer(state, action);
}
