import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, EMPTY, exhaustMap, map } from "rxjs";
import * as NAVIGATION_ACTIONS from "./navigation.actions";
import { NavigationFacade } from "../facades/navigation-facade";
import { NavigationService } from "../../../api/navigation/navigation.service";
import { INavigationTree } from "../interfaces/inavigation-tree";
import { INavigationNodeData } from "../interfaces/inavigation-node-data";

@Injectable()
export class NavigationEffects {

    loadMenus$ = createEffect(() => this.actions$.pipe(
        ofType(NAVIGATION_ACTIONS.GET_NAVIGATION),
        exhaustMap((payload) => this.navigationService.getMenu(payload.userId, true)
            .pipe(
                map((navigationNodes: INavigationTree<INavigationNodeData>) => NAVIGATION_ACTIONS.SET_CONFIG({ payload: navigationNodes })),
                catchError(() => EMPTY)
            ))
    )
    );

    constructor(
        private actions$: Actions,
        private navigationFacade: NavigationFacade,
        private navigationService: NavigationService
    ) { }
}