import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';
import { RestoreProcedureRoutingModule } from './restore-procedure-routing.module';
import { RestoreProcedureComponent } from './pages/restore-procedure/restore-procedure.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RestoreProcedureRoutingModule
  ],
  declarations: [
    RestoreProcedureComponent
  ],
  exports: [
    RestoreProcedureComponent
  ]
})
export class RestoreProcedureModule { }
