import { Injectable } from '@angular/core';
import { AppHttp } from '../../../http/services/app-http/app-http.service';
import { EndpointsService } from '../../../http/services/endpoints/endpoints.service';
import { Endpoint } from '../../../http/models/endpoint/endpoint';
import { IResponse } from '../../../http/interfaces/iresponse';
import { ICareerData } from '../../../interfaces/catalogues/icareer-data';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CareerService {

  careerEndpoint: Endpoint;
  careersByWordEndpoint: Endpoint;
  constructor(
    private http: AppHttp,
    private endpoints: EndpointsService
  ) {
    this.careerEndpoint = this.endpoints.catalogs.careers;
    this.careersByWordEndpoint = this.endpoints.catalogs.careersByWord;
  }

  getCareerList(campusId: string) {
    this.careerEndpoint.useWildcardParams({ campusId });
    return this.http.get<IResponse<ICareerData>>(this.careerEndpoint)
      .pipe(map(response => response.data));
  }

  getCareersByWordList(word: string) {
    this.careersByWordEndpoint.useWildcardParams({ word });
    return this.http.get<IResponse<ICareerData>>(this.careersByWordEndpoint)
      .pipe(map(response => response.data));
  }
}
