import { Inject, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MAIN_REDUCERS } from '../../app.reducer';
import { MAIN_STATE } from '../../app.state';
import { environment } from '../../../environments/environment';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AppFacade } from './facades/app-facade';
import { UserFacade } from '../data/user/facades/user-facade';
import { ImpersonateFacade } from '../data/impersonate/facades/impersonate-facade';
import { NavigationFacade } from '../data/navigation/facades/navigation-facade';
import { Router } from '@angular/router';
import { ConfirmationFacade } from '../data/confirmation/facades/confirmation-facade';
import { EffectsModule } from '@ngrx/effects';
import { ApplicationStoreModule } from './application-store.module';
import { CreateTutorReducer } from 'src/app/modules/admin/tutors/redux/admin-tutor.reducer';
import { BillingReducer } from '../data/billing/redux/billing.reducer';
import { ChatbotReducer } from '../data/chatbot/redux/chatbot.reducer';
import { ConfirmationReducer } from '../data/confirmation/redux/confirmation.reducer';
import { DocumentReducer } from '../data/document/redux/document.reducer';
import { EarlyEnrollmentReducer } from '../data/early-enrollment/redux/early-enrollment.reducer';
import { ImpersonateReducer } from '../data/impersonate/redux/impersonate.reducer';
import { NavigationReducer } from '../data/navigation/redux/navigation.reducer';
import { NotificationReducer } from '../data/notification/redux/notification.reducer';
import { PaymentReducer } from '../data/payment/redux/payment.reducer';
import { ReenrollmentReducer } from '../data/reenrollment/redux/reenrollment.reducer';
import { ReportReenrollmentReducer } from '../data/report-reenrollment/redux/report-reenrollment.reducer';
import { TransactionReducer } from '../data/transactions-engine/redux/transaction.reducer';
import { UserReducer } from '../data/user/redux/user.reducer';
import { NavigationEffects } from '../data/navigation/redux/navigation.effect';
import { provideBootstrapEffects } from './bootstrap-effect-provider';
import { MenuAdminReducer } from '../data/menu-admin/redux/menu-admin.reducer';
import { MenuAdminEffects } from '../data/menu-admin/redux/menu-admin.effect';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forRoot([]),
    ApplicationStoreModule,
    StoreModule.forFeature('navigation', NavigationReducer),
    StoreModule.forFeature('impersonate', ImpersonateReducer),
    StoreModule.forFeature('user', UserReducer),
    StoreModule.forFeature('notification', NotificationReducer),
    StoreModule.forFeature('payment', PaymentReducer),
    StoreModule.forFeature('transaction', TransactionReducer),
    StoreModule.forFeature('chatbot', ChatbotReducer),
    StoreModule.forFeature('confirmation', ConfirmationReducer),
    StoreModule.forFeature('document', DocumentReducer),
    StoreModule.forFeature('createTutor', CreateTutorReducer),
    StoreModule.forFeature('billing', BillingReducer),
    StoreModule.forFeature('earlyEnrollment', EarlyEnrollmentReducer),
    StoreModule.forFeature('reenrollment', ReenrollmentReducer),
    StoreModule.forFeature('reportReenrollment', ReportReenrollmentReducer),
	StoreModule.forFeature('menuAdmin', MenuAdminReducer),
    StoreDevtoolsModule.instrument({
      maxAge: 5, // Retains last 5 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
  ],
  declarations: [],
  providers: [
    provideBootstrapEffects([
      NavigationEffects,
	  MenuAdminEffects
    ])
  ],
})
export class ReduxModule {
  constructor(
    private userFacade: UserFacade,
    private impersonateFacade: ImpersonateFacade,
    private navigationFacade: NavigationFacade,
    private confirmationFacade: ConfirmationFacade,
    private appFacade: AppFacade,
    private router: Router,
    private store: Store<any>,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {
    this.store.subscribe(state => {
      //this.storage.set('_app_state', state);
    });
  }
}
