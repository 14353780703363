/**
 * ---------------------------------
 * Core application Modules
 * ---------------------------------
 */

/**
 * @angular
 */
import { isDevMode, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../../environments/environment';

/**
 * @vendor
 */

/**
 * @app
 */
import { SessionModule } from './session/session.module';
import { HttpModule } from './http/http.module';
import { ServicesModule } from './services/services.module';
import { AppRoutingModule } from './routing/app-routing.module';
import { ConfigModule } from './config/config.module';
import { BootstrapModule } from './bootstrap/bootstrap.module';
import { SupportingDocumentsModule } from './data/supporting-documents/supporting-documents.module';
import { CampusNamePipe } from './utilities/pipes/campus-name.pipe';
import { PaymentModule } from '../modules/payment/payment.module';
import { RestoreProcedureModule } from '../modules/restore-procedure/restore-procedure.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { ConfigService } from './config/services/config.service';
import { SchoolServiceResolve } from './routing/resolver/school-service/school-service.resolve';
import { BankBridgeModule } from '../modules/payment/modules/bank-bridge/bank-bridge.module';
import { BankBridgeNewModule } from '../modules/payment/modules/bank-bridge-v2/bank-bridge.v2.module';
import { ReduxModule } from './redux/redux.module';

export function HttpLoaderFactory(http: HttpClient, config: ConfigService) {
  return new TranslateHttpLoader(http, config.get('globalization.url', ''), '');
}

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    let val = '';
    if (params != null && typeof params !== 'undefined') {
      if (params.interpolateParams != null && typeof params.interpolateParams !== 'undefined') {
        val = (params.interpolateParams['value'] != null && typeof params.interpolateParams['value'] !== 'undefined') ? params.interpolateParams['value'] : '';
      }
    }
    return val;
  }
}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: !isDevMode(), registrationStrategy: 'registerWhenStable:30000' }),
    HttpModule,
    SessionModule,
    ReduxModule,
    ServicesModule,
    AppRoutingModule,
    ConfigModule,
    BootstrapModule,
    SupportingDocumentsModule,
    RestoreProcedureModule,
    PaymentModule,
    BankBridgeModule,
    BankBridgeNewModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, ConfigService]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler
      }
    })
  ],
  declarations: [CampusNamePipe],
  providers: [
    SchoolServiceResolve
  ],
  exports: [
    AppRoutingModule
  ]
})
export class CoreModule { }


