// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

::ng-deep .h-1rem {
  height: 1rem !important;
}

.full-image {
  width: 100%;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/admin/calendars/components/file-uploader/file-uploader.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF","sourcesContent":[":host {\r\n  display: block;\r\n}\r\n\r\n::ng-deep .h-1rem {\r\n  height: 1rem !important;\r\n}\r\n\r\n.full-image {\r\n  width: 100%;\r\n  height: auto;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
