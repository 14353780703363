// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div#socialServiceContainer {
  margin: 16px;
}

div#socialServiceContainer mat-card {
  max-width: 720px;
}

div#socialServiceContainer .flex-wrap {
  flex-wrap: wrap;
}

div#socialServiceContainer .center {
  text-align: center;
}

div#socialServiceContainer dd ul {
  margin: 0;
}

div#socialServiceContainer dd p {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/school-services/pages/social-service/components/social-service-program/social-service-program.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AACA;EACE,gBAAA;AAEF;;AAGA;EACE,eAAA;AAAF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,SAAA;AACF;;AACA;EACE,SAAA;AAEF","sourcesContent":["div#socialServiceContainer {\r\n  margin: 16px;\r\n}\r\ndiv#socialServiceContainer mat-card {\r\n  max-width: 720px;\r\n}\r\n// div#socialServiceContainer dl {\r\n//   flex-wrap: wrap;\r\n// }\r\ndiv#socialServiceContainer .flex-wrap{\r\n  flex-wrap: wrap;\r\n}\r\ndiv#socialServiceContainer .center{\r\n  text-align: center;\r\n}\r\n\r\ndiv#socialServiceContainer dd ul{\r\n  margin: 0;\r\n}\r\ndiv#socialServiceContainer dd p{\r\n  margin: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
