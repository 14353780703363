// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-menu {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: calc(100vh - 500px);
  height: calc(100vh - 105px);
  max-height: calc(100vh - 64px);
  flex-grow: 1;
}

::ng-deep .cdk-overlay-connected-position-bounding-box:first-child .mat-menu-panel {
  left: 74px !important;
  position: fixed !important;
  min-height: 100px;
  height: auto;
  max-height: 50%;
}

@media (max-height: 590px) {
  ::ng-deep .cdk-overlay-connected-position-bounding-box:first-child .mat-menu-panel {
    bottom: 100px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/layouts/components/nav-list/nav-list.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,+BAAA;EACA,2BAAA;EACA,8BAAA;EACA,YAAA;AACF;;AAGE;EACE,qBAAA;EACA,0BAAA;EACA,iBAAA;EACA,YAAA;EACA,eAAA;AAAJ;;AAIA;EAEI;IACE,aAAA;EAFJ;AACF","sourcesContent":[".side-menu {\r\n  position: relative;\r\n  overflow-x: hidden;\r\n  overflow-y: auto;\r\n  min-height: calc(100vh - 500px);\r\n  height: calc(100vh - 105px);\r\n  max-height: calc(100vh - 64px);\r\n  flex-grow: 1;\r\n}\r\n\r\n::ng-deep .cdk-overlay-connected-position-bounding-box:first-child {\r\n  .mat-menu-panel {\r\n    left: 74px !important;\r\n    position: fixed !important;\r\n    min-height: 100px;\r\n    height: auto;\r\n    max-height: 50%;\r\n  }\r\n}\r\n\r\n@media (max-height: 590px) {\r\n  ::ng-deep .cdk-overlay-connected-position-bounding-box:first-child {\r\n    .mat-menu-panel {\r\n      bottom: 100px;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
