import { CommonModule } from "@angular/common";
import { MenuAdminFacade } from "./facades/menu-admin.facade";
import { NgModule } from "@angular/core";

@NgModule({
  imports: [
	CommonModule
  ],
  providers: [
	MenuAdminFacade
  ],
})
export class MenuAdminModule { }
