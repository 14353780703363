import { Component, OnInit, Inject, Output, EventEmitter, Input } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { DOCUMENT } from '@angular/common';
import { IImageEvent } from 'src/app/core/interfaces/iimage-event';
import { map, Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@UntilDestroy()
@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss']
})
export class ImageEditorComponent implements OnInit {

  @Output('loaded') loaded: EventEmitter<IImageEvent> = new EventEmitter();
  @Input('imgBase64') imgBase64 : string;
  imageChangedEvent: any = '';
  croppedImage: any = '';

  watcher: Subscription;
  activeMediaQuery = '';
  loadMobileContent = false;
  imgLoadedFromServer = false;

  constructor(
    @Inject(DOCUMENT) document,
    private breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit() {
    this.watcher = this.breakpointObserver.observe(
      [Breakpoints.XSmall,
      Breakpoints.Handset])
      .pipe(map(result => result.matches)).subscribe(mobile => this.loadMobileContent = mobile);
    if (this.imgBase64) {
      this.croppedImage = 'data:image/png;base64,' + this.imgBase64;
      this.imgLoadedFromServer = true;
    }
  }


  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.imgLoadedFromServer = false;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    const eventLoaded: IImageEvent = {
      data: this.croppedImage,
      info: '',
      success: true
    };

    this.loaded.emit(eventLoaded);
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready

  }
  loadImageFailed() {
    // show message
  }
}
