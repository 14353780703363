// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div#socialServiceContainer {
  margin: 16px;
}

div#socialServiceContainer mat-card {
  margin-bottom: 16px;
}

div#socialServiceContainer mat-card.info {
  width: 720px;
}

div#socialServiceContainer mat-card.data {
  width: 100%;
}

div#socialServiceContainer .center-text {
  text-align: center;
}

div#socialServiceContainer table {
  width: 100%;
}

div#socialServiceContainer tr:hover {
  background: whitesmoke;
  cursor: pointer;
}

table th {
  padding-top: 15px;
}

table td {
  font-size: 13px;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/school-services/pages/social-service/pages/social-service.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AACA;EACE,mBAAA;AAEF;;AACA;EACE,YAAA;AAEF;;AAAA;EACE,WAAA;AAGF;;AADA;EACE,kBAAA;AAIF;;AAAA;EACE,WAAA;AAGF;;AAAA;EACE,sBAAA;EACA,eAAA;AAGF;;AAAA;EACE,iBAAA;AAGF;;AACA;EACE,eAAA;EACA,iBAAA;AAEF","sourcesContent":["div#socialServiceContainer {\r\n  margin: 16px;\r\n}\r\ndiv#socialServiceContainer mat-card {\r\n  margin-bottom: 16px;\r\n}\r\n\r\ndiv#socialServiceContainer mat-card.info {\r\n  width: 720px;\r\n}\r\ndiv#socialServiceContainer mat-card.data {\r\n  width: 100%;\r\n}\r\ndiv#socialServiceContainer .center-text{\r\n  text-align: center;\r\n}\r\n\r\n\r\ndiv#socialServiceContainer table {\r\n  width: 100%;\r\n}\r\n\r\ndiv#socialServiceContainer tr:hover {\r\n  background: whitesmoke;\r\n  cursor: pointer;\r\n}\r\n\r\ntable th{\r\n  padding-top: 15px;\r\n  //text-align: center;\r\n}\r\n\r\ntable td{\r\n  font-size: 13px;\r\n  margin-left: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
