import { createReducer, on } from '@ngrx/store';
import { IEarlyEnrollmentState } from '../interfaces/iearly-enrollment-status';
import * as EARLY_ENROLLMENT_ACTIONS from './early-enrollment.actions';
import { EARLYENROLLMENT_STATE } from './../interfaces/early-enrollment.state';


const _earlyEnrollmentReducer = createReducer(EARLYENROLLMENT_STATE,
  on(EARLY_ENROLLMENT_ACTIONS.SET_ENROLLMENT_DATA, (state: IEarlyEnrollmentState, { payload }) => ({ ...state, ...payload })),
  on(EARLY_ENROLLMENT_ACTIONS.SET_ACTIVE, (state: IEarlyEnrollmentState, { payload }) => ({ ...state, active: true })),
  on(EARLY_ENROLLMENT_ACTIONS.SET_STUDENT_DATA, (state: IEarlyEnrollmentState, { payload }) => ({ ...state, studentData: payload })),
  on(EARLY_ENROLLMENT_ACTIONS.SET_SUBJECTS_DATA, (state: IEarlyEnrollmentState, { payload }) => ({ ...state, subjectsData: payload })),
  on(EARLY_ENROLLMENT_ACTIONS.SET_PAYMENT_RESUME_DATA, (state: IEarlyEnrollmentState, { payload }) => ({ ...state, paymentResumeData: payload })),
  on(EARLY_ENROLLMENT_ACTIONS.SET_PAYMENT_DATA, (state: IEarlyEnrollmentState, { payload }) => ({ ...state, paymentResumeData: payload })),
  on(EARLY_ENROLLMENT_ACTIONS.REMOVE_DATA, (state: IEarlyEnrollmentState, { payload }) => ({ ...EARLYENROLLMENT_STATE, active: false }))
);

export function EarlyEnrollmentReducer(state, action) {
  return _earlyEnrollmentReducer(state, action);
}
