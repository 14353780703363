import { Injectable, OnDestroy } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { Subscription, timer, interval } from "rxjs";
import { TitleService } from "../title/title.service";
import { NAVIGABLE_ROUTES } from "../../redux/navigation/navigable";
import { WINDOW_TITLES } from "../../data/constants/windows-title";
import { HIDE_SIDEBAR, HIDE_SIDEBAR_TIME, REQUESTED_URL_QUERIES } from "../../session/services/auth/auth.service";
import { NavigationFacade } from "../../redux/navigation/facades/navigation-facade";
import { INavigationNodeData } from "../../redux/navigation/interfaces/inavigation-node-data";

@Injectable({
  providedIn: 'root'
})
export class NavigationService implements OnDestroy {

  private history = [];
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  private eventsSubscription: Subscription;
  private initialized = false;

  navigables = NAVIGABLE_ROUTES;
  titles = WINDOW_TITLES;
  mainTitle = WINDOW_TITLES.main;
  timerSuscription: Subscription;

  timer: any;
  nodes: INavigationNodeData[] = [];

  constructor(
    private router: Router,
    private titleService: TitleService,
    private navigationFacade: NavigationFacade
  ) {
    this.navigationFacade.configState$.subscribe(config => {
      this.nodes = config.main;
    });
  }

  ngOnDestroy(): void {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
    if (this.timerSuscription) {
      this.timerSuscription.unsubscribe();
    }
  }

  public loadRouting(): void {
    this.initialized = true;
    this.currentUrl = this.router.url;
    //this.validateCurrentRoute();
    this.eventsSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = urlAfterRedirects;
        this.history = [...this.history, this.currentUrl];

        console.log('currentUrl -> ' + this.getCurrentUrl());
        //console.log('previusUrl -> ' + this.getPreviousUrl());
        console.log('history -> ' + this.getHistory());
        const nav = Object.keys(this.navigables).find(n => this.navigables[n] === this.currentUrl);
        if (nav != null && typeof nav !== 'undefined') {
          setTimeout(() => {
            this.titleService.setTitle(this.titles['main.' + nav]);
            document.title = `${this.titles['main.' + nav]} | Ventanilla Virtual`;
          }, 500);

        }
        if (this.currentUrl == '/user/profile') {
          //console.log('this.currentUrl CLOSE', this.currentUrl, this.history);
          setTimeout(() => {
            this.updateCurrentNode(this.currentUrl);
          }, 1000);
          let hideSidebar = window.localStorage.getItem(HIDE_SIDEBAR);
          if (hideSidebar != null) {
            let hs = Boolean(hideSidebar);
            if (hs) {
              window.localStorage.removeItem(REQUESTED_URL_QUERIES);
              localStorage.removeItem(HIDE_SIDEBAR);
              localStorage.removeItem(HIDE_SIDEBAR_TIME);
              window.close();
            }
          }
          //window.close();
        }
      });
  }

  updateCurrentNode(url: string) {
    console.log('updateCurrentNode', url);
    let id = Object.keys(this.navigables).find(n => this.navigables[n] === this.currentUrl);
    console.log(`had to get main.${id} in `, this.nodes);
    let current = this.getNode(id, this.nodes);
    console.log('node found: ', current);
    if (current) {
      this.navigationFacade.setCurrent(current);
    }
  }

  getNode(id: string, source: INavigationNodeData[]): INavigationNodeData | undefined {
    let current = source.find(n => n.id.includes(id));
    if (current) return current;
    for (let i = 0; i < source.length; i++) {
      if (source[i].children?.length) {
        current = this.getNode(id, source[i].children);
        if (current) {
          break;
        }
      }
    }
    return current;
  }

  getParentId(id: string) {
    let idSplited = id.split('.');
    idSplited.pop();
    return idSplited.join('.');
  }

  public validateCurrentRoute() {
    this.currentUrl = this.router.url;
    //console.log('loadRouting: ', this.currentUrl);
    if (this.currentUrl == '/') {
      this.router.navigate(['/login']);
    }
    if (this.currentUrl == '/login') {
      this.router.navigate(['/user/profile']);
    }
    if (this.currentUrl == '/changeUser') {
      this.router.navigate(['/login']);
    }
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getCurrentUrl(): string {
    return this.currentUrl;
  }

  public getPreviousUrl(): string {
    return this.previousUrl;
  }

  public isInitialized(): boolean {
    return this.initialized;
  }
}