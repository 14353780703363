import { IConfirmationState } from "../interfaces/iconfirmation-status";

export const CONFIRMATION_STATE: IConfirmationState = {
    accessToken: null,
    idToken: null,
    active: false,
    finished: false,
    error: null,
    user: null
};
