/**
 * ---------------------------------
 * Core application Modules
 * ---------------------------------
 */

/**
 * @angular
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../../environments/environment';

/**
 * @vendor
 */
import { StorageServiceModule } from 'ngx-webstorage-service';

/**
 * @app
 */
import { SessionModule } from './session/session.module';
import { HttpModule } from './http/http.module';
import { ServicesModule } from './services/services.module';
import { AppRoutingModule } from './routing/app-routing.module';
import { ConfigModule } from './config/config.module';
import { ReduxModule } from './redux/redux.module';
import { BootstrapModule } from './bootstrap/bootstrap.module';
import { NavigationModule } from './data/navigation/navigation.module';
import { UserModule } from './data/user/user.module';
import { ImpersonateModule } from './data/impersonate/impersonate.module';
import { CatalogsModule } from './data/catalogs/catalogs.module';
import { NotificationModule } from './data/notification/notification.module';
import { SupportingDocumentsModule } from './data/supporting-documents/supporting-documents.module';
import { CampusNamePipe } from './pipes/campus-name.pipe';
import { PaymentModule } from '../modules/payment/payment.module';
import { TransactionModule } from './data/transactions-engine/transaction.module';
import { RestoreProcedureModule } from '../modules/restore-procedure/restore-procedure.module';
import { PaymentStoreModule } from './data/payment/payment.module';
import { ConfirmationModule } from './data/confirmation/confirmation.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { ConfigService } from './config/services/config.service';
import { GenericDocumentResolve } from './data/resolver/generic-document/generic-document.resolve';
import { DocumentModule } from './data/document/document.module';
import { ChatbotModule } from './data/chatbot/chatbot.module';
import { SchoolServiceResolve } from './data/resolver/school-service/school-service.resolve';
import { BillingStoreModule } from './data/billing/billing.module';
import { EarlyEnrollmentStoreModule } from './data/early-enrollment/early-enrollment.module';
import { ReenrollmentStoreModule } from './data/reenrollment/reenrollment.module';
import { ReportReenrollmentStoreModule } from './data/report-reenrollment/report-reenrollment.module';
import { BankBridgeModule } from '../modules/payment/modules/bank-bridge/bank-bridge.module';
import { BankBridgeNewModule } from '../modules/payment/modules/bank-bridge-v2/bank-bridge.v2.module';
import { TitleRequestStoreModule } from './data/title-request/title-request.module';
import { MenuAdminModule } from './data/menu-admin/menu-admin.module';

export function HttpLoaderFactory(http: HttpClient, config: ConfigService) {
	return new TranslateHttpLoader(http, config.get('globalization.url', ''), '');
}

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
	handle(params: MissingTranslationHandlerParams) {
		let val = '';
		if (params != null && typeof params !== 'undefined') {
			if (params.interpolateParams != null && typeof params.interpolateParams !== 'undefined') {
				val = (params.interpolateParams['value'] != null && typeof params.interpolateParams['value'] !== 'undefined') ? params.interpolateParams['value'] : '';
			}
		}
		return val;
	}
}

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
		StorageServiceModule,
		HttpModule,
		SessionModule,
		ReduxModule,
		ServicesModule,
		AppRoutingModule,
		ConfigModule,
		NotificationModule.forRoot(),
		BootstrapModule,
		NavigationModule,
		MenuAdminModule,
		UserModule,
		DocumentModule,
		TransactionModule,
		ImpersonateModule,
		PaymentStoreModule,
		ConfirmationModule,
		ChatbotModule,
		BillingStoreModule,
		EarlyEnrollmentStoreModule,
		CatalogsModule,
		SupportingDocumentsModule,
		PaymentModule,
		RestoreProcedureModule,
		BankBridgeModule,
		ReenrollmentStoreModule,
		TitleRequestStoreModule,
		ReportReenrollmentStoreModule,
		BankBridgeNewModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient, ConfigService]
			},
			missingTranslationHandler: {
				provide: MissingTranslationHandler,
				useClass: CustomMissingTranslationHandler
			}
		})
	],
	declarations: [CampusNamePipe],
	providers: [
		SchoolServiceResolve
	],
	exports: [
		AppRoutingModule
	]
})
export class CoreModule { }


