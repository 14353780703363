import { Injectable } from '@angular/core';
import { ConfigService } from '../../config/services/config.service';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { INotificationData } from '../../redux/notification/interfaces/inotification-data';
import * as signalR from '@microsoft/signalr';
import { MsalService } from '@azure/msal-angular';
import { UserFacade } from '../../redux/user/facades/user-facade';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountInfo, AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';
import { NotificationService } from 'src/app/core/api/notification/notification.service';
import { _log } from '../../utilities/logger/logger';
import { LoggerService } from '../../services/logger.service/logger.service';
import { BaseInjectable } from '../../utilities/injectables/base-injectable';

@Injectable({
  providedIn: 'root'
})
export class NotificationClient extends BaseInjectable {
  host: string = this.config.get('notifications.host');
  subscriptionPath: string = this.config.get('notifications.subscription');

  /** New implementation */
  private connection!: signalR.HubConnection;

  connectedSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
  notificationSource: BehaviorSubject<INotificationData> = new BehaviorSubject(null);

  connected$: Observable<boolean> = this.connectedSource.asObservable();
  notification$: Observable<INotificationData> = this.notificationSource.asObservable();

  retry = 0;
  retryLimit = 3;
  unauthorizeRetry = 3;

  token: string = '';
  constructor(
    private config: ConfigService,
    private auth: MsalService,
    private userFacade: UserFacade,
    private _snackBar: MatSnackBar,
    private notificationService: NotificationService,
    private loggerService: LoggerService
  ) {
    super();
    this.userFacade.auth$.pipe(filter(auth => !!auth.authenticated)).subscribe(data => {
      this.getTokenFromStore();
      this.connection = this.createConnection();
      this.connection.serverTimeoutInMilliseconds = 60000;
    });
    console.log(this.className)
  }

  /** New implementation */
  /**
     * Create a HubConnection object
     * @return HubConnection
     */
  private createConnection(): signalR.HubConnection {
    return new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(`${this.host}${this.subscriptionPath}`, {
        accessTokenFactory: () => this.auth.instance.getActiveAccount().idToken
      })
      .build();
  }

  /**
   * Try connect only if is not connected
   */
  connect() {
    this.loggerService.log('@microsoft/signalr connecting to ws');
    //this.loggerService.
    this.connected$.pipe(first(), filter(isConnected => {
      console.log('watching connecion status for reconnect');
      return !isConnected;
    }))
      .subscribe((data) => {
        this.connection.start()
          .then(() => {
            console.log('@microsoft/signalr ws connected');
            this.connectedSource.next(true);
            this.retry = this.unauthorizeRetry = 0;
            this.openSnackBar('En línea', 2000);
          })
          .catch((e: any) => {
            console.log(e.message, typeof e);
            console.log('ws no connected');
            this.connectedSource.next(false);
            if (typeof e === 'object' && e.message) {
              this.openSnackBar('Conectando...');
              if (e.message.includes(`Status code '401'`)) {
                this.retryConnection(true);
              } else {
                this.retryConnection();
              }
            } else {
              this.retryConnection();
            }
          });
      });
  }

  retryConnection(refresh: boolean = false) {
    if (refresh) {
      setTimeout(() => {
        this.retryAfterRefresh();
      }, 3000);
    } else {
      setTimeout(() => {
        if (this.retry <= this.retryLimit) {
          console.log('Retry to connect ws...');
          this.connect();
          this.retry++;
        } else {
          this.retryAfterRefresh();
        }
      }, 3000);
    }

  }

  retryAfterRefresh() {
    if (this.unauthorizeRetry <= this.retryLimit) {
      this.resfreshTokenAndRetry();
      this.unauthorizeRetry++;
    } else {
      this.openSnackBar('Fuera de línea, favor de actualizar la página');
    }
  }

  /**
   * Try close connection only if is connected
   */
  disconnect() {
    this.connected$.pipe(first(), filter(isConnected => isConnected))
      .subscribe(() => {
        this.connection.stop()
          .then(() => this.connectedSource.next(false))
          .catch(() => console.log('[New Notification Service] can not close connection.'));
      });
  }

  /**
   * Initialize notification service
   */
  start() {
    this.connect();
    this.connection.on('BroadcastMessage', data => this.notificationSource.next(data));
    this.connection.onclose((error) => {
      setTimeout(() => {
        this.connectedSource.next(false);
        this.retry = this.unauthorizeRetry = 0;
        this.connect();
      }, 5000);
    });
  }

  send(data) {
    return from(this.connection.invoke('send', data));
  }

  resfreshTokenAndRetry() {
    console.log('ws resfreshTokenAndRetry');
    let account: AccountInfo | null;
    if (!!this.auth.instance.getActiveAccount()) {
      this.auth
        .getLogger()
        .verbose("Interceptor - active account selected");
      account = this.auth.instance.getActiveAccount();
    } else {
      this.auth
        .getLogger()
        .verbose("Interceptor - no active account, fallback to first account");
      account = this.auth.instance.getAllAccounts()[0];
    }
    if (account) {
      var request = {
        scopes: this.config.get('msal.apiConfig.scopes'),
        account: account,
        forceRefresh: true,
        refreshTokenExpirationOffsetSeconds: 7200, // 2 hours * 60 minutes * 60 seconds = 7200 seconds,
        authority: this.config.get('msal.msalConfig.auth.authority')
      };

      this.auth.acquireTokenSilent(request).subscribe({
        next: (result: AuthenticationResult) => {
          if (result && result.accessToken) {
            this.token = result.accessToken;
            this.callEcho();
          }
        },
        error: (error: any) => {
          if (error instanceof InteractionRequiredAuthError) {
            this.auth.acquireTokenRedirect(request);
          }
        }
      });
    }
  }

  callEcho() {
    this.notificationService.echo().subscribe({
      next: () => {
        this.getTokenFromStore();
        this.connect();
      },
      error: () => {
        this.openSnackBar('Servicio no disponible, contacta a soporte técnico');
      }
    })
  }

  getTokenFromStore() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = `${localStorage.key(i)}`;
      if (key.includes('accesstoken') && key.includes('.default--')) {
        let accesstokenData: any = JSON.parse(`${localStorage.getItem(key)}`);
        this.token = `${accesstokenData['secret']}`;
        break;
      }
    }
  }

  openSnackBar(message: string, duration: number = 0) {
    this._snackBar.open(message, 'Ok', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      duration: duration
    });
  }

}
