// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-cover {
  background-size: cover;
  background-position: center;
  min-height: 200px;
}
.img-cover .welcome-img-title {
  color: white;
  font-weight: 500;
  margin-bottom: 10px;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8);
}

.welcome-title {
  font-size: 14px;
  font-weight: 700;
}

#welcome-message {
  max-height: 200px;
  overflow: auto;
}

@media only screen and (max-device-width: 370px) {
  #welcome-message {
    max-height: 130px;
    overflow: auto;
  }
  .img-cover {
    zoom: 0.7;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/modules/auth/components/welcome-dialog/welcome-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,2BAAA;EACA,iBAAA;AACF;AAAE;EACE,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,2CAAA;AAEJ;;AACA;EACE,eAAA;EACA,gBAAA;AAEF;;AACA;EACE,iBAAA;EACA,cAAA;AAEF;;AAAA;EAGE;IACE,iBAAA;IACA,cAAA;EACF;EACA;IACE,SAAA;EACF;AACF","sourcesContent":[".img-cover {\r\n  background-size: cover;\r\n  background-position: center;\r\n  min-height: 200px;\r\n  .welcome-img-title {\r\n    color: white;\r\n    font-weight: 500;\r\n    margin-bottom: 10px;\r\n    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8);\r\n  }\r\n}\r\n.welcome-title {\r\n  font-size: 14px;\r\n  font-weight: 700;\r\n}\r\n\r\n#welcome-message{\r\n  max-height: 200px;\r\n  overflow: auto;\r\n}\r\n@media\r\n    only screen  and (max-device-width: 370px)\r\n{\r\n  #welcome-message{\r\n    max-height: 130px;\r\n    overflow: auto;\r\n  }\r\n  .img-cover {\r\n    zoom: .7;\r\n\r\n  }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
