import { IPaymentServices, IPaymentRoutes, IPaymentRequests, IPaymentOrigin, IPaymentMessage } from "../interfaces/ipayment.services";
import { SupportingDocumentService } from "../../../core/api/supporting-documents/supporting-document.service";
import { GenericDocumentService } from "src/app/core/api/generic-document/generic-document.service";
import { DebtsService } from 'src/app/core/api/debts/debts.service';
import { EarlyEnrollmentService } from "src/app/core/api/early-enrollment/early-enrollment.service";

export const MODULES = {
    CONSTANCIAS: 'CONSTANCIAS',
    DOCUMENTS_ACADEMIC_HISTORIAL: 'DOCUMENTS_ACADEMIC_HISTORIAL',
    CAROUSEL: 'CAROUSEL',
    EARLY_ENROLLMENT: 'EARLY_ENROLLMENT'
}

export const Origins: IPaymentOrigin[] = [
    { id: 'SupportingDocumentService', module: MODULES.CONSTANCIAS },
    { id: 'DocumentService', module: MODULES.DOCUMENTS_ACADEMIC_HISTORIAL },
    { id: 'DebtsService', module: MODULES.CAROUSEL },
    { id: 'EarlyEnrollmentService', module: MODULES.EARLY_ENROLLMENT }
]

export const servicesOfPayment: IPaymentServices[] = [
    { id: MODULES.CONSTANCIAS, service: SupportingDocumentService },
    { id: MODULES.DOCUMENTS_ACADEMIC_HISTORIAL, service: GenericDocumentService },
    { id: MODULES.CAROUSEL, service: DebtsService },
    { id: MODULES.EARLY_ENROLLMENT, service: EarlyEnrollmentService },
];

export const requestsOfPayment: IPaymentRequests[] = [
    { id: MODULES.CONSTANCIAS, request: 'getPayRequest' },
    { id: MODULES.DOCUMENTS_ACADEMIC_HISTORIAL, request: 'getPaymentRequest' },
    { id: MODULES.CAROUSEL, request: 'getCarouselPayment' },
    { id: MODULES.EARLY_ENROLLMENT, request: 'getPaymentRequest' }
];

export const mapRoutes: IPaymentRoutes[] = [
    { id: MODULES.CONSTANCIAS, route: '/service-window/supporting-document' },
    { id: MODULES.DOCUMENTS_ACADEMIC_HISTORIAL, route: '/service-window/document/academic-historial' },
    { id: MODULES.CAROUSEL, route: '/financial-info/debts' },
    { id: MODULES.EARLY_ENROLLMENT, route: '/financial-info/early-enrollment' }
];

export const postRoutes: IPaymentRoutes[] = [
    { id: MODULES.CONSTANCIAS, route: '/service-window/supporting-document/finishrequest' },
    { id: MODULES.DOCUMENTS_ACADEMIC_HISTORIAL, route: '/service-window/document/finish-request' },
    { id: MODULES.CAROUSEL, route: '/financial-info/debts' },
    { id: MODULES.EARLY_ENROLLMENT, route: '/financial-info/early-enrollment' }
];

export const cancelMessages: IPaymentMessage[] = [
    { id: MODULES.CONSTANCIAS, message:  'Al cancelar el proceso se borrará la información que solicitaste previamente para la elaboración de la constancia y tendrás que realizar una nueva solicitud.' },
    { id: MODULES.DOCUMENTS_ACADEMIC_HISTORIAL, message:  'Al cancelar el proceso se borrará la información que solicitaste previamente para la elaboración del Historial Acádemico y tendrás que realizar una nueva solicitud.' },
    { id: MODULES.CAROUSEL, message:  'Al cancelar el proceso se borrará la información que solicitaste previamente.' },
    { id: MODULES.EARLY_ENROLLMENT, message:  'Al cancelar el proceso se borrará la información que solicitaste previamente.' }
];

export function generatePaymentStorage(): void {

}
