import { SocialServiceModule } from './../school-services/pages/social-service/social-service.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared/shared.module';
import { ConstanceOrderDetailRoutingModule } from './public-pages-routing.module';

import { ConstanceOrderDetailComponent } from '../public/pages/constance-order-detail/constance-order-detail.component';
import { SafeHtmlPipe } from '../../core/utilities/pipes/safe-html.pipe';
import { DocumentOrderDetailComponent } from './pages/document-order-detail/document-order-detail.component'

@NgModule({
  imports: [
    CommonModule,
    ConstanceOrderDetailRoutingModule,
    SharedModule,
    SocialServiceModule
  ],
  declarations: [
    ConstanceOrderDetailComponent,
    DocumentOrderDetailComponent
  ],
  providers: [],
})
export class PublicPagesModule { }
