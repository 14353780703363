import { createAction, props } from '@ngrx/store';
import { IApplicationIp } from '../interfaces/application.state';

const INIT = createAction('[APP] init load data');

const SET_TITLE = createAction('[APP] set section title', props<{ title: any }>());

const SET_IP = createAction('[APP] set ip', props<{ ipInfo: IApplicationIp }>());


export { INIT, SET_TITLE, SET_IP };