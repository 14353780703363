import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, EMPTY, exhaustMap, map, of } from "rxjs";
import { CampusService } from "../../catalogs/services/campus/campus.service";
import { MenuAdminFacade } from "../facades/menu-admin.facade";
import * as MENU_ADMIN_ACTIONS from "./menu-admin.actions";
import { ICampusData } from "../../catalogs/interfaces/icampus-data";
import { CampusData } from "../interfaces/imenu-api-model";
import { CareerService } from "../../catalogs/services/career/career.service";
import { LevelService } from "../../catalogs/services/level/level.service";
import { StatusService } from "../../catalogs/services/status/status.service";
import { ICareerData, ILevel } from "../../catalogs/interfaces/icareer-data";
import { CareerData, LevelData } from "../../catalogs/interfaces/ilevel-data";
import { MenuAdminService } from "../services/MenuAdminService";

@Injectable()
export class MenuAdminEffects {

	loadConfig$ = createEffect(() => this.actions$.pipe(
		ofType(MENU_ADMIN_ACTIONS.GET_CONFIG),
		exhaustMap((payload) => this.menuAdminService.getMenuConfiguration(payload.payload)
			.pipe(
				map((response) => MENU_ADMIN_ACTIONS.SET_CONFIG_DATA({ payload: response })),
				catchError(() => EMPTY)
			))
	)
	);

	setConfig$ = createEffect(() => this.actions$.pipe(
		ofType(MENU_ADMIN_ACTIONS.SET_CONFIG_DATA),
		exhaustMap((payload) => of(MENU_ADMIN_ACTIONS.SET_CONFIG({ payload: payload.payload.data })))
	)
	);

	setRoles$ = createEffect(() => this.actions$.pipe(
		ofType(MENU_ADMIN_ACTIONS.SET_CONFIG_DATA),
		exhaustMap((payload) => of(MENU_ADMIN_ACTIONS.SET_ROLES({ payload: payload.payload.additionalData })))
	)
	);

	loadCampus$ = createEffect(() => this.actions$.pipe(
		ofType(MENU_ADMIN_ACTIONS.GET_CATALOGS),
		exhaustMap((payload) => this.campusService.getCampusList(true)
			.pipe(
				map((response: ICampusData[]) => response.map((s) => ({ campusName: s.campusName, campusId: s.campusId }))),
				map((campus: CampusData[]) => MENU_ADMIN_ACTIONS.SET_CAMPUS({ campus })),
				catchError(() => EMPTY)
			))
	)
	);

	loadCareers$ = createEffect(() => this.actions$.pipe(
		ofType(MENU_ADMIN_ACTIONS.GET_CATALOGS),
		exhaustMap((payload) => this.careerService.getCareerList("TODOS")
			.pipe(
				map((response: ICareerData[]) => response.map((s) => ({ careerName: s.careerName, careerId: s.careerId }))),
				map((response: CareerData[]) => MENU_ADMIN_ACTIONS.SET_CAREERS({ careers: response })),
				catchError(() => EMPTY)
			))
	)
	);

	loadLevels$ = createEffect(() => this.actions$.pipe(
		ofType(MENU_ADMIN_ACTIONS.GET_CATALOGS),
		exhaustMap((payload) => this.levelService.getLevelList("TODOS")
			.pipe(
				map((response: ILevel[]) => response.map((s) => ({
					levelUId: s.levelUId,
					levelUIdName: s.levelUIdName,
					levelCrm: s.levelCrm,
					levelCrmName: s.levelCrmName,
				}))),
				map((response: LevelData[]) => MENU_ADMIN_ACTIONS.SET_LEVELS({ levels: response })),
				catchError(() => EMPTY)
			))
	)
	);

	loadDownTypes$ = createEffect(() => this.actions$.pipe(
		ofType(MENU_ADMIN_ACTIONS.GET_CATALOGS),
		exhaustMap((payload) => this.statusService.getDownTypes()
			.pipe(
				map((response) => MENU_ADMIN_ACTIONS.SET_DOWN_TYPES({ downTypes: response })),
				catchError(() => EMPTY)
			))
	)
	);

	loadStudentStatus$ = createEffect(() => this.actions$.pipe(
		ofType(MENU_ADMIN_ACTIONS.GET_CATALOGS),
		exhaustMap((payload) => this.statusService.getStudentStatus()
			.pipe(
				map((response) => MENU_ADMIN_ACTIONS.SET_STUDENT_STATUS({ studentStatus: response })),
				catchError(() => EMPTY)
			))
	)
	);

	constructor(
		private actions$: Actions,
		private menuAdminFacade: MenuAdminFacade,
		private campusService: CampusService,
		private careerService: CareerService,
		private levelService: LevelService,
		private statusService: StatusService,
		private menuAdminService: MenuAdminService
	) { }
}
