import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationClient } from '../../bootstrap/notification-client/notification-client.service';
import { NotificationFacade } from './facades/notification-facade';
import { StoreModule } from '@ngrx/store';
import { NotificationReducer } from './store/notification.reducer';
//import { PushNotificationService } from 'ngx-push-notifications';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('notification', NotificationReducer),
  ],
  providers: [
    NotificationClient,
    NotificationFacade,
  ]
})
export class NotificationStoreModule {
  static forRoot(): ModuleWithProviders<NotificationStoreModule> {
    return {
      ngModule: NotificationStoreModule,
      providers: [
        NotificationClient
      ],
    };
  }
}
