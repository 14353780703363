import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { INavigationNode } from 'src/app/core/redux/navigation/interfaces/inavigation-node';
import { MessageService } from 'src/app/core/services/message/message.service';
import { NavigationFacade } from 'src/app/core/redux/navigation/facades/navigation-facade';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-menu-nested',
  templateUrl: './menu-nested.component.html',
  styleUrls: ['./menu-nested.component.scss']
})
export class MenuNestedComponent implements OnInit {
  @Output() mouseenter2 = new EventEmitter();
  @Output() mouseleave2 = new EventEmitter();

  @Output() submouseenter2 = new EventEmitter<any>();
  @Output() submouseleave2 = new EventEmitter<any>();

  @Input('item') item: INavigationNode;
  @Input('title') title: string = '';


  @ViewChild('childMenu', { static: true }) public childMenu;

  items: INavigationNode[];

  visible = false;

  constructor(
    private messageService: MessageService,
    private navigationFacade: NavigationFacade,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.items = this.item.children;
  }

  subButtonEnter(trigger) {
    this.submouseenter2.emit(trigger);
  }

  subButtonLeave(trigger, element = null) {
    this.submouseleave2.emit(trigger);
  }

  handleClick(node: INavigationNode) {
    if (node.isDisabled() || node.hasChildren())
      return;

    if (node.isMessage()) {
      return this.message(node);
    }

    return this.navigate(node);
  }

  navigate(node: INavigationNode) {
    if (node.hasExternalUrl()) {
      return window.open(node.getUrl(), node.getTarget());
    }
    this.navigationFacade.setCurrent(node.config);
    return this.router.navigate([node.getUrl()]);
  }

  message(node: INavigationNode) {
    if (node.hasRuleMessage()) {
      this.messageService.info(node.getRuleMessage());
    }
  }

}

