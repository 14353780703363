import { createAction, props } from '@ngrx/store';
import { ITransactionData } from '../interfaces/itransactions-filter';

const SET_TRANSACTIONS = createAction('[TRANSACTIONS] set transactions data', props<{ payload: ITransactionData[], payload2: number }>());

const ADD_TRANSACTIONS = createAction('[TRANSACTIONS] add transactions data', props<{ payload: ITransactionData[] }>());

const ADD_TRANSACTION = createAction('[TRANSACTIONS] add transaction data', props<{ payload: ITransactionData }>());

const REMOVE_TRANSACTION = createAction('[TRANSACTIONS] remove transaction data', props<{ payload: any }>());

export { SET_TRANSACTIONS, ADD_TRANSACTION, ADD_TRANSACTIONS, REMOVE_TRANSACTION }
