import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

export const BROWSERS = {
  iExplorer: 'ie',
};
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-browser-unsupported',
  templateUrl: './browser-unsupported.component.html',
  styleUrls: ['./browser-unsupported.component.scss']
})
export class BrowserUnsupportedComponent implements OnInit {

  public display: boolean = true;

  constructor(private deviceService: DeviceDetectorService) { }

  ngOnInit() {
    const deviceInfo = this.deviceService.getDeviceInfo();
    this.display = this.isSupported(deviceInfo.browser);
  }

  isSupported(agent: string, version?: string): boolean {
    return agent.indexOf(BROWSERS.iExplorer) !== -1;
  }
}
