import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ConfigService } from 'src/app/core/config/services/config.service';

@UntilDestroy()
@Component({
  selector: 'app-unitec-footer',
  templateUrl: './unitec-footer.component.html',
  styleUrls: ['./unitec-footer.component.scss']
})
export class UnitecFooterComponent implements OnInit {

  version: string = this.configService.get('version');
  
  constructor(
    private configService: ConfigService
  ) { }

  ngOnInit() {

  }
}
