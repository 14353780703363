import { createReducer, on } from '@ngrx/store';
import { ITitleRequestState } from '../interfaces/ititle-request-status';
import * as TITLE_REQUEST_ACTIONS from './title-request.actions';
import { TITLE_REQUEST_STATE } from './../interfaces/title-request.state';

const _titleRequestReducer = createReducer(
  TITLE_REQUEST_STATE,
  on(TITLE_REQUEST_ACTIONS.SET_TOKEN_DATA, (state: ITitleRequestState, { payload }) => ({
    ...state,
    token: payload,
  })),
  on(TITLE_REQUEST_ACTIONS.SET_KEY_TIMBRADOS_DATA, (state: ITitleRequestState, { payload }) => ({
    ...state,
    apiKeyTimbrados: payload,
  })),
  on(TITLE_REQUEST_ACTIONS.SET_TIMBRADOS_DATA, (state: ITitleRequestState, { payload }) => ({
    ...state,
    active: true,
    createdAt:  new Date().getTime(),
    data: payload,
  })),
  on(TITLE_REQUEST_ACTIONS.REMOVE_DATA, (state: ITitleRequestState, { payload }) => ({
    ...TITLE_REQUEST_STATE,
    active: false,
  }))
);

export function TitleRequestReducer(state, action) {
  return _titleRequestReducer(state, action);
}
