import { Injectable } from "@angular/core";
import { NotificationClient } from "../notification-client/notification-client.service";
import { NotificationFacade } from "../../redux/notification/facades/notification-facade";
import { filter } from "rxjs/operators";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { INotificationData } from "../../redux/notification/interfaces/inotification-data";

@UntilDestroy()
@Injectable()
export class NotificationInitializer {
  constructor(
    private client: NotificationClient,
    private facade: NotificationFacade
  ) {}

  init() {
    this.client.start();
    this.client.notification$
      .pipe(untilDestroyed(this))
      .pipe(filter((notification) => !!notification))
      .pipe(untilDestroyed(this))
      .subscribe((notification) =>
        this.facade.add({
          ...notification,
          data: JSON.parse(notification.data),
        } as INotificationData)
      );
  }
}
