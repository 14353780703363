import { Injectable } from '@angular/core';
import { Endpoint } from '../../http/models/endpoint/endpoint';
import { AppHttp } from '../../http/services/app-http/app-http.service';
import { EndpointsService } from '../../http/services/endpoints/endpoints.service';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ICareersDocuments } from '../../data/supporting-documents/interfaces/isupporting-document';
import { ISupportingDocumentMetadata } from '../../data/supporting-documents/interfaces/isupporting-document-metadata';
import { IResponse } from 'src/app/core/http/interfaces/iresponse';
import { ITransactionData } from '../../redux/transactions-engine/interfaces/itransactions-filter';
import { IProcedureData } from '../../redux/transactions-engine/interfaces/IProcedureData';
import { IPrice } from '../../redux/payment/interfaces/iprice';


@Injectable({
  providedIn: 'root'
})
export class SupportingDocumentService {

  supportingDocuments: {
    main: Endpoint,
    metadata: Endpoint,
    requestPreview: Endpoint,
    preview: Endpoint,
    changeStatus: Endpoint,
    downloadPDF: Endpoint,
    savePreview: Endpoint,
    finishOrderRequest: Endpoint,
    sendDigitalDocument: Endpoint,
    publicOrderDetail: Endpoint,
    procedureList: Endpoint,
    requestPrices: Endpoint,
    validateTransaction: Endpoint,
    getPrices: Endpoint,
    savePaymentRequest: Endpoint,
    getPayRequest: Endpoint
  };

  constructor(
    private http: AppHttp,
    private endpoints: EndpointsService,
  ) {
    this.supportingDocuments = this.endpoints.supportingDocuments;
  }

  getList(profileUserId): Observable<ICareersDocuments[]> {
    this.supportingDocuments.main.useWildcardParams({profileUserId});
    return this.http.get(this.supportingDocuments.main)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo cargar los tipos de constancia')));
  }

  getMetadata(documentId: string, userId: string): Observable<ISupportingDocumentMetadata> {
    this.supportingDocuments.metadata.useWildcardParams({documentId, userId});
    return this.http.get(this.supportingDocuments.metadata)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo obtener la metadata')));
  }

  requestPreview(userId, body) {
    this.supportingDocuments.requestPreview.useWildcardParams({userId});
    return this.http.post(this.supportingDocuments.requestPreview, body)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'Ocurrió un error al solicitar la vista previa')));
  }

  getSupportingDocumentPreview(orderId, userId) {
    this.supportingDocuments.preview.useWildcardParams({orderId, userId});
    return this.http.get(this.supportingDocuments.preview)
      .pipe(map(response => response));
  }

  nextStatus(trasactionId: number, idStatus: number) {
    const body = { TransactionId: trasactionId, NewStatusId: idStatus };
    return this.http.post<IResponse<ITransactionData>>(this.supportingDocuments.changeStatus, body)
    .pipe(map(response => response.data))
    .pipe(catchError(response => this.http.handleError(response, 'No se pudo cambiar el status')));
  }

  getDocument(transactionId: number, format: string) {
    this.supportingDocuments.downloadPDF.useWildcardParams({transactionId, format});

    return this.http.get(this.supportingDocuments.downloadPDF)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'Ocurrió un error al solicitar la constancia')));
  }

  saveSupportingDocumentRequest(orderId, userId, lpidsis, sisRequest) {
    const query = { lpidsis: lpidsis,  sisRequest: sisRequest };
    const params = this.http.buildQueryParams(query);
    this.supportingDocuments.savePreview.useWildcardParams({orderId, userId});
    return this.http.get(this.supportingDocuments.savePreview, { params })
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo guardar la solicitud')));
  }

  getPublicOrderDetail(TransactionId, captchaReq) {
    this.supportingDocuments.publicOrderDetail.useWildcardParams({TransactionId});
    const params = this.http.buildQueryParams({captchaReq});
    return this.http.get(this.supportingDocuments.publicOrderDetail, {params})
        .pipe(map(response => response))
        .pipe(catchError(response => this.http.handleError(response, 'Ocurrió un error al solicitar el detalle de la constancia')));
  }

  finishOrderRequest(TransactionId, profileId) {
    this.supportingDocuments.finishOrderRequest.useWildcardParams({profileId, TransactionId});
    return this.http.get(this.supportingDocuments.finishOrderRequest)
        .pipe(map(response => response))
        .pipe(catchError(response => this.http.handleError(response, 'Ocurrió un error al finalizar la solicitud, intentalo de nuevo')));
  }

  sendDigitalDocument(profileId, TransactionId, OrderId) {
    this.supportingDocuments.sendDigitalDocument.useWildcardParams({profileId, TransactionId, OrderId});
    return this.http.get(this.supportingDocuments.sendDigitalDocument)
        .pipe(map(response => response))
        .pipe(catchError(response => this.http.handleError(response, 'Ocurrió un error al enviar la constancia digitalizada')));
  }

  ////////////*////////////
  getProcedureList(profileId) {
    this.supportingDocuments.procedureList.useWildcardParams({ profileId });
    this.supportingDocuments.procedureList.setCache(0);
    return this.http.get<IResponse<IProcedureData[]>>(this.supportingDocuments.procedureList)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo obtener la solicitud de fromas de entrega.')));
  }

 requestPrices(userId, profileUserId): Observable<any> {
    this.supportingDocuments.requestPrices.useWildcardParams({userId, profileUserId});
    this.supportingDocuments.requestPrices.setCache(0);
    return this.http.post(this.supportingDocuments.requestPrices, {})
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'Ocurrió un error al solicitar la lista de precios')));
  }

  validateTransaction(queueId) {
    this.supportingDocuments.validateTransaction.useWildcardParams({queueId});
    this.supportingDocuments.validateTransaction.setCache(0);
    return this.http.get<IResponse<any>>(this.supportingDocuments.validateTransaction)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo recuperar la consulta')));
  }

  getPrices(requestId, userId, profileUserId): Observable<IPrice[]> {
    this.supportingDocuments.getPrices.useWildcardParams({requestId, userId, profileUserId});
    this.supportingDocuments.getPrices.setCache(0);
    return this.http.get<IResponse<IPrice[]>>(this.supportingDocuments.getPrices)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo recuperar la lista de precios')));
  }

  getPayRequest(userId, profileUserId, paymentId): Observable<any> {
    this.supportingDocuments.getPayRequest.useWildcardParams({ userId, profileUserId, paymentId });
    this.supportingDocuments.getPayRequest.setCache(0);
    return this.http.post(this.supportingDocuments.getPayRequest, {})
      .pipe(map(response =>  { return { data: response.data, additionalData: response.additionalData } }))
      .pipe(catchError(response => this.http.handleError(response, 'Ocurrió un error al solicitar la lista de precios')));
  }

}
