import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ICatData, IMenuAdminState } from "../interfaces/imenu-admin.state";
import { getState } from "./../store/menu-admin.selectors";
import { select, Store } from "@ngrx/store";
import { IDictionary } from "src/app/core/interfaces/idictionary";
import * as MENU_ADMIN_ACTIONS from '../store/menu-admin.actions';
import { IDownTypeModelView, IStudentStatusModelView } from "src/app/core/interfaces/catalogues/istatus";

@Injectable()
export class MenuAdminFacade {

    private state$: Observable<IMenuAdminState> = this.store.select(getState);

    sessionActive$: Observable<boolean> = this.state$.pipe(select(state => state.sessionActive));
    isFullControl$: Observable<boolean> = this.state$.pipe(select(state => state.isFullControl));
    campuses$: Observable<IDictionary<string>[]> = this.state$.pipe(select(state => state.campuses));
    levels$: Observable<IDictionary<string>[]> = this.state$.pipe(select(state => state.levels));
    careers$: Observable<IDictionary<string>[]> = this.state$.pipe(select(state => state.careers));
    downTypes$: Observable<IDownTypeModelView[]> = this.state$.pipe(select(state => state.downTypes));
    studentStatus$: Observable<IStudentStatusModelView[]> = this.state$.pipe(select(state => state.studentStatus));


    constructor(
        private store: Store<IMenuAdminState>
    ) { }

    setSessionActive(payload: boolean) {
        this.store.dispatch(MENU_ADMIN_ACTIONS.SET_ACTIVE({ payload }));
    }

    setFullControl(payload: boolean) {
        this.store.dispatch(MENU_ADMIN_ACTIONS.SET_FULL_CONTROL({ payload }));
    }

    setCatData(payload: ICatData) {
        this.store.dispatch(MENU_ADMIN_ACTIONS.SET_CAT_DATA({ payload }));
    }

    setDownTypes(payload: IDownTypeModelView[]) {
        this.store.dispatch(MENU_ADMIN_ACTIONS.SET_DOWN_TYPES({ payload }));
    }

    setStudentStatus(payload: IStudentStatusModelView[]) {
        this.store.dispatch(MENU_ADMIN_ACTIONS.SET_STUDENT_STATUS({ payload }));
    }

}