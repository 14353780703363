import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { IConfirmationState, IToken, IErrorInfo } from '../interfaces/iconfirmation-status';
import { getState } from '../store/confirmation.selectors';
import * as CONFIRMATION_ACTIONS from '../store/confirmation.actions';

@Injectable()
export class ConfirmationFacade {

  private state$: Observable<IConfirmationState> = this.store.select(getState);
  _lastConfirmationSource: BehaviorSubject<IConfirmationState> = new BehaviorSubject(null);
  last$: Observable<IConfirmationState> = this._lastConfirmationSource.asObservable();

  active$: Observable<boolean> = this.state$.pipe(
    select(state => state.active),
    filter(active => !!active)
  );

  finished$: Observable<boolean> = this.state$.pipe(
    select(state => state.finished),
    filter(finished => !!finished)
  );

  idToken$: Observable<IToken> = this.state$.pipe(
    select(state => state.idToken),
    filter(idToken => !!idToken)
  );

  accessToken$: Observable<IToken> = this.state$.pipe(
    select(state => state.accessToken),
    filter(accessToken => !!accessToken)
  );

  error$: Observable<IErrorInfo> = this.state$.pipe(
    select(state => state.error),
    filter(error => !!error)
  );

  constructor(private store: Store<IToken>) { }

  setInitToken(active: boolean) {
    this.store.dispatch(CONFIRMATION_ACTIONS.SET_CONFIRMATION({ payload: active }));
  }

  setIdToken(idToken: IToken, active: boolean, user: string, finished: boolean) {
    this.store.dispatch(CONFIRMATION_ACTIONS.SET_CONFIRMATION_ID({ payload: idToken, active: active, user: user, finished: finished }));
    this._lastConfirmationSource.next(<IConfirmationState>{
      active: active,
      idToken: idToken,
      user: user,
      finished: finished
    });
  }

  setAccessToken(accessToken: IToken, active: boolean, user: string, finished: boolean) {
    this.store.dispatch(CONFIRMATION_ACTIONS.SET_CONFIRMATION_ACCESS({ payload: accessToken, active: active, user: user, finished: finished }));
    this._lastConfirmationSource.next(<IConfirmationState>{
      active: active,
      accessToken: accessToken,
      user: user,
      finished: finished
    });
  }

  setError(error: IErrorInfo, finished: boolean) {
    this.store.dispatch(CONFIRMATION_ACTIONS.SET_CONFIRMATION_ERROR({ payload: error, finished: finished }));
  }

  addconfirmation(token: IToken, typeToken: number) {
    this.store.dispatch(CONFIRMATION_ACTIONS.ADD_CONFIRMATION({ payload: token, typeToken: typeToken }));
  }

  removeconfirmation(token: IToken) {
    this.store.dispatch(CONFIRMATION_ACTIONS.REMOVE_CONFIRMATION({ payload: token }));
  }
}
