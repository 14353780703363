import { Component, OnInit } from '@angular/core';
import { AssetsService } from '../../../../core/api/assets/assets.service';
import { Endpoint } from 'src/app/core/http/models/endpoint/endpoint';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs';
import { ICarouselModel } from 'src/app/core/interfaces/catalogues/icarousel';
import * as _ from 'lodash';
import { ConfigService } from 'src/app/core/config/services/config.service';

export const default_responsiveOptions: any[] = [
  {
    breakpoint: '1024px',
    numVisible: 5
  },
  {
    breakpoint: '768px',
    numVisible: 3
  },
  {
    breakpoint: '560px',
    numVisible: 1
  }
];

@UntilDestroy()
@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {
  availableImages: ICarouselModel[] = [];
  slides = [];
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    infinite: true,
    adaptiveHeight: false,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnFocus: true,
    pauseOnHover: true,
  };

  responsiveOptions = default_responsiveOptions;

  signature = this.configService.get(`storage.blob.signature`, '');

  constructor(
    private assetsService: AssetsService,
    private breakpointObserver: BreakpointObserver,
    private configService: ConfigService
  ) { }

  ngOnInit() {
    this.signature = this.signature == '' ? '' : `?${this.signature}`
    this.assetsService.getCarousel().pipe(untilDestroyed(this)).subscribe((response: ICarouselModel[]) => {
      console.log('getCarousel: ', response);
      this.availableImages = this.getAvailablesImages(response);
      this.slides = this.getVisibleSlides();
    });
    this.breakpointObserver.observe(
      [Breakpoints.XSmall,
      Breakpoints.Handset])
      .pipe(map(result => result.matches)).subscribe(mobile => {
        this.slides = this.getVisibleSlides(mobile);
      });
  }

  getAvailablesImages(images: ICarouselModel[]) {
    let current = images.filter(image =>
      image['InitialDate'] == null ? (image['EndDate'] == null ? true : (new Date(image['EndDate'])).getTime() > (new Date()).getTime()) :
        (((new Date(image['InitialDate'])).getTime() < (new Date()).getTime()) &&
          (image['EndDate'] == null ? true : (new Date(image['EndDate'])).getTime() > (new Date()).getTime()))
    )
    console.log('getAvailablesImages', current);
    return _.orderBy(current, 'order', 'asc');
  }

  getVisibleSlides(mobile: boolean = false) {
    return mobile
      ? this.availableImages.map(blob => this.getImageUrl(blob['MobileFileName']))
      : this.availableImages.map(blob => this.getImageUrl(blob['DeskFileName']));
  }

  getImageUrl(image: string) {
    return (<Endpoint>this.assetsService.getCarouselEndpoint()).useWildcardParams({ file: `${image}` }).url + this.signature;
  }

  isForMobile(name: string) {
    const image = (<Endpoint>this.assetsService.getCarouselEndpoint()).useWildcardParams({ file: `sm/${name}` }).url;
    return image;
  }

  isForWeb(name: string) {
    const image = (<Endpoint>this.assetsService.getCarouselEndpoint()).useWildcardParams({ file: `lg/${name}` }).url;
    return image;
  }

  slidesRange() {
    return [...Object.keys(this.slides)];
  }
}
