import { createAction, props } from '@ngrx/store';
import { IToken, IErrorInfo } from '../interfaces/iconfirmation-status';

const SET_CONFIRMATION = createAction('[CONFIRMATION] set init Confirmation data', props<{ payload: boolean }>());

const SET_CONFIRMATION_ID = createAction('[CONFIRMATION] set Id Confirmation data', props<{ payload: IToken, active: boolean, user: string, finished: boolean }>());

const SET_CONFIRMATION_ACCESS = createAction('[CONFIRMATION] set Access Confirmation data', props<{ payload: IToken, active: boolean, user: string, finished: boolean }>());

const ADD_CONFIRMATION = createAction('[CONFIRMATION] add Confirmation data', props<{ payload: IToken, typeToken: number }>());

const REMOVE_CONFIRMATION = createAction('[CONFIRMATION] remove Confirmation data', props<{ payload: IToken }>());

const SET_CONFIRMATION_ERROR = createAction('[CONFIRMATION] error Confirmation data', props<{ payload: IErrorInfo, finished: boolean }>());

export { SET_CONFIRMATION, SET_CONFIRMATION_ID, SET_CONFIRMATION_ACCESS, ADD_CONFIRMATION, REMOVE_CONFIRMATION, SET_CONFIRMATION_ERROR }