// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.box-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  visibility: hidden;
  text-align: justify;
}

.clamp-container {
  border-radius: 1rem;
  border: solid 1px #efefef;
  padding: 1rem;
}

.clamp-expander {
  color: #006fba;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/layouts/components/line-clamp/line-clamp.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAEA;EACI,gBAAA;EACA,oBAAA;EACA,4BAAA;EACA,kBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,yBAAA;EACA,aAAA;AACJ;;AAEA;EACI,cAAA;EACA,eAAA;EACA,0BAAA;EACA,eAAA;EACA,iBAAA;AACJ","sourcesContent":[":host {\r\n    display: block;\r\n}\r\n\r\n.box-clamp {\r\n    overflow: hidden;\r\n    display: -webkit-box;\r\n    -webkit-box-orient: vertical;\r\n    visibility: hidden;\r\n    text-align: justify;\r\n}\r\n\r\n.clamp-container {\r\n    border-radius: 1rem;\r\n    border: solid 1px #efefef;\r\n    padding: 1rem;\r\n}\r\n\r\n.clamp-expander {\r\n    color: #006fba;\r\n    font-size: 12px;\r\n    text-decoration: underline;\r\n    cursor: pointer;\r\n    font-weight: bold;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
