import { createAction, props } from '@ngrx/store';

const SET_PROFILE_BACKUP = createAction('[IMPERSONATE] set profile backup', props<{ payload: any }>());

const SET_PICTURE_BACKUP = createAction('[IMPERSONATE] set picture backup', props<{ payload: any }>());

const SET_ACTIVE = createAction('[IMPERSONATE] set active');

const SET_INACTIVE = createAction('[IMPERSONATE] set inactive');

export { SET_PROFILE_BACKUP, SET_PICTURE_BACKUP, SET_ACTIVE, SET_INACTIVE }
