import { Component, OnInit, Inject } from '@angular/core';
import { AssetsService } from 'src/app/core/api/assets/assets.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { of, Observable } from 'rxjs';
import { DialogData } from 'src/app/modules/admin/student-requests/components/confirm/confirm.component';
import { AuthService } from 'src/app/core/session/services/auth/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';

@UntilDestroy()
@Component({
  selector: 'app-mfa-dialog',
  templateUrl: './mfa-dialog.component.html',
  styleUrls: ['./mfa-dialog.component.scss']
})
export class MfaDialogComponent implements OnInit {

  styles: any;
  welcomeMessage$: Observable<any>;
  stay: boolean = false;
  mfaurl;

  constructor(
    private dialogRef: MatDialogRef<MfaDialogComponent>,
    private assets: AssetsService,
    @Inject(MAT_DIALOG_DATA) public data: { msg: any },
    //private authService: AuthService,
    private sanitizer: DomSanitizer,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
  ) {
    this.welcomeMessage$ = of(data.msg);
  }

  ngOnInit() {
    this.assets.getMfaImageBlob().subscribe(data => {
      let reader = new FileReader();
      reader.onload = () => {
        let dataUrl: any = reader.result;
        var base64 = dataUrl.split(',')[1];
        this.setBackgroud(base64);
      };
      reader.readAsDataURL(data);
    });

  }

  setBackgroud(image) {
    this.mfaurl = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64, ' + image);
  }

  close() {
    this.dialogRef.close();
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
        prompt: 'select_account'
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
    this.close();
  }

}
