// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-info span {
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/auth/pages/password-recovery/password-recovery.component.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;AAAJ","sourcesContent":[".account-info{\r\n  span {\r\n    margin-left: 10px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
