import { createAction, props } from '@ngrx/store';
import { IPaymentData, IProcessInfo } from '../interfaces/ipayment-status';

const SET_PAYMENT = createAction('[PAYMENT] set payment data', props<{ payload: IPaymentData, active: boolean, paymentOrderId: string, process: IProcessInfo }>());

const ADD_PAYMENT = createAction('[PAYMENT] add payment data', props<{ payload: IPaymentData }>());

const REMOVE_PAYMENT = createAction('[PAYMENT] remove payment data', props<{ payload: IPaymentData }>());

const ADD_PROCESS = createAction('[PAYMENT] add process data', props<{ payload: IProcessInfo }>());

const REMOVE_PROCESS = createAction('[PAYMENT] remove process data', props<{ payload: IProcessInfo }>());

export { SET_PAYMENT, ADD_PAYMENT, REMOVE_PAYMENT, ADD_PROCESS, REMOVE_PROCESS }