import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatbotFacade } from './facades/chatbot-facade';
import { StoreModule } from '@ngrx/store';
import { ChatbotReducer } from './store/chatbot.reducer';


@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('chatbot', ChatbotReducer),
  ],
  declarations: [],
  providers: [
    ChatbotFacade
  ]
})
export class ChatbotStoreModule { }
