// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-tab .mat-tab-header-pagination {
  display: none;
}
.notification-tab .mat-tab-label {
  padding: 0 15px;
  min-width: 33%;
  font-size: 12px;
}
.notification-tab .mat-tab-body-wrapper {
  flex: auto;
  align-self: stretch;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/layouts/components/notification-center/notification-center.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ;AAEE;EACE,eAAA;EACA,cAAA;EACA,eAAA;AAAJ;AAGE;EACE,UAAA;EACA,mBAAA;AADJ","sourcesContent":[".notification-tab {\r\n  .mat-tab-header-pagination {\r\n    display: none;\r\n  }\r\n  .mat-tab-label {\r\n    padding: 0 15px;\r\n    min-width: 33%;\r\n    font-size: 12px;\r\n  }\r\n\r\n  .mat-tab-body-wrapper {\r\n    flex: auto;\r\n    align-self: stretch;\r\n  }\r\n\r\n  .mat-tab-body-content {\r\n  }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
