import { createReducer, on } from '@ngrx/store';
import { IBillBoxData, IBillingData, IBillingMessages, IBillingState, IRebillingData } from '../interfaces/ibilling-status';
import * as BILLING_ACTIONS from './billing.actions';
import { BILLING_STATE } from '../interfaces/billing.state';


const _billingReducer = createReducer(BILLING_STATE,
  on(BILLING_ACTIONS.SET_BILLBOX, (state: IBillingState, { payload }) => {
    return { ...state, billbox: { ...payload } };
  }),
  on(BILLING_ACTIONS.SET_BILLBOX_FILTERS, (state: IBillingState, { payload }) => {
    return { ...state, billbox: { ...state.billbox, filters: payload } };
  }),
  on(BILLING_ACTIONS.SET_BILLBOX_BILLS, (state: IBillingState, { payload }) => {
    return { ...state, billbox: { ...state.billbox, bills: payload } };
  }),
  on(BILLING_ACTIONS.SET_BILLBOX_TRNS_ID, (state: IBillingState, { payload }) => {
    return { ...state, billbox: { ...state.billbox, transactionId: payload } };
  }),
  on(BILLING_ACTIONS.REMOVE_BILLBOX, (state: IBillingState, { payload }) => {
    return { ...state, billbox: {} as IBillBoxData };
  }),


  on(BILLING_ACTIONS.SET_BILLINGDATA, (state: IBillingState, { payload }) => {
    return { ...state, billingData: { ...payload } };
  }),
  on(BILLING_ACTIONS.SET_BILLINGDATA_DATA, (state: IBillingState, { payload }) => {
    return { ...state, billingData: { ...state.billingData, data: payload } };
  }),
  on(BILLING_ACTIONS.SET_BILLINGDATA_ACTIVE, (state: IBillingState, { payload }) => {
    return { ...state, billingData: { ...state.billingData, active: payload } };
  }),

  on(BILLING_ACTIONS.SET_BILLINGDATA_TRNS_ID, (state: IBillingState, { payload }) => {

    return { ...state, billingData: { ...state.billingData, transactionId: payload } };
  }),
  on(BILLING_ACTIONS.REMOVE_BILLINGDATA, (state: IBillingState, { payload }) => {
    return { ...state, billingData: {} as IBillingData };
  }),


  on(BILLING_ACTIONS.SET_REBILLING, (state: IBillingState, { payload }) => {
    return { ...state, rebilling: { ...payload } };
  }),
  on(BILLING_ACTIONS.SET_REBILLING_FOLIO, (state: IBillingState, { payload }) => {
    return { ...state, rebilling: { ...state.rebilling, folio: payload } };
  }),
  on(BILLING_ACTIONS.SET_REBILLING_UUID, (state: IBillingState, { payload }) => {
    return { ...state, rebilling: { ...state.rebilling, uuid: payload } };
  }),
  on(BILLING_ACTIONS.SET_REBILLING_ACTIVE, (state: IBillingState, { payload }) => {
    return { ...state, rebilling: { ...state.rebilling, active: payload } };
  }),
  on(BILLING_ACTIONS.SET_REBILLING_DATA, (state: IBillingState, { payload }) => {
    const rebilling = <IRebillingData>{ ...state.rebilling, data: payload };
    return { ...state, rebilling };
  }),
  on(BILLING_ACTIONS.SET_REBILLING_ADDITIONALDATA, (state: IBillingState, { payload }) => {
    const rebilling = <IRebillingData>{ ...state.rebilling, additionalData: payload };
    return { ...state, rebilling };
  }),
  on(BILLING_ACTIONS.SET_REBILLING_TRNS_ID, (state: IBillingState, { payload }) => {
    const rebilling = <IRebillingData>{ ...state.rebilling, transactionId: payload };
    return { ...state, rebilling };
  }),
  on(BILLING_ACTIONS.REMOVE_REBILLING, (state: IBillingState, { payload }) => {
    const rebilling = <IRebillingData>{};
    return { ...state, rebilling };
  }),

  on(BILLING_ACTIONS.SET_BILLING_MESSAGE, (state: IBillingState, { payload }) => {
    const billingMessages = <IBillingMessages>{ ...payload };
    return { ...state, billingMessages };
  }),
  on(BILLING_ACTIONS.REMOVE_BILLING_MESSAGE, (state: IBillingState, { payload }) => {
    const billingMessages = <IBillingMessages>{};
    return { ...state, billingMessages };
  }),
);

export function BillingReducer(state, action) {
  return _billingReducer(state, action);
}
