// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-unitec-logo {
  height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/layouts/pages/public-layout/public-layout.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ","sourcesContent":[".nav-unitec-logo {\r\n    height: 20px;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
