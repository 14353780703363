import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadModule } from 'primeng/fileupload';
import { TreeTableModule } from 'primeng/treetable';
import { CarouselModule } from 'primeng/carousel';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { RatingModule } from 'primeng/rating';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { BadgeModule } from "primeng/badge";
import { TimelineModule } from "primeng/timeline";
import { AvatarModule } from 'primeng/avatar';
import { DividerModule } from 'primeng/divider';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { SkeletonModule } from 'primeng/skeleton';
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { SplitterModule } from "primeng/splitter";
import { KnobModule } from 'primeng/knob';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { SpeedDialModule } from 'primeng/speeddial';
import { DockModule } from 'primeng/dock';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CalendarModule } from 'primeng/calendar';
import { ChipModule } from 'primeng/chip';
import { ScrollerModule } from 'primeng/scroller';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { GalleriaModule } from 'primeng/galleria';
import { OrderListModule } from 'primeng/orderlist';
import { ImageModule } from 'primeng/image';
import { FloatLabelModule } from 'primeng/floatlabel';
import { Prime_Locale_ES } from 'src/app/core/utilities/locale/primemg-locale-es';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FileUploadModule,
    TreeTableModule,
    CarouselModule,
    DataViewModule,
    DropdownModule,
    RatingModule,
    PanelModule,
    DialogModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    OverlayPanelModule,
    BadgeModule,
    TimelineModule,
    AvatarModule,
    DividerModule,
    ToastModule,
    SkeletonModule,
    ConfirmPopupModule,
    SplitterModule,
    KnobModule,
    ScrollPanelModule,
    VirtualScrollerModule,
    SpeedDialModule,
    DockModule,
    AutoCompleteModule,
    BreadcrumbModule,
    ChipModule,
    ScrollerModule,
    InputGroupModule,
    InputGroupAddonModule,
    CalendarModule,
    ProgressSpinnerModule,
    GalleriaModule,
    OrderListModule,
    ImageModule,
    FloatLabelModule
  ],
  exports: [
    FileUploadModule,
    TreeTableModule,
    CarouselModule,
    DataViewModule,
    DropdownModule,
    RatingModule,
    PanelModule,
    DialogModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    OverlayPanelModule,
    BadgeModule,
    TimelineModule,
    AvatarModule,
    DividerModule,
    ToastModule,
    SkeletonModule,
    ConfirmPopupModule,
    SplitterModule,
    KnobModule,
    ScrollPanelModule,
    VirtualScrollerModule,
    SpeedDialModule,
    DockModule,
    AutoCompleteModule,
    BreadcrumbModule,
    ChipModule,
    ScrollerModule,
    InputGroupModule,
    InputGroupAddonModule,
    CalendarModule,
    ProgressSpinnerModule,
    GalleriaModule,
    OrderListModule,
    ImageModule,
    FloatLabelModule
  ],
  providers: [
    MessageService,
    ConfirmationService,
  ]
})
export class PrimengModule {
  constructor(private primengConfig: PrimeNGConfig) {
    this.primengConfig.setTranslation(Prime_Locale_ES.es);
  }
}
