import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { ConfirmationFacade } from './facades/confirmation-facade';
import { ConfirmationReducer } from './store/confirmation.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('confirmation', ConfirmationReducer),
  ],
  declarations: [],
  providers: [
    ConfirmationFacade
  ]
})
export class ConfirmationStoreModule { }
