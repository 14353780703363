import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { UserFacade } from '../../../redux/user/facades/user-facade';
import { Observable, firstValueFrom, of } from 'rxjs';
import { IUserData } from '../../../redux/user/interfaces/iuser-data';
import { ImpersonateFacade } from '../../../redux/impersonate/facades/impersonate-facade';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PROFILE_URL } from 'src/app/core/session/services/auth/auth.service';

@UntilDestroy()
@Injectable()
export class ImpersonateResolve  {

    _urlRedirect: string;
    profile$: Observable<IUserData> = null;
    isActive$: Observable<boolean> = null;

    constructor(
        private router: Router,
        private userFacde: UserFacade,
        private impersonateFacade: ImpersonateFacade
    ) {
        this.userFacde.profile$.pipe(untilDestroyed(this)).subscribe(profile => this.profile$ = of(profile));
        this.impersonateFacade.isActive$.pipe(untilDestroyed(this)).subscribe((isActive: boolean) => this.isActive$ = of(isActive));
    }

    resolve(route: ActivatedRouteSnapshot): Promise<any> | boolean {
        return firstValueFrom(this.isActive$).then((active: boolean) => {
            if (active) {
                this.router.navigate([PROFILE_URL]);
                return false;
            } else {
                return true;
            }
        });
    }

}
