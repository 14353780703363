// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block-btn {
  width: 100%;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/auth/components/tutor-dialog/tutor-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,cAAA;AACF","sourcesContent":[".block-btn {\r\n  width: 100%;\r\n  display: block;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
