import { createReducer, on } from '@ngrx/store';
import { CREATE_TUTOR_STATE } from '../interfaces/admin-tutor.state';
import { ICreateTutorState } from '../interfaces/icreate-tutor-state';
import * as CREATE_TUTOR_ACTIONS from './admin-tutor.actions';

const _createTutorReducer = createReducer(CREATE_TUTOR_STATE,
    on(CREATE_TUTOR_ACTIONS.SELECT_STUDENT, (state: ICreateTutorState, { payload }) => ({ ...state, selectedStudent: payload })),
    on(CREATE_TUTOR_ACTIONS.SET_CURRENT_STEP, (state: ICreateTutorState, { payload }) => ({ ...state, step: payload })),
    on(CREATE_TUTOR_ACTIONS.SELECT_TUTOR, (state: ICreateTutorState, { payload }) => ({ ...state, selectedTutor: payload }))
);

export function CreateTutorReducer(state, action) {
    return _createTutorReducer(state, action);
}