export const WaitMessage = [
    'Seguimos trabajando por favor espere',
    'El proceso esta tardando mas de lo usual pero continuamos trabajando, por favor espere',
    'Generando vista previa, por favor espere',
];

export const WaitMessagePayment = [
    'Seguimos trabajando por favor espere',
    'El proceso esta tardando mas de lo usual pero continuamos trabajando, por favor espere',
    'Cargando datos del pago, por favor espere',
];