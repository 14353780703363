import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NavigationNodeFactory } from '../factories/NavigationNodeFactory';
import { INavigationState } from '../interfaces/inavigation-state';
import { INavigationTree } from '../interfaces/inavigation-tree';
import { Observable } from 'rxjs';
import { INavigationNode } from '../interfaces/inavigation-node';
import { INavigationNodeData } from '../interfaces/inavigation-node-data';
import { filter, map } from 'rxjs/operators';
import { getState } from '../store/navigation.selectors';
import * as NAVIGATION_ACTIONS from '../store/navigation.actions';

@Injectable()
export class NavigationFacade {

  private state$: Observable<INavigationState> = this.store.select(getState);

  /**
   * Navigation config state from store
   */
  configState$: Observable<INavigationTree<INavigationNodeData>> = this.state$
    .pipe(select(state => state.config));

  /**
   * Current navigation node state from store
   */
  currentState$: Observable<INavigationNodeData> = this.state$
    .pipe(select(state => state.current), filter(current => !!current));

  /**
   * Navigation node tree
   */
  tree$: Observable<INavigationTree<INavigationNode>> = this.configState$
    .pipe(map(config => this.nodeFactory.createTree(config)));

  /**
   * Current navigation node
   */
  current$: Observable<INavigationNode> = this.currentState$
    .pipe(map(current => this.nodeFactory.create(current)));


  sidebarOpened$: Observable<boolean> = this.state$
    .pipe(select(state => state.sidebarOpened));

  constructor(
    private store: Store<INavigationState>,
    private nodeFactory: NavigationNodeFactory,
  ) { }

  setConfig(config: INavigationTree<INavigationNodeData>) {
    this.store.dispatch(NAVIGATION_ACTIONS.SET_CONFIG({ payload: config }));
  }

  setCurrent(config: INavigationNodeData) {
    this.store.dispatch(NAVIGATION_ACTIONS.SET_CURRENT({ payload: config }));
  }

  openSidebar(open: boolean) {
    this.store.dispatch(NAVIGATION_ACTIONS.OPEN_SIDEBAR({ payload: open }));
  }

  restoreNavigation(userId: string) {
    this.store.dispatch(NAVIGATION_ACTIONS.GET_NAVIGATION({ userId }));
  }
}
