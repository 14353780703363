import { Injectable } from '@angular/core';
import { ConfigService } from '../../../config/services/config.service';
import { Endpoint } from '../../models/endpoint/endpoint';
import { EndpointListInterface } from '../../interfaces/endpoint-list-interface';
import { DAY, HOUR, WEEK, MINUTE } from '../../../cache/services/cache.service';

@Injectable({
	providedIn: 'root',
})
export class EndpointsService implements EndpointListInterface {
	public readonly account;
	public readonly accademicOffer;
	public readonly carousel;
	public readonly catalogs;
	public readonly assets;
	public readonly users;
	public readonly supportingDocuments;
	public readonly transactions;
	public readonly subjectsList;
	public readonly menu;
	public readonly registration;
	public readonly payment;
	public readonly classWithdraw;
	public readonly genericDocument;
	public readonly chatbot;
	public readonly informativeFile;
	public readonly captionsConfig;
	public readonly documentManager;
	public readonly bookSale;
	public readonly debts;
	public readonly socialService;
	public readonly academicOffer;
	public readonly scholarshipCalculator;
	public readonly calendarImages;
	public readonly billing;
	public readonly earlyEnrollment;
	public readonly reenrollment;
	public readonly titleResquest;
	public readonly files: any;
	public readonly notification;

	/**
	 * Endpoint constructor
	 * @param {ConfigService} config
	 */
	constructor(protected config: ConfigService) {
		const api: string = this.config.get('api.url', '');
		const reenrollment_api: string = this.config.get('reenrollment_api.url', '');
		const titleResquest_api: string = this.config.get('timbrados.url', '');
		const cemdi_api: string = this.config.get('cemdi_api.url', '');
		const sselApi: string = this.config.get('ssel.url', '');
		const blobStorageApi: string = this.config.get('storage.blob.url', '');
		const functionsApi: string = this.config.get('functions.url', '');
		const bankbridgeApi: string = this.config.get('bankbridge.url', '');
		const authApi: string = this.config.get('chatbot.azure.authUrl', '');
		const botApi: string = this.config.get('chatbot.api', '');

		this.account = {
			permissions: new Endpoint(functionsApi, `menu/school/{schoolId}`, {
				authorization: false,
				cache: HOUR,
			}),
			menu: new Endpoint(api, 'menu/{userId}', {
				authorization: true,
				cache: 0,
			}),
			killSession: new Endpoint(sselApi, 'sesionActiva.jsp', {
				authorization: false,
				cache: 0,
			}),
			signUp: new Endpoint(sselApi, 'registerUserEP.jsp', {
				authorization: false,
				cache: WEEK,
			}),
			userProfile: new Endpoint(api, 'accounts/profile/{userId}', {
				authorization: true,
				cache: DAY,
			}),
			sselSession: new Endpoint(api, 'accounts/sselsession/{userId}', {
				authorization: true,
				cache: HOUR,
			}),
			resetAADPassword: new Endpoint(api, 'accounts/ResetAADPassword', {
				authorization: false,
				cache: 0,
			}),
			checkSession: new Endpoint(functionsApi, 'alive/{id}', {
				authorization: true,
				cache: 0,
			}),
			profilePictureEndpoint: new Endpoint(
				api,
				'accounts/profile/{userId}/picture',
				{ authorization: true, cache: DAY }
			),
			getADGroups: new Endpoint(api, 'accounts/tenant/groups', {
				authorization: true,
				cache: DAY,
			}),

			executives: {
				manage: new Endpoint(api, 'Accounts/executives', {
					authorization: true,
					cache: 0,
				}),
				executive: new Endpoint(api, 'Accounts/executives/{userId}', {
					authorization: true,
					cache: 0,
				}),
				executiveByJobs: new Endpoint(api, 'Accounts/executive/job/{jobId}', {
					authorization: true,
					cache: 0,
				}),
				executiveByJobInCampus: new Endpoint(
					api,
					'Accounts/executive/job/{jobId}/campus/{campusId}',
					{ authorization: true, cache: 0 }
				),
				signatories: new Endpoint(api, 'Accounts/signatories', {
					authorization: true,
					cache: 0,
				}),
				signatoriesDocuments: new Endpoint(
					api,
					'Accounts/signatories/documents',
					{ authorization: true, cache: 0 }
				),
			},
		};

		this.accademicOffer = {
			request: new Endpoint(api, 'AcademicOffer/{profileUserId}', {
				authorization: true,
				cache: 0,
			}),
			response: new Endpoint(api, 'AcademicOffer/{profileUserId}/{guid}', {
				authorization: true,
				cache: 0,
			}),
		};

		this.catalogs = {
			campus: new Endpoint(api, 'catalogs/campus', {
				authorization: true,
				cache: 0,
			}),
			campusById: new Endpoint(api, 'catalogs/campus/{id}', {
				authorization: true,
				cache: 0,
			}),
			careers: new Endpoint(api, 'catalogs/campus/{campusId}/careers', {
				authorization: true,
				cache: 0,
			}),
			schools: new Endpoint(api, 'catalogs/campus/{campusId}/schools', {
				authorization: true,
				cache: 0,
			}),
			relationship: new Endpoint(api, 'catalogs/relationships/{id?}', {
				authorization: true,
				cache: WEEK,
			}),
			jobs: new Endpoint(api, 'Accounts/Admin/Job', {
				authorization: true,
				cache: 0,
			}),
			careersByWord: new Endpoint(api, 'catalogs/careers/{word}', {
				authorization: true,
				cache: DAY,
			}),
			schoolsByWord: new Endpoint(api, 'catalogs/schools/{word}', {
				authorization: true,
				cache: DAY,
			}),
			saveCampusChanges: new Endpoint(
				api,
				'catalogs/campus/saveChanges/{userId}',
				{ authorization: true, cache: 0 }
			),

			getInformativeConfiguration: new Endpoint(
				api,
				'Catalogs/InformativeFile/{informativeTypeId}/Configuration',
				{ authorization: true, cache: 0 }
			),
			getInformativeValidation: new Endpoint(
				api,
				'Catalogs/InformativeFile/{informativeTypeId}/profileId/{profileId}',
				{ authorization: true, cache: 0 }
			),
			getInformativeFile: new Endpoint(
				api,
				'Catalogs/InformativeFile/{informativeTypeId}',
				{ authorization: true, cache: 0 }
			),
			informativeFile: new Endpoint(api, 'Catalogs/InformativeFile', {
				authorization: true,
				cache: 0,
			}),
			deleteInformativeFile: new Endpoint(
				api,
				'Catalogs/InformativeFile/{InformativeFileId}',
				{ authorization: true, cache: 0 }
			),
			academicTree: new Endpoint(api, 'Catalogs/Campus/AcademicTree', {
				authorization: true,
				cache: 0,
			}),

			levels: new Endpoint(api, 'catalogs/campus/{campusId}/levels', {
				authorization: true,
				cache: 0,
			}),
			levelsByWord: new Endpoint(api, 'catalogs/levels/{word}', {
				authorization: true,
				cache: DAY,
			}),

			blobNames: new Endpoint(api, 'catalogs/blob/{catalogKey}/names', {
				authorization: true,
				cache: 0,
			}),
			blobs: new Endpoint(api, 'catalogs/blob/{catalogKey}', {
				authorization: true,
				cache: 0,
			}),
			blob: new Endpoint(api, 'catalogs/blob/{catalogKey}/{fullName}', {
				authorization: true,
				cache: 0,
			}),
			blobCatalogs: new Endpoint(api, 'catalogs/blob/catalog', {
				authorization: true,
				cache: 0,
			}),

			getAddress: new Endpoint(api, 'Catalogs/PostalCode/{id}', {
				authorization: true,
				cache: 0,
			}),
			getDownTypes: new Endpoint(api, 'Catalogs/DownTypes', {
				authorization: true,
				cache: 0,
			}),
			getStudentStatus: new Endpoint(api, 'Catalogs/StudentStatus', {
				authorization: true,
				cache: 0,
			}),
		};

		this.assets = {
			carousel: new Endpoint(blobStorageApi, 'carousel/{file}', {
				authorization: false,
				cache: 0,
			}),
			message: new Endpoint(blobStorageApi, 'assets/welcome-message.html', {
				authorization: false,
				cache: 0,
			}),
			campus: new Endpoint(blobStorageApi, 'assets/campi/{campusId}.jpg', {
				authorization: false,
				cache: 0,
			}),
			personsignature: new Endpoint(
				blobStorageApi,
				'personsignature/{id}.png',
				{ authorization: false, cache: 0 }
			),
			mfaMessage: new Endpoint(blobStorageApi, 'assets/mfa-message.html', {
				authorization: false,
				cache: 0,
			}),
			bookSaleMessage: new Endpoint(
				blobStorageApi,
				'assets/book_sale_msg.html',
				{ authorization: false, cache: 0 }
			),
			blobfile: new Endpoint(
				blobStorageApi,
				'{path}',
				{ authorization: false, cache: 0 }
			),
		};

		this.socialService = {
			pdf: new Endpoint(
				blobStorageApi,
				'social-service-companies/Manual_Usuario.pdf',
				{ authorization: false, cache: 0 }
			),
			logo: new Endpoint(
				blobStorageApi,
				'social-service-companies/unitec-logo-ss.png',
				{ authorization: false, cache: 0 }
			),
			careers: new Endpoint(
				blobStorageApi,
				'social-service-companies/social-service-careers.json',
				{ authorization: false, cache: 0 }
			),
			sectors: new Endpoint(
				blobStorageApi,
				'social-service-companies/social-service-sectors.json',
				{ authorization: false, cache: 0 }
			),
			programs: new Endpoint(
				blobStorageApi,
				'social-service-companies/programs/programs-career-{career}-sector-{sector}.json',
				{ authorization: false, cache: 0 }
			),
		};
		this.academicOffer = {
			offerRequest: new Endpoint(api, 'AcademicOffer/{userId}', {
				authorization: true,
				cache: 0,
			}),
			offerRetrieve: new Endpoint(api, 'AcademicOffer/{userId}/{guid}', {
				authorization: true,
				cache: 0,
			}),
		};
		this.scholarshipCalculator = {
			calculatorRequest: new Endpoint(
				api,
				'ScholarshipCalculator/calculator/{userId}',
				{ authorization: true, cache: 0 }
			),
			calculatorRetrieve: new Endpoint(
				api,
				'ScholarshipCalculator/calculator/{userId}/{guid}',
				{ authorization: true, cache: 0 }
			),
			calculationRequest: new Endpoint(
				api,
				'ScholarshipCalculator/calculation',
				{ authorization: true, cache: 0 }
			),
			calculationRetrieve: new Endpoint(
				api,
				'ScholarshipCalculator/calculation/retrieve/{guid}',
				{ authorization: true, cache: 0 }
			),
			calculationRetrieveFile: new Endpoint(
				api,
				'ScholarshipCalculator/calculation/file/retrieve/{guid}',
				{ authorization: true, cache: 0 }
			),
		};
		this.calendarImages = {
			calendarImage: new Endpoint(blobStorageApi, 'calendar/{school}.jpg', {
				authorization: false,
				cache: 0,
			}),
		};

		this.users = {
			tutor: {
				main: new Endpoint(api, 'tutors/{tutorId?}', {
					authorization: true,
					cache: 0,
				}),
				students: new Endpoint(api, 'tutors/{tutorId}/students/{studentId}', {
					authorization: true,
					cache: 0,
				}),
				voucherFile: new Endpoint(api, 'tutors/{tutorId}/voucher/{studentId}', {
					authorization: true,
					cache: 0,
				}),
				credentialsFile: new Endpoint(api, 'tutors/assignment/{tutorId}', {
					authorization: true,
					cache: 0,
				}),
				me: new Endpoint(api, 'tutors/me', { authorization: true, cache: 0 }),
			},

			student: {
				list: new Endpoint(api, 'accounts/impersonate', {
					authorization: true,
					cache: 0,
				}),
				tutors: new Endpoint(api, 'tutors/student/{idStudent}/tutors', {
					authorization: true,
					cache: 0,
				}),
			},

			sync: {
				//update: new Endpoint(api, 'Sync/profile/{profileUserId}',
				update: new Endpoint(api, 'Sync/ADF/profile/{profileUserId}/All', {
					authorization: true,
					cache: 0,
				}),
				clean: new Endpoint(api, 'Sync/profile/{profileUserId}/clean', {
					authorization: true,
					cache: 0,
				}),
				//verify: new Endpoint(api, 'Sync/queueSync/{queueId}',
				verify: new Endpoint(api, 'Sync/ADF/profile/{profileUserId}/All', {
					authorization: true,
					cache: 0,
				}),
			},
		};

		this.supportingDocuments = {
			main: new Endpoint(api, 'SupportingDocument/user/{profileUserId}', {
				authorization: true,
				cache: 0,
			}),
			metadata: new Endpoint(
				api,
				'SupportingDocument/{documentId}/metadata/user/{userId}',
				{ authorization: true, cache: 0 }
			),
			requestPreview: new Endpoint(
				api,
				'SupportingDocument/preview/user/{userId}',
				{ authorization: true, cache: 0 }
			),
			preview: new Endpoint(
				api,
				'SupportingDocument/preview/order/{orderId}/user/{userId}',
				{ authorization: true, cache: 0 }
			),
			changeStatus: new Endpoint(
				api,
				'SupportingDocument/Transactions/Status',
				{ authorization: true, cache: 0 }
			),
			downloadPDF: new Endpoint(
				api,
				'SupportingDocument/folio/{transactionId}/format/{format}/download',
				{ authorization: true, cache: 0 }
			),
			savePreview: new Endpoint(
				api,
				'SupportingDocument/order/{orderId}/user/{userId}',
				{ authorization: true, cache: 0 }
			),
			publicOrderDetail: new Endpoint(
				api,
				'SupportingDocument/PublicOrderDetail/{TransactionId}',
				{ authorization: false, cache: 0 }
			),
			finishOrderRequest: new Endpoint(
				api,
				'SupportingDocument/{profileId}/finishOrderRequest/{TransactionId}',
				{ authorization: true, cache: 0 }
			),
			sendDigitalDocument: new Endpoint(
				api,
				'SupportingDocument/sendDigital/user/{profileId}/transaction/{TransactionId}/order/{OrderId}',
				{ authorization: true, cache: 0 }
			),
			managment: {
				getConfiguration: new Endpoint(
					api,
					'SupportingDocument/admin/document/configuration',
					{ authorization: true, cache: 0 }
				),
				documents: new Endpoint(api, 'SupportingDocument/admin/document', {
					authorization: true,
					cache: 0,
				}),
				document: new Endpoint(
					api,
					'SupportingDocument/admin/document/{documentId}',
					{ authorization: true, cache: 0 }
				),
				deleteDocumentSection: new Endpoint(
					api,
					'SupportingDocument/admin/document/{documentId}/section/{sectionId}',
					{ authorization: true, cache: 0 }
				),
				tags: new Endpoint(api, 'SupportingDocument/admin/tag', {
					authorization: true,
					cache: 0,
				}),
				section: new Endpoint(api, 'SupportingDocument/admin/section', {
					authorization: true,
					cache: 0,
				}),
				getSectionsConfigurations: new Endpoint(
					api,
					'SupportingDocument/admin/section/config',
					{ authorization: true, cache: 0 }
				),
				deleteSection: new Endpoint(
					api,
					'SupportingDocument/admin/section/{SectionId}',
					{ authorization: true, cache: 0 }
				),
				logo: new Endpoint(api, 'SupportingDocument/admin/logo', {
					authorization: true,
					cache: 0,
				}),
				deleteLogo: new Endpoint(api, 'SupportingDocument/admin/logo/{id}', {
					authorization: true,
					cache: 0,
				}),
			},
			operative: {
				getStatus: new Endpoint(
					api,
					'SupportingDocument/admin/SupportingDocumentRequests/AllStatus/{deliveryType}',
					{ authorization: true, cache: 0 }
				),
				getRequests: new Endpoint(
					api,
					'SupportingDocument/admin/SupportingDocumentRequests/{deliveryType}',
					{ authorization: true, cache: 0 }
				),
				getAllowStateChanges: new Endpoint(
					api,
					'SupportingDocument/admin/SupportingDocumentRequests/{transactionId}/AllowStateChanges',
					{ authorization: true, cache: 0 }
				),
				getRequestLogs: new Endpoint(
					api,
					'SupportingDocument/admin/SupportingDocumentRequests/{transactionId}/Logs',
					{ authorization: true, cache: 0 }
				),
				changeStatus: new Endpoint(
					api,
					'SupportingDocument/admin/SupportingDocumentRequests/ChangeStatus',
					{ authorization: true, cache: 0 }
				),
				sendRequestByEmail: new Endpoint(
					api,
					'SupportingDocument/admin/SupportingDocumentRequests/send/transaction/{transactionId}/{recreate}',
					{ authorization: true, cache: 0 }
				),
			},
			procedureList: new Endpoint(
				api,
				'SupportingDocument/{profileId}/deliveryTypes',
				{ authorization: true, cache: 0 }
			),
			requestPrices: new Endpoint(
				api,
				'SupportingDocument/price/user/{userId}/profile/{profileUserId}',
				{ authorization: true, cache: 0 }
			),
			validateTransaction: new Endpoint(
				api,
				'SupportingDocument/request/{queueId}',
				{ authorization: true, cache: 0 }
			),
			getPrices: new Endpoint(
				api,
				'SupportingDocument/price/{requestId}/user/{userId}/profile/{profileUserId}',
				{ authorization: true, cache: 0 }
			),
			savePaymentRequest: new Endpoint(
				api,
				'SupportingDocument/request/user/{userId}/profile/{profileUserId}/transaction/{transactionId}',
				{ authorization: true, cache: 0 }
			),
			getPayRequest: new Endpoint(
				api,
				'SupportingDocument/request/user/{userId}/profile/{profileUserId}/getPayRequest/{paymentId}',
				{ authorization: true, cache: 0 }
			),
		};

		this.transactions = {
			list: new Endpoint(
				api,
				'TransactionsEngine/Procedures/Transactions/CurrentState',
				{ authorization: true, cache: 0 }
			),
			procedureStatus: new Endpoint(
				api,
				'TransactionsEngine/Procedures/{procedureId}/Transitions',
				{ authorization: true, cache: 0 }
			),
			nextStatus: new Endpoint(
				api,
				'TransactionsEngine/Procedures/{procedureId}/Procedure/{folio}/NextStatus',
				{ authorization: true, cache: 0 }
			),
			changeStatus: new Endpoint(
				api,
				'TransactionsEngine/Procedures/ChangeStatus',
				{ authorization: true, cache: 0 }
			),
			proceduresList: new Endpoint(api, 'TransactionsEngine/Procedures', {
				authorization: true,
				cache: 0,
			}),
			transactionRestore: new Endpoint(
				api,
				'TransactionsEngine/Procedures/ReturnToAction/{TransactionId}/user/{profileId}',
				{ authorization: true, cache: 0 }
			),
			transactionListByUser: new Endpoint(
				api,
				'TransactionsEngine/TransactionsByUser/CurrentState/{SisAccount}/user/{profileId}',
				{ authorization: true, cache: 0 }
			),
			transactionTracker: new Endpoint(
				api,
				'TransactionsEngine/TransactionTracker/CurrentState/{TransactionId}/user/{profileId}',
				{ authorization: true, cache: 0 }
			),
			blog: new Endpoint(
				api,
				'TransactionsEngine/Procedures/{idTransaction}/TransactionLogs',
				{ authorization: true, cache: 0 }
			),
			cancel: new Endpoint(
				api,
				'TransactionsEngine/transaction/{transactionId}/Cancel/{profileId}',
				{ authorization: true, cache: 0 }
			),
		};

		this.notification = {
			echo: new Endpoint(api, 'Notifications/echo', {
				authorization: true,
				cache: 0,
			})
		};

		this.subjectsList = {
			data: new Endpoint(api, 'SubjectList/profile/{profileUserId}/data', {
				authorization: true,
				cache: MINUTE * 10,
			}),
			document: new Endpoint(
				api,
				'SubjectList/profile/{profileUserId}/document',
				{ authorization: true, cache: MINUTE * 10 }
			),
		};

		this.menu = {
			menuTest: new Endpoint(api, 'Menu/{userId}/Test', {
				authorization: true,
				cache: 0,
			}),
			configuration: new Endpoint(api, 'Menu?fullControl={fullControl}', {
				authorization: true,
				cache: 0,
			}),
			updateRoles: new Endpoint(api, 'Menu/{idItem}/roles', {
				authorization: true,
				cache: 0,
			}),
			updateStatus: new Endpoint(api, 'Menu/{idItem}/status', {
				authorization: true,
				cache: 0,
			}),
			globalConfig: new Endpoint(api, 'Menu/globalstatus', {
				authorization: true,
				cache: 0,
			}),
			menu: new Endpoint(api, 'Menu', { authorization: true, cache: 0 }),
			saveTransformation: new Endpoint(api, 'Menu/{idItem}/transformations', {
				authorization: true,
				cache: 0,
			}),
			deleteMenu: new Endpoint(api, 'Menu/delete', {
				authorization: true,
				cache: 0,
			}),
			sortMenu: new Endpoint(api, 'Menu/sort', {
				authorization: true,
				cache: 0,
			}),
		};

		this.registration = {
			data: new Endpoint(api, 'Accounts/RIELSession/{userId}', {
				authorization: true,
				cache: 0,
			}),
		};

		this.payment = {
			requestList: new Endpoint(api, 'Payment/Request/{id}', { authorization: true, cache: 0 }),
			savePaymentRequest: new Endpoint(api, 'Payment/request/user/{userId}/profile/{profileUserId}',
				{ authorization: true, cache: 0 }),
			validatePayment: new Endpoint(api, 'Payment/validate/{paymentId}/profileUserId/{profileUserId}',
				{ authorization: true, cache: 0 }),
			requestPaymentProcess: new Endpoint(api, 'Payment/bank/payment/{paymentId}/profile/{profileUserId}/v1',
				{ authorization: true, cache: 0 }),
			requestPaymentProcessV2: new Endpoint(api, 'Payment/bank/payment/{paymentId}/profile/{profileUserId}/v2',
				{ authorization: true, cache: 0 }),
			requestPaymentFinalize: new Endpoint(api, 'Payment/bank/user/{userId}/payment/{paymentId}/profile/{profileUserId}/v1',
				{ authorization: true, cache: 0 }),
			requestPaymentFinalizeV2: new Endpoint(api, 'Payment/bank/user/{userId}/payment/{paymentId}/profile/{profileUserId}/v2',
				{ authorization: true, cache: 0 }),
			requestPaymentStatus: new Endpoint(api, 'Payment/{paymentId}/profileUserId/{profileUserId}',
				{ authorization: true, cache: 0 }),
			bankbridge: {
				get: new Endpoint(bankbridgeApi, 'PaymentRequest/{paymentId}',
					{ authorization: false, cache: 0 }),
				save: new Endpoint(bankbridgeApi, 'PaymentRequest',
					{ authorization: false, cache: 0 }),
			},
			proofOfPayment: new Endpoint(api, 'Payment/proofOfPayment/payment/{paymentId}/profile/{profileUserId}',
				{ authorization: true, cache: 0 }
			),
			validatePostPayment: new Endpoint(api, 'Payment/request/{queueId}',
				{ authorization: true, cache: 0 }),
			cancelPayment: new Endpoint(api, 'Payment/cancel/{paymentId}/profileUserId/{profileUserId}',
				{ authorization: true, cache: 0 }
			),


			requestPaymentChannelProcess: new Endpoint(api, 'Payment/channel/{channel}/payment/{paymentId}/profile/{profileUserId}',
				{ authorization: true, cache: 0 }),
			requestPaymentChannelStatus: new Endpoint(api, 'Payment/channel/{channel}/payment/{paymentId}/profile/{profileUserId}/status/{apiCoDiId}',
				{ authorization: true, cache: 0 }),
		};

		this.billing = {
			requestMailBox: new Endpoint(
				api,
				'Billing/Invoices/MailBox/{profileId}',
				{ authorization: true, cache: 0 }
			),
			getMailBox: new Endpoint(
				api,
				'Billing/Invoices/MailBox/{profileId}/{executionId}',
				{ authorization: true, cache: 0 }
			),
			requestBillboxInvoiceFile: new Endpoint(
				api,
				'Billing/Invoices/MailBox/{invoiceId}/Bill/Files/{profileId}/{mailboxRequestId}?rebill={rebilling}',
				{ authorization: true, cache: 0 }
			),
			requestInvoiceFile: new Endpoint(
				api,
				'Billing/{invoiceId}/{transactionId}/Bill/Files/{profileId}?rebill={rebilling}',
				{ authorization: true, cache: 0 }
			),
			getInvoiceFiles: new Endpoint(
				api,
				'Billing/Bill/{invoiceId}/Files/{profileId}/{requestId}?rebill={rebilling}',
				{ authorization: true, cache: 0 }
			),

			searchRfc: new Endpoint(
				api,
				'Billing/Search/Rfc/{rfc}/{profileId}/{mailBoxRequestId}',
				{ authorization: true, cache: 0 }
			),
			getRfc: new Endpoint(api, 'Billing/Search/Rfc/{profileId}/{RequestId}', {
				authorization: true,
				cache: 0,
			}),
			requestUpdateRfc: new Endpoint(
				api,
				'Billing/Update/ThirdParty/{profileId}/{mailBoxRequestId}',
				{ authorization: true, cache: 0 }
			),
			responseUpdateRfc: new Endpoint(
				api,
				'Billing/Update/ThirdParty/{profileId}/{requestId}',
				{ authorization: true, cache: 0 }
			),
			getReceiptThirdsChanges: new Endpoint(
				api,
				'Billing/Update/ThirdParty/Receipt/{profileId}/{requestId}',
				{ authorization: true, cache: 0 }
			),
			searchForeignRfc: new Endpoint(api, 'Billing/Search/Rfc/Foreign/{profileId}/{mailBoxRequestId}',
				{ authorization: true, cache: 0 }),
			getForeignRfc: new Endpoint(api, 'Billing/Search/Rfc/Foreign/{profileId}/{RequestId}',
				{ authorization: true, cache: 0 }),
			requestRebilling: new Endpoint(
				api,
				'Billing/Invoice/Rebill/{profileId}/{requestId}/{invoiceId}',
				{ authorization: true, cache: 0 }
			),
			responseRebilling: new Endpoint(
				api,
				'Billing/Invoice/Rebill/{profileId}/{requestId}',
				{ authorization: true, cache: 0 }
			),

			sendEmail: new Endpoint(
				api,
				'Billing/Bill/{invoiceId}/Send/{RequestId}/{profileId}',
				{ authorization: true, cache: 0 }
			),
			validateRequestStatus: new Endpoint(
				api,
				'Billing/Request/{RequestId}/Status/{profileId}',
				{ authorization: true, cache: 0 }
			),
		};

		this.classWithdraw = {
			reasons: new Endpoint(api, 'ClassWithdraw/Reasons', {
				authorization: true,
				cache: 0,
			}),
			careers: new Endpoint(api, 'ClassWithdraw/Careers/{userId}', {
				authorization: true,
				cache: 0,
			}),
			requestSchedule: new Endpoint(
				api,
				'ClassWithdraw/Request/profileId/{profileId}/careerId/{careerId}',
				{ authorization: true, cache: 0 }
			),
			withdrawRequestReady: new Endpoint(
				api,
				'ClassWithdraw/sisdata/profileId/{profileId}/requestId/{requestId}/call/{sp}',
				{ authorization: true, cache: 0 }
			),
			getSchedule: new Endpoint(
				api,
				'ClassWithdraw/Request/profile/{profileId}/RequestId/{RequestId}',
				{ authorization: true, cache: 0 }
			),
			withdrawRequest: new Endpoint(
				api,
				'ClassWithdraw/Request/profileId/{profileId}/requestId/{requestId}',
				{ authorization: true, cache: 0 }
			),
			cancelRequest: new Endpoint(
				api,
				'ClassWithdraw/Request/profileId/{profileId}/requestId/{requestId}/cancel',
				{ authorization: true, cache: 0 }
			),
			getConfirmData: new Endpoint(
				api,
				'ClassWithdraw/Confirm/profileId/{profileId}/requestId/{requestId}',
				{ authorization: true, cache: 0 }
			),
			getDetail: new Endpoint(
				api,
				'ClassWithdraw/Request/profile/{profileId}/RequestId/{RequestId}/detail',
				{ authorization: true, cache: 0 }
			),
			getVoucher: new Endpoint(
				api,
				'ClassWithdraw/receipt/profileId/{profileId}/requestId/{requestId}',
				{ authorization: true, cache: 0 }
			),

			guidelines: new Endpoint(api, 'ClassWithdraw/Guideline', {
				authorization: true,
				cache: 0,
			}),
			deleteGuideline: new Endpoint(
				api,
				'ClassWithdraw/Guideline/{guidelineId}',
				{ authorization: true, cache: 0 }
			),
		};

		this.genericDocument = {
			getDocumentTypeMetadata: new Endpoint(
				api,
				'Document/{documentType}/configuration/user/{profileUserId}',
				{ authorization: true, cache: 0 }
			),
			getDocumentMetadata: new Endpoint(
				api,
				'Document/{documentId}/metadata/user/{profileUserId}',
				{ authorization: true, cache: 0 }
			),
			requestDocumentPrices: new Endpoint(
				api,
				'Document/{documentId}/price/profile/{profileUserId}',
				{ authorization: true, cache: 0 }
			),
			getPaymentStatus: new Endpoint(api, 'Document/request/{queueId}', {
				authorization: true,
				cache: 0,
			}),
			getDocumentPrices: new Endpoint(
				api,
				'Document/{documentId}/price/{requestId}/profile/{profileUserId}',
				{ authorization: true, cache: 0 }
			),
			startDocument: new Endpoint(api, 'Document/start/user/{profileUserId}', {
				authorization: true,
				cache: 0,
			}),
			getDocumentPreview: new Endpoint(
				api,
				'Document/start/order/{orderId}/user/{profileUserId}',
				{ authorization: true, cache: 0 }
			),
			activityLogs: new Endpoint(
				api,
				'Document/{orderId}/Activity/{ActivityKey}',
				{ authorization: true, cache: 0 }
			),
			activityStart: new Endpoint(api, 'Activity/{document}/{activity}', {
				authorization: true,
				cache: 0,
			}),
			downloadDocument: new Endpoint(
				api,
				'Document/admin/transaction/{transactionId}/files',
				{ authorization: true, cache: 0 }
			),
			getPaymentId: new Endpoint(
				api,
				'Document/order/{orderId}/profile/{profileUserId}',
				{ authorization: true, cache: 0 }
			),
			validateDebsRequets: new Endpoint(
				api,
				'Document/{procedure}/{requestId}/profile/{profileUserId}/status',
				{ authorization: true, cache: 0 }
			),
			getDebs: new Endpoint(
				api,
				'Document/debts/{requestId}/profile/{profileUserId}',
				{ authorization: true, cache: 0 }
			),
			getDocuments: new Endpoint(
				api,
				'Document/admin/documentRequests/{documentType}/{deliveryType}',
				{ authorization: true, cache: 0 }
			),
			getAllStatus: new Endpoint(
				api,
				'Document/admin/documentRequests/{documentType}/AllStatus/{deliveryType}',
				{ authorization: true, cache: 0 }
			),
			getAllowStateChanges: new Endpoint(
				api,
				'Document/admin/documentRequests/{transactionId}/AllowStateChanges',
				{ authorization: true, cache: 0 }
			),
			getDocumentLogs: new Endpoint(
				api,
				'Document/folio/{transactionId}/Logs',
				{ authorization: true, cache: 0 }
			),
			getPaymentRequest: new Endpoint(
				api,
				'Document/order/profile/{profileUserId}/payRequest/{paymentId}',
				{ authorization: true, cache: 0 }
			),
			getSummaryRequest: new Endpoint(
				api,
				'Document/final/profile/{profileId}/transaction/{transactionId}',
				{ authorization: true, cache: 0 }
			),
			getDocumentsQr: new Endpoint(
				api,
				'Document/qr/transaction/{transactionId}?captchaReq={captchaReq}',
				{ authorization: false, cache: 0 }
			),
			changeStatus: new Endpoint(api, 'Document/Transactions/Status', {
				authorization: true,
				cache: 0,
			}),
			//Document Administration Section
			getConfiguration: new Endpoint(
				api,
				'Document/admin/document/{documentType}/configuration',
				{ authorization: true, cache: 0 }
			),
			documents: new Endpoint(api, 'Document/admin/document/{documentType}', {
				authorization: true,
				cache: 0,
			}),
			document: new Endpoint(
				api,
				'Document/admin/document/{documentType}/{documentId}',
				{ authorization: true, cache: 0 }
			),
			deleteDocumentSection: new Endpoint(
				api,
				'Document/admin/document/{documentType}/{documentId}/section/{sectionId}',
				{ authorization: true, cache: 0 }
			),
			tags: new Endpoint(api, 'Document/admin/{documentType}/tag', {
				authorization: true,
				cache: 0,
			}),
			section: new Endpoint(api, 'Document/admin/{documentType}/section', {
				authorization: true,
				cache: 0,
			}),
			getSectionsConfigurations: new Endpoint(
				api,
				'Document/admin/{documentType}/section/config',
				{ authorization: true, cache: 0 }
			),
			deleteSection: new Endpoint(
				api,
				'Document/admin/{documentType}/section/{SectionId}',
				{ authorization: true, cache: 0 }
			),
			logos: new Endpoint(api, 'Document/admin/{documentType}/logo', {
				authorization: true,
				cache: 0,
			}),
			sendByEmail: new Endpoint(
				api,
				'Document/send/transaction/{transactionId}/{recreate}',
				{ authorization: true, cache: 0 }
			),
		};

		this.informativeFile = {
			getInformativeValidation: new Endpoint(
				api,
				'Catalogs/InformativeFile/{informativeTypeId}/profileId/{profileId}',
				{ authorization: true, cache: 0 }
			),
			getInformativeFile: new Endpoint(
				api,
				'Catalogs/InformativeFile/{informativeTypeId}',
				{ authorization: true, cache: 0 }
			),
			postInformativeFile: new Endpoint(api, 'Catalogs/InformativeFile', {
				authorization: true,
				cache: 0,
			}),
			patchInformativeFile: new Endpoint(api, 'Catalogs/InformativeFile', {
				authorization: true,
				cache: 0,
			}),
			deletInformativeFile: new Endpoint(
				api,
				'Catalogs/InformativeFile/{InformativeFileId}',
				{ authorization: true, cache: 0 }
			),
		};

		this.captionsConfig = {
			getLegendsConfiguration: new Endpoint(api, 'Document/legend', {
				authorization: true,
				cache: 0,
			}),
			getConfigurations: new Endpoint(api, 'Document/legend/configuration', {
				authorization: true,
				cache: 0,
			}),
			saveConfigurations: new Endpoint(api, 'Document/legend', {
				authorization: true,
				cache: 0,
			}),
			updateConfigurations: new Endpoint(api, 'Document/legend/{groupId}', {
				authorization: true,
				cache: 0,
			}),
			deleteConfigurations: new Endpoint(api, 'Document/legend/{groupId}', {
				authorization: true,
				cache: 0,
			}),
		};

		this.chatbot = {
			authAzureAD: new Endpoint(authApi, '', {
				authorization: false,
				cache: 0,
			}),
			authBot: new Endpoint(botApi, '', { authorization: false, cache: 0 }),
			meBot: new Endpoint(api, 'Accounts/bot/{userId}', {
				authorization: true,
				cache: 0,
			}),
		};

		this.documentManager = {
			documentType: new Endpoint(api, 'Document/admin/document/types', {
				authorization: true,
				cache: 0,
			}),
			deleteDocumentTypes: new Endpoint(
				api,
				'Document/admin/document/types/{documentTypeId}/removeDocs/{RemoveDocs}',
				{ authorization: true, cache: 0 }
			),
			getDocumentTypeConfiguration: new Endpoint(
				api,
				'Document/admin/document/{documentType}/configuration',
				{ authorization: true, cache: 0 }
			),
			//Sections
			sections: new Endpoint(api, 'Document/admin/{documentType}/section', {
				authorization: true,
				cache: 0,
			}),
			getSectionsConfigurations: new Endpoint(
				api,
				'Document/admin/{documentType}/section/config',
				{ authorization: true, cache: 0 }
			),
			section: new Endpoint(
				api,
				'Document/admin/{documentType}/section/{SectionId}',
				{ authorization: true, cache: 0 }
			),
			getTags: new Endpoint(api, 'Document/admin/{documentType}/tag', {
				authorization: true,
				cache: 0,
			}),

			getDocumentsConfig: new Endpoint(
				api,
				'Document/admin/document/{documentType}/configuration',
				{ authorization: true, cache: 0 }
			),
			documents: new Endpoint(api, 'Document/admin/document/{documentType}', {
				authorization: true,
				cache: 0,
			}),
			document: new Endpoint(
				api,
				'Document/admin/document/{documentType}/{documentId}',
				{ authorization: true, cache: 0 }
			),
		};

		this.bookSale = {
			getBook: new Endpoint(api, 'Cemdi/GetItems/{profileUserId}',
				{ authorization: true, cache: 0 }),
			buyBook: new Endpoint(api, 'Cemdi/BuyRequest/{profileUserId}',
				{ authorization: true, cache: 0 }),
			searchRequests: new Endpoint(api, 'Cemdi/SearchRequests',
				{ authorization: true, cache: 0 }),
			getVoucher: new Endpoint(api, 'Cemdi/Voucher/{profileId}/{requestId}/{purchaseSource}',
				{ authorization: true, cache: 0 }),
			paymentResponse: new Endpoint(api, 'Cemdi/PaymentResponse/{profileId}/{requestId}',
				{ authorization: true, cache: 0 }),
			sisRequestStatus: new Endpoint(api, 'Cemdi/SisRequestStatus/{profileId}/{requestId}',
				{ authorization: true, cache: 0 }),
			getPaycheckList: new Endpoint(api, 'Cemdi/GetPaycheckList/{userId}/{profileUserId}',
				{ authorization: true, cache: 0 }),

			requestBookOffer: new Endpoint(api, 'Cemdi/Request/BookOffer/{profileId}',
				{ authorization: true, cache: 0 }),
			responseBookOffer: new Endpoint(api, 'Cemdi/Response/BookOffer/{profileId}/request/{requestId}',
				{ authorization: true, cache: 0 }),
			requestBookOfferBuy: new Endpoint(api, 'Cemdi/Request/BookOffer/buy/{profileId}/request/{requestId}',
				{ authorization: true, cache: 0 }),
			responseBookOfferBuy: new Endpoint(api, 'Cemdi/Response/BookOffer/buy/{profileId}/request/{requestId}',
				{ authorization: true, cache: 0 }),
			requestBookOfferHistory: new Endpoint(api, 'Cemdi/Request/BookOffer/History/profile/{profileId}',
				{ authorization: true, cache: 0 }),
			requestBookOfferHistoryDetails: new Endpoint(api, 'Cemdi/Request/BookOffer/History/profile/{profileId}/Details/{ProofId}',
				{ authorization: true, cache: 0 }),
			requestBookOfferHistoryFile: new Endpoint(api, 'Cemdi/Request/BookOffer/History/profile/{profileId}/File/{ProofId}',
				{ authorization: true, cache: 0 }),
		}

		this.debts = {
			requestDebts: new Endpoint(
				api,
				'Payment/carousel/request/profile/{profileUserId}',
				{ authorization: true, cache: 0 }
			),
			getDebts: new Endpoint(
				api,
				'Payment/bankstatement/{requestId}/user/{profileUserId}',
				{ authorization: true, cache: 0 }
			),
			getServices: new Endpoint(
				api,
				'Payment/servicesList/{requestId}/user/{profileUserId}',
				{ authorization: true, cache: 0 }
			),
			validateDebts: new Endpoint(
				api,
				'Payment/{requestId}/procedure/{procedureName}/user/{profileUserId}/status',
				{ authorization: true, cache: 0 }
			),
			carouselRequest: new Endpoint(
				api,
				'Payment/Carousel/PaymentRequest/{processId}/user/{profileId}/type/{type}',
				{ authorization: true, cache: 0 }
			),
			carouselPayment: new Endpoint(
				api,
				'Payment/Carousel/GetRequestPayment/{paymentId}/user/{profileUserId}',
				{ authorization: true, cache: 0 }
			),
		};

		this.earlyEnrollment = {
			requestEnrollment: new Endpoint(api, 'Reenrollment/NextCycle/user/{profileId}/request',
				{ authorization: true, cache: 0 }),
			getEnrollment: new Endpoint(api, 'Reenrollment/NextCycle/user/{profileId}/response/{requestId}',
				{ authorization: true, cache: 0 }),
			requestCalculator: new Endpoint(api, 'Reenrollment/TuitionCalculator/user/{profileId}/request/{requestId}',
				{ authorization: true, cache: 0 }),
			getCalculator: new Endpoint(api, 'Reenrollment/TuitionCalculator/user/{profileId}/response/{requestId}',
				{ authorization: true, cache: 0 }),
			requestAccessoriesPayment: new Endpoint(api,
				'Reenrollment/Payment/Accessories/user/{profileId}/{requestId}',
				{ authorization: true, cache: 0 }),
			requestPayment: new Endpoint(api,
				'Reenrollment/Payment/user/{profileId}/{requestId}?OnlineAmount={OnlineAmount}&PositiveBalanceAmount={PositiveBalanceAmount}&pbCantModify={pbCantModify}',
				{ authorization: true, cache: 0 }
			),
			getPayment: new Endpoint(
				api,
				'Reenrollment/Payment/user/{profileId}/{paymentId}',
				{ authorization: true, cache: 0 }
			),
			validateRequest: new Endpoint(
				api,
				'Reenrollment/Request/Status/{profileId}/{requestId}',
				{ authorization: true, cache: 0 }
			),
			getPoofofPayment: new Endpoint(
				api,
				'Reenrollment/Proof/{profileId}/{requestId}',
				{ authorization: true, cache: 0 }
			),
		};

		this.titleResquest = {
			getToken: new Endpoint(api, 'Titulation/Preregister/Init/{profileId}', {
				authorization: true,
				cache: 0
			}),
			requestInformation: new Endpoint(titleResquest_api, 'External/Services/Preregister/Request', {
				authorization: false,
				timbradosAuthorization: true,
				cache: 0
			}),
			resultInformation: new Endpoint(titleResquest_api, 'External/Services/Preregister/Request/Documents/{executionId}/Response', {
				authorization: false,
				timbradosAuthorization: true,
				cache: 0
			}),
			saveRequest: new Endpoint(titleResquest_api, 'External/Services/Preregister/Save', {
				authorization: false,
				timbradosAuthorization: true,
				cache: 0
			}),
			searchRequest: new Endpoint(titleResquest_api, 'External/Services/Preregister/Requests', {
				authorization: false,
				timbradosAuthorization: true,
				cache: 0
			}),
			detailRequest: new Endpoint(titleResquest_api, 'External/Services/Preregister/Detail/{PreregisterId}', {
				authorization: false,
				timbradosAuthorization: true,
				cache: 0
			}),
			updateRequest: new Endpoint(titleResquest_api, 'External/Services/Preregister/Update', {
				authorization: false,
				timbradosAuthorization: true,
				cache: 0
			})
		};

		this.files = {
			saveExternalFile: new Endpoint(titleResquest_api, 'Files/Ext/Tit/Prg/{userId}', {
				authorization: false,
				timbradosAuthorization: true,
				cache: 0,
			})
		};

		this.reenrollment = {
			requestToken: new Endpoint(
				api,
				'Reenrollment/Enrollment/{moment}/Token/{profileId}',
				{ authorization: true, cache: 0 }
			),
			requestReportToken: new Endpoint(
				api,
				'Reenrollment/Report/Token',
				{ authorization: true, cache: 0 }
			),
			refreshToken: new Endpoint(
				reenrollment_api,
				'Auth/Token/Refresh',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			saveLogTerms: new Endpoint(
				reenrollment_api,
				'Student/SaveLogTerms',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			getGeneralData: new Endpoint(
				reenrollment_api,
				'Student/GeneralData/{sysAccount}',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			getLegends: new Endpoint(
				reenrollment_api,
				'Student/Legends',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			dataAvailability: new Endpoint(
				reenrollment_api,
				'Student/DataAvailability',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			reservationTemporary: new Endpoint(
				reenrollment_api,
				'Reservation/ReservationTemporary',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			cancelReservation: new Endpoint(
				reenrollment_api,
				'Reservation/CancelTemporary',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			paymentAlternatives: new Endpoint(
				reenrollment_api,
				'Payment/PaymentAlternatives',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			calculateTuition: new Endpoint(
				reenrollment_api,
				'Payment/CalculateTuition',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			initBankbridge: new Endpoint(
				reenrollment_api,
				'Payment/InitBankbridge',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			savePaymentData: new Endpoint(
				reenrollment_api,
				'Payment/SavePaymentData',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			getSchedulesData: new Endpoint(
				reenrollment_api,
				'Schedules/GetSchedules',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			sendEmailReenrollment: new Endpoint(
				reenrollment_api,
				'Schedules/SendMail',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			getListCampus: new Endpoint(
				reenrollment_api,
				'Reports/GetFacilities',
				{ authorization: false, cache: 0, reportReenrollmentAuthorization: true }
			),
			getListSchool: new Endpoint(
				reenrollment_api,
				'Reports/GetSchools',
				{ authorization: false, cache: 0, reportReenrollmentAuthorization: true }
			),
			getReportReenrollment: new Endpoint(
				reenrollment_api,
				'Reports/GetReports',
				{ authorization: false, cache: 0, reportReenrollmentAuthorization: true }
			),
			getFileReport: new Endpoint(
				reenrollment_api,
				'Reports/GetReportsDocument',
				{ authorization: false, cache: 0, reportReenrollmentAuthorization: true }
			),

			getReportInterquarterly: new Endpoint(
				reenrollment_api,
				'Reports/intercuatrimestral',
				{ authorization: false, cache: 0, reportReenrollmentAuthorization: true }
			),
			getFileInterquarterly: new Endpoint(
				reenrollment_api,
				'Reports/intercuatrimestral/file',
				{ authorization: false, cache: 0, reportReenrollmentAuthorization: true }
			),

			getBookOffer: new Endpoint(
				reenrollment_api,
				'Cemdi/BookOffer',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			buyBooks: new Endpoint(
				reenrollment_api,
				'Cemdi/BookOffer/Buy',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			buyBooksResult: new Endpoint(
				reenrollment_api,
				'Cemdi/BookOffer/Response/{sisaccount}/{requestId}',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			detailBuyBook: new Endpoint(
				reenrollment_api,
				'Cemdi/History/{SisAccount}/Proof/{ProofId}/Detail',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			detailBuyBookFile: new Endpoint(
				reenrollment_api,
				'Cemdi/History/{SisAccount}/Proof/{ProofId}/Detail/File',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			requestSchedule: new Endpoint(
				api,
				'Reenrollment/Schedule/Request/profileId/{profileId}',
				{ authorization: true, cache: 120, reenrollmentAuthorization: false }
			),
			responseSchedule: new Endpoint(
				api,
				'Reenrollment/Schedule/Response/{executionId}/profileId/{profileId}',
				{ authorization: true, cache: 0, reenrollmentAuthorization: false }
			),
			downloadSchedule: new Endpoint(
				api,
				'Reenrollment/Schedule/Response/{executionId}/profileId/{profileId}/selection/{id}',
				{ authorization: true, cache: 0, reenrollmentAuthorization: false }
			),



			getGeneralDataV2: new Endpoint(
				reenrollment_api,
				'Student/V2/GeneralData',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			dataAvailabilityV2: new Endpoint(
				reenrollment_api,
				'Student/V2/DataAvailability',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			saveLogTermsV2: new Endpoint(
				reenrollment_api,
				'Student/V2/SaveLogTerms',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),

			cancelReservationV2: new Endpoint(
				reenrollment_api,
				'Reservation/V2/CancelTemporary',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			paymentAlternativesV2: new Endpoint(
				reenrollment_api,
				'Payment/V2/PaymentAlternatives',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			calculateTuitionV2: new Endpoint(
				reenrollment_api,
				'Payment/V2/CalculateTuition',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			initBankbridgeV2: new Endpoint(
				reenrollment_api,
				'Payment/V2/InitBankbridge',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			confirmV2: new Endpoint(
				reenrollment_api,
				'Payment/V2/Confirm',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
			getScheduleV2: new Endpoint(
				reenrollment_api,
				'Schedules/File/{requestId}',
				{ authorization: false, cache: 0, reenrollmentAuthorization: true }
			),
		};

		this.carousel = {
			positiveBalance: new Endpoint(
				api,
				'request/PositiveBalance/{profileUserId}',
				{ authorization: true, cache: 0 }
			),
			debts: new Endpoint(api, 'request/Debts/{profileUserId}', {
				authorization: true,
				cache: 0,
			}),
			services: new Endpoint(api, 'request/Services/{profileUserId}', {
				authorization: true,
				cache: 0,
			}),
		};
	}
}


