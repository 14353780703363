import { createAction, props } from '@ngrx/store';
import { CampusData, MenuItemModel, MenuModel, MenuRolesModel } from '../interfaces/imenu-api-model';
import { IDictionary } from 'src/app/core/interfaces/collections/idictionary-model';
import { MenuAdminActionTypes } from '../interfaces/imenu-state';
import { ICareerData, ILevel } from '../../catalogs/interfaces/icareer-data';
import { IDownTypeModelView, IStudentStatusModelView } from '../../catalogs/interfaces/istatus';
import { CareerData, LevelData } from '../../catalogs/interfaces/ilevel-data';
import { IMinResponse } from 'src/app/core/http/interfaces/iresponse';

export const GET_CONFIG = createAction('[MenuAdmin] get menu config', props<{ payload: boolean }>());

export const SET_CONFIG_DATA = createAction('[MenuAdmin] set menu config data', props<{ payload: IMinResponse<MenuModel, MenuRolesModel> }>());

export const SET_CONFIG = createAction('[MenuAdmin] set menu config', props<{ payload: MenuModel }>());

export const SET_ROLES = createAction('[MenuAdmin] set menu roles', props<{ payload: MenuRolesModel }>());

export const SET_CURRENT = createAction('[MenuAdmin] set current', props<{ payload: MenuItemModel }>());

export const SET_ACTION = createAction('[MenuAdmin] set action', props<{ payload: MenuAdminActionTypes }>());

export const SET_CURRENT_ID = createAction('[MenuAdmin] set current id', props<{ payload: string }>());

export const SET_ADDITIONAL = createAction('[MenuAdmin] set additional', props<{ payload: IDictionary<string> }>());

export const GET_CAMPUS = createAction('[MenuAdmin] get campus cat', props<{ includeAll?: boolean; includePrincipal?: boolean; excludeOnline?: boolean; enableCache?: boolean }>());

export const GET_CAREERS = createAction('[MenuAdmin] get careers cat', props<{ campusId: string }>());

export const GET_LEVELS = createAction('[MenuAdmin] get levels cat', props<{ campusId: string }>());

export const GET_DOWN_TYPES = createAction('[MenuAdmin] get down types cat');

export const GET_STUDENT_STATUS = createAction('[MenuAdmin] get student status cat');

export const GET_CATALOGS = createAction('[MenuAdmin] get catalogs');

export const SET_CAMPUS = createAction('[MenuAdmin] set campus cat', props<{ campus: CampusData[] }>());

export const SET_CAREERS = createAction('[MenuAdmin] set careers cat', props<{ careers: CareerData[] }>());

export const SET_LEVELS = createAction('[MenuAdmin] set levels cat', props<{ levels: LevelData[] }>());

export const SET_DOWN_TYPES = createAction('[MenuAdmin] set down types cat', props<{ downTypes: IDownTypeModelView[] }>());

export const SET_STUDENT_STATUS = createAction('[MenuAdmin] set student status cat', props<{ studentStatus: IStudentStatusModelView[] }>());

export const RESET = createAction('[MenuAdmin] reset');


