// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.countdown {
  border: 1px solid #e3e3e3;
  border-radius: 10px;
}

.counter-text {
  font-size: 22px;
  font-weight: 700;
}

.viewFull {
  min-width: 250px;
  max-width: 325px;
  width: auto;
}

.expired-qr {
  filter: blur(8px);
}

.qr-expired-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  padding: 12px;
  background: #fff;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
}

.info-slider {
  max-width: 350px;
  margin: 2.5px auto auto;
}

.slide-waiting-text,
.slide-disclaimer-text {
  font-size: 15px;
  font-weight: 500;
}

.red-text-message {
  color: #951414;
}
.red-text-message.bold {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/payment/modules/codi/components/request-codi-payment/request-codi-payment.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,gBAAA;EACA,WAAA;AACJ;;AAEA;EAEI,iBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,UAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,uBAAA;AACJ;;AAEA;;EAEI,eAAA;EACA,gBAAA;AACJ;;AAEA;EACI,cAAA;AACJ;AAAI;EACI,iBAAA;AAER","sourcesContent":[".countdown {\r\n    border: 1px solid #e3e3e3;\r\n    border-radius: 10px;\r\n}\r\n\r\n.counter-text {\r\n    font-size: 22px;\r\n    font-weight: 700;\r\n}\r\n\r\n.viewFull {\r\n    min-width: 250px;\r\n    max-width: 325px;\r\n    width: auto;\r\n}\r\n\r\n.expired-qr {\r\n    -webkit-filter: blur(8px);\r\n    filter: blur(8px);\r\n}\r\n\r\n.qr-expired-text {\r\n    position: absolute;\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n    z-index: 2;\r\n    padding: 12px;\r\n    background: #fff;\r\n    border-radius: 10px;\r\n    font-size: 15px;\r\n    font-weight: 600;\r\n}\r\n\r\n.info-slider {\r\n    max-width: 350px;\r\n    margin: 2.5px auto auto;\r\n}\r\n\r\n.slide-waiting-text,\r\n.slide-disclaimer-text {\r\n    font-size: 15px;\r\n    font-weight: 500;\r\n}\r\n\r\n.red-text-message {\r\n    color: #951414;\r\n    &.bold {\r\n        font-weight: bold;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
