import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportReenrollmentFacade } from './facades/report-reenrollment-facade';
import { StoreModule } from '@ngrx/store';
import { ReportReenrollmentReducer } from './store/report-reenrollment.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('reportReenrollment', ReportReenrollmentReducer),
  ],
  declarations: [],
  providers: [
    ReportReenrollmentFacade
  ]
})
export class ReportReenrollmentStoreModule { }
