import { Component, OnInit } from '@angular/core';
import { AuthService, PROFILE_URL, REQUESTED_URL, REQUESTED_URL_QUERIES } from './core/session/services/auth/auth.service';
import { ConfigService } from './core/config/services/config.service';
import { UserFacade } from './core/redux/user/facades/user-facade';
import { Router } from '@angular/router';
import { ServerAvailability } from './core/session/services/server-availability/server-availability.service';
import { UserInitializer } from './core/bootstrap/user/user-initializer';
import { NavigationInitializer } from './core/bootstrap/navigation/navigation-initializer';
import { NotificationInitializer } from './core/bootstrap/notification/notification-initializer';

//import { PushNotificationService } from 'ngx-push-notifications';
import { TransactionInitializer } from './core/bootstrap/transaction/transaction-initializer';
import { ConfirmationTokenService } from './core/services/confirmation-token/confirmation-token.service';
import { ChatBotInitializer } from './core/bootstrap/chatbot/chat-bot-initializer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { filter } from 'rxjs';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, SilentRequest } from '@azure/msal-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from './core/services/message/message.service';
import { NotificationService } from './core/api/notification/notification.service';
import { AppFacade } from './core/redux/application/facades/app-facade';
import { UpdateService } from './core/services/update/update.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';
  interval;

  //MSAL IMPLEMENTATION
  isIframe = false;
  loginDisplay = false;
  adquireToken = true;
  private accountStatusCounter = 0;
  profile$ = this.userFacade.profile$;

  _requested_url = '';
  _requested_query = '';

  currentApplicationVersion = this.config.get('version');

  constructor(
    private config: ConfigService,
    private userBootstrap: UserInitializer,
    private userFacade: UserFacade,
    private navigationBootstrap: NavigationInitializer,
    private router: Router,
    private swUpdate: UpdateService,
    private server: ServerAvailability,
    private notificationBootstrap: NotificationInitializer,
    private appFacade: AppFacade,
    //private pushNotificationService: PushNotificationService,
    private transactionBootstrap: TransactionInitializer,
    private _confirmToken: ConfirmationTokenService,
    private chatBotBootstrap: ChatBotInitializer,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private notificationService: NotificationService
  ) {
    let requestedUrl = localStorage.getItem(REQUESTED_URL);
    this._requested_url = requestedUrl ? requestedUrl.replace(new RegExp('"', 'g'), '') : "";
    this._requested_query = localStorage.getItem(REQUESTED_URL_QUERIES);
    //console.log('AppComponent constructor router: ', this.router.url, this._requested_url, this._requested_query);
    this._confirmToken.confirmationTokenCallbackListener();
  }


  ngOnInit() {
    console.log(`AppVersion ${this.currentApplicationVersion}, commit ${this.config.get('commitHash')}, built at ${this.config.get('buildDate')}`);
    this.checkForUpdates();
    this.isIframe = window !== window.parent && !window.opener;

    this.authService.instance.enableAccountStorageEvents();
    //console.log('ngOnInit: ', this.isIframe);

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        //console.log('msalBroadcastService.msalSubject$: ', result);
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        untilDestroyed(this)
      )
      .subscribe((status) => {
        //console.log('msalBroadcastService.inProgress$...', status);
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    //console.log('setLoginDisplay: ', this.loginDisplay);
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */

    let activeAccount = this.authService.instance.getActiveAccount();
    //console.log('checkAndSetActiveAccount: ', this.accountStatusCounter, activeAccount);
    //console.log('',
    //  !activeAccount,
    //  this.authService.instance.getAllAccounts().length > 0
    //);

    this.accountStatusCounter++;
    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      this.initializeUser();
    } else {
      if (!activeAccount && this.accountStatusCounter <= 4) {
        setTimeout(() => this.checkAndSetActiveAccount(), 2500);
      }
      else {
        this.initializeUser();
      }
    }
  }

  initializeUser() {
    if (this.adquireToken) {
      this.spinner.show();
      this.adquireToken = false;
      let config = this.config.get('oauth2');
      let activeAccount = this.authService.instance.getActiveAccount();
      if (activeAccount) {
        if (activeAccount.idToken) {
          this.setAccountAndInit(activeAccount, activeAccount.idToken);
        } else {
          this.authService.instance
            .acquireTokenSilent(<SilentRequest>{
              account: activeAccount,
              scopes: [`${config.azure.clientId}/.default`],
            })
            .then((data: AuthenticationResult) => {
              if (data.idToken) {
                this.setAccountAndInit(data.account, data.idToken);
              }
            })
            .catch(() => {
              this.setAccountAndInit(null, null);
              this.router.navigate(['/']);
            });
        }
      } else {
        this.setAccountAndInit(null, null);
      }
    }
  }

  setAccountAndInit(account: AccountInfo | null, token: string | null) {
    if (!account) {
      this.spinner.hide();
      return;
    }

    this.userFacade.setAuthData(account.username);
    this.server.checkAvailability(account.username);
    this.boot();
    if (this._requested_url == '/login' || this._requested_url == '/' || !this._requested_url) {
      this._requested_url = PROFILE_URL;
    }
    this.profile$.subscribe(profile => this.navigateTo(this._requested_url));
  }

  boot() {
    this.autoRenewToken();
    this.userBootstrap.init();
    this.appFacade.setInitialized();
    this.appFacade.initNavigation();

    /**
     * Initializer depend of user profile
     */
    this.navigationBootstrap.init();
    this.notificationBootstrap.init();
    this.transactionBootstrap.init();
    this.chatBotBootstrap.init();
    this.appFacade.initIp();
  }

  navigateTo(requestedUrl: string) {
    let allowedFeatures: string[] = this.config.get('features.allowed');
    let navigate = true;
    let qObject = {};
    if (this._requested_query && this._requested_query != 'none') {
      //console.log('navigateTo: ', this._requested_query, encodeURIComponent(this._requested_query));
      navigate = false;
      for (let i = 0; i <= allowedFeatures.length; i++) {
        if (requestedUrl.includes(allowedFeatures[i])) {
          navigate = true;
          break;
        }
      }
      let queries = this._requested_query.split('?');
      for (let a = 0; a < queries.length; a++) {
        const qstring = queries[a];
        const qname = qstring.substring(0, qstring.indexOf('='));
        qObject[qname] = qstring.replace(qname, '').replace('=', '');
        //console.log('qname: ', qstring.replace(qname, ''));
      }
      //console.log('queries_splited: ', qObject);
    }
    if (navigate) {
      this.router.navigate([requestedUrl], { queryParams: qObject }).then(e => {
        if (e) {
          console.log('navigateTo e', e);
        } else {
          let requestedUrl = localStorage.getItem(REQUESTED_URL);
          //console.log('navigateTo requestedUrl: ', requestedUrl)
          if (requestedUrl)
            setTimeout(() => this.navigateTo(requestedUrl), 1000);
        }
      });
    } else {
      this.spinner.hide();
      this.messageService.info('Servicio no disponible por el momento', undefined, () => {
        window.close();
      });
      setTimeout(() => {
        window.close();
      }, 100);
    }

  }

  checkForUpdates() {
    this.config.loadVersion();
    this.swUpdate.checkForUpdates();
  }

  autoRenewToken() {
    let config = this.config.get('oauth2');
    let timeBeforeRefresh = Number(`${this.config.get('timeBeforeRefresh')}`);
    let activeAccount = this.authService.instance.getActiveAccount();
    let scopes = [`${config.azure.clientId}/.default`];
    this.authService.acquireTokenSilent({
      account: activeAccount,
      scopes: scopes,
    }).subscribe((result: AuthenticationResult) => {
      console.log('autoRenewToken: ', result);
      let milisecondsBeforeExpire = result.expiresOn.getTime() - (new Date().getTime());
      if (milisecondsBeforeExpire < (timeBeforeRefresh * 60000)) {
        this.renewToken(activeAccount, scopes);
      } else {
        console.log('Token will refresh in: ', (milisecondsBeforeExpire - ((timeBeforeRefresh - 1) * 60000)));
        setTimeout(() => {
          this.autoRenewToken();
        }, (milisecondsBeforeExpire - ((timeBeforeRefresh - 1) * 60000)));
      }
    });
  }

  renewToken(activeAccount: AccountInfo, scopes: string[]) {
    this.authService.acquireTokenSilent({
      account: activeAccount,
      scopes: scopes,
      forceRefresh: true
    }).subscribe({
      next: (resultRefreshed) => {
        this.autoRenewToken();
      }
    })
  }

  queryStringToObject(url: string) {
    return Object.fromEntries([...new URLSearchParams(url.split('?')[1])]);
  }

}

