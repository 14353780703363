import { IMenuAdminState } from "../interfaces/imenu-state";


export const MENU_ADMIN_STATE: IMenuAdminState = {
  config: null,
  roles: null,
  current: null,
  action: null,
  currentId: null,
  additional: null,
  campus: [],
  careers: [],
  levels: [],
  downTypes: [],
  studentStatus: []
};
