import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IMenuAdminState, MenuAdminActionTypes } from '../interfaces/imenu-state';
import { getState } from '../redux/menu-admin.selectors';
import { CampusData, MenuItemModel, MenuModel, MenuRolesModel } from '../interfaces/imenu-api-model';
import * as MENU_ADMIN_ACTIONS from '../redux/menu-admin.actions';
import { CareerData, LevelData } from '../../catalogs/interfaces/ilevel-data';
import { IDownTypeModelView, IStudentStatusModelView } from '../../catalogs/interfaces/istatus';

@Injectable()
export class MenuAdminFacade {

	private state$: Observable<IMenuAdminState> = this.store.select(getState);

	config$: Observable<MenuModel> = this.state$.pipe(select(state => state.config));

	current$: Observable<MenuItemModel> = this.state$.pipe(select(state => state.current));

	action$: Observable<MenuAdminActionTypes> = this.state$.pipe(select(state => state.action));

	currentId$: Observable<string> = this.state$.pipe(select(state => state.currentId));

	additional$: Observable<{ [key: string]: string }> = this.state$.pipe(select(state => state.additional));

	campuses$: Observable<CampusData[]> = this.state$.pipe(select(state => state.campus));

	careers$: Observable<CareerData[]> = this.state$.pipe(select(state => state.careers));

	levels$: Observable<LevelData[]> = this.state$.pipe(select(state => state.levels));

	downTypes$: Observable<IDownTypeModelView[]> = this.state$.pipe(select(state => state.downTypes));

	studentStatus$: Observable<IStudentStatusModelView[]> = this.state$.pipe(select(state => state.studentStatus));

	roles$: Observable<MenuRolesModel> = this.state$.pipe(select(state => state.roles));

	constructor(
		private store: Store<IMenuAdminState>
	) { }

	getConfig(fullcontrol: boolean = false) {
		this.store.dispatch(MENU_ADMIN_ACTIONS.GET_CONFIG({ payload: fullcontrol }));
	}

	setConfig(config: MenuModel) {
		this.store.dispatch(MENU_ADMIN_ACTIONS.SET_CONFIG({ payload: config }));
	}

	setCurrentId(id: string) {
		this.store.dispatch(MENU_ADMIN_ACTIONS.SET_CURRENT_ID({ payload: id }));
	}

	setCurrent(current: MenuItemModel) {
		this.store.dispatch(MENU_ADMIN_ACTIONS.SET_CURRENT({ payload: current }));
	}

	setAction(action: MenuAdminActionTypes) {
		this.store.dispatch(MENU_ADMIN_ACTIONS.SET_ACTION({ payload: action }));
	}

	setAdditional(additional: { [key: string]: string }) {
		this.store.dispatch(MENU_ADMIN_ACTIONS.SET_ADDITIONAL({ payload: additional }));
	}

	getCatalogs() {
		this.store.dispatch(MENU_ADMIN_ACTIONS.GET_CATALOGS());
	}

	reset() {
		this.store.dispatch(MENU_ADMIN_ACTIONS.RESET());
	}
}
