import { Injectable } from '@angular/core';
import { AppHttp } from '../../http/services/app-http/app-http.service';
import { EndpointsService } from '../../http/services/endpoints/endpoints.service';
import { Endpoint } from '../../http/models/endpoint/endpoint';
import { HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { ConfigService } from '../../config/services/config.service';
import { Observable, of } from 'rxjs';
import { IResponse } from '../../http/interfaces/iresponse';
import { IUserData } from '../../redux/user/interfaces/iuser-data';
import { UserFacade } from '../../redux/user/facades/user-facade';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  protected signUpEndpoint: Endpoint;
  protected sselEndpoint: Endpoint;
  protected profileEndpoint: Endpoint;
  protected profilePictureEndpoint: Endpoint;
  protected resetAADPassword: Endpoint;
  protected checkSessionEndpoint: Endpoint;
  protected getADGroupsEndpoint: Endpoint;

  constructor(
    private userFacade: UserFacade,
    private http: AppHttp,
    private endpoints: EndpointsService,
    private config: ConfigService
  ) {
    this.sselEndpoint = endpoints.account.sselSession;
    this.profileEndpoint = endpoints.account.userProfile;
    this.signUpEndpoint = endpoints.account.signUp;
    this.resetAADPassword = endpoints.account.resetAADPassword;
    this.checkSessionEndpoint = endpoints.account.checkSession;
    this.profilePictureEndpoint = endpoints.account.profilePictureEndpoint;
    this.getADGroupsEndpoint = endpoints.account.getADGroups;
  }

  signUp(token) {
    const params = new HttpParams().set('dataenc', token);
    return this.http.get<IResponse<any>>(this.signUpEndpoint, { params });
  }

  getSSELToken(userId: string) {
    this.sselEndpoint.useWildcardParams({ userId });
    return this.http.get<IResponse<any>>(this.sselEndpoint);
  }

  getProfile(id, handleError: boolean = true): Observable<IUserData> {
    this.profileEndpoint.useWildcardParams({ userId: `${id}` });
    return this.http.get<IResponse<IUserData>>(this.profileEndpoint)
      .pipe(map(response => response.data))
      .pipe(catchError(response => handleError
        ? this.http.handleError(response, 'Ocurrió un error al intentar cargar los datos del usuario')
        : throwError(response)
      ));
  }

  getProfilePicture(id): Observable<string> {
    this.profilePictureEndpoint.useWildcardParams({ userId: `${id}` });
    return this.http.get<IResponse<string>>(this.profilePictureEndpoint)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'Ocurrió un error al intentar cargar la imagen del usuario')
      ));
  }

  killSselSession(token) {
    const params = new HttpParams().set('dataenc', token);
    return this.http.get<IResponse<any>>(this.endpoints.account.killSession, { params });
  }

  requestMyUnitecAccountReset(data) {
    return this.http.post<IResponse<any>>(this.resetAADPassword, data);
  }

  getQueueStatus(id) {
    this.checkSessionEndpoint.useWildcardParams({ id });
    const fromString = this.config.get('functions.queue.signature', '');
    const params = new HttpParams({ fromString });
    //console.log('send signs of life');
    return this.http.get(this.checkSessionEndpoint, { params }).pipe(
      tap(response => {
        console.log('storing queue data');
        this.userFacade.setQueueData(response.data);
      }),
      catchError(() => {
        const data = {
          canEnter: true,
          ticket: 0
        };
        console.log('can not get queue data');
        this.userFacade.setQueueData(data);
        return of({ data });
      })
    );
  }

  getADGroups(): Observable<string[]> {
    return this.http.get(this.getADGroupsEndpoint)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudieron recuperar los grupos del usuario')
      ));
  }
}
