// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-wrapper {
  margin: auto;
  border: solid 1px gainsboro;
  padding: 5px;
  max-width: 35px;
  max-height: 35px;
  border-radius: 50%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: inline-block;
}
.avatar-wrapper.flat {
  padding: 0;
  border: none;
}
.avatar-wrapper .avatar {
  background: gainsboro;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.bold {
  font-weight: bold;
}

.fz-14 {
  font-size: 14px !important;
}

.fz-12 {
  font-size: 12px !important;
}

.fz-16 {
  font-size: 16px !important;
}

.rounded {
  border-radius: 7px !important;
}

.bg-light-accent {
  background-color: #e8f7ff !important;
}

::ng-deep.mat-drawer.miniSidenav::ng-deep .item-profile {
  display: none;
}

::ng-deep.mat-drawer.miniSidenav:hover::ng-deep .item-profile {
  display: flex;
}

.text-ellipsed {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/layouts/components/sidebar-profile/sidebar-profile.component.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,2BAAA;EACA,YAAA;EACA,eANU;EAOV,gBAPU;EAQV,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,qBAAA;AADF;AAGE;EACE,UAAA;EACA,YAAA;AADJ;AAIE;EACE,qBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAFJ;;AAMA;EACE,iBAAA;AAHF;;AAMA;EACE,0BAAA;AAHF;;AAMA;EACE,0BAAA;AAHF;;AAMA;EACE,0BAAA;AAHF;;AAMA;EACE,6BAAA;AAHF;;AAMA;EACE,oCAAA;AAHF;;AAOE;EACI,aAAA;AAJN;;AASE;EACI,aAAA;AANN;;AAUA;EACE,gBAAA;EACA,uBAAA;EACA,gBAAA;AAPF","sourcesContent":["$max_width: 35px;\r\n\r\n.avatar-wrapper {\r\n  margin: auto;\r\n  border: solid 1px gainsboro;\r\n  padding: 5px;\r\n  max-width: $max_width;\r\n  max-height: $max_width;\r\n  border-radius: 50%;\r\n  overflow: hidden;\r\n  width: 100%;\r\n  height: 100%;\r\n  display: inline-block;\r\n\r\n  &.flat {\r\n    padding: 0;\r\n    border: none;\r\n  }\r\n\r\n  .avatar {\r\n    background: gainsboro;\r\n    width: 100%;\r\n    height: 100%;\r\n    border-radius: 50%;\r\n  }\r\n}\r\n\r\n.bold {\r\n  font-weight: bold;\r\n}\r\n\r\n.fz-14 {\r\n  font-size: 14px !important;\r\n}\r\n\r\n.fz-12 {\r\n  font-size: 12px !important;\r\n}\r\n\r\n.fz-16 {\r\n  font-size: 16px !important;\r\n}\r\n\r\n.rounded {\r\n  border-radius: 7px !important;\r\n}\r\n\r\n.bg-light-accent {\r\n  background-color: #e8f7ff !important;\r\n}\r\n\r\n::ng-deep.mat-drawer.miniSidenav {\r\n  &::ng-deep .item-profile {\r\n      display: none;\r\n  }\r\n}\r\n\r\n::ng-deep.mat-drawer.miniSidenav:hover {\r\n  &::ng-deep .item-profile {\r\n      display: flex;\r\n  }\r\n}\r\n\r\n.text-ellipsed{\r\n  max-width: 150px;\r\n  text-overflow: ellipsis;\r\n  overflow: hidden;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
