import { Injectable } from "@angular/core";
import { AppHttp } from "../../http/services/app-http/app-http.service";
import { EndpointsService } from "../../http/services/endpoints/endpoints.service";
import { Observable } from "rxjs";
import { IValidationCampus } from "../../interfaces/ivalidation-campus";
import { map, catchError } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ActivityLogsService {
    constructor(
        private http: AppHttp,
        private endpoints: EndpointsService,
    ) {
    }

    activityLogs(orderId: any, ActivityKey: string): Observable<IValidationCampus> {
        this.endpoints.genericDocument.activityLogs.useWildcardParams({ orderId, ActivityKey });
        return this.http.post(this.endpoints.genericDocument.activityLogs, {})
            .pipe(map(response => response.data))
            .pipe(catchError(response => this.http.handleError(response, 'No se pudo guardar el log.')));
    }

    activityStart(profileUserId: string, document:string, activity: string): Observable<IValidationCampus> {
        this.endpoints.genericDocument.activityStart.useWildcardParams({ document: document, activity: activity });
        return this.http.post(this.endpoints.genericDocument.activityStart, {
            profileUserId: profileUserId
        })
        .pipe(map(response => response.data))
        .pipe(catchError(response => this.http.handleError(response, 'No se pudo guardar el log.')));
    }


}