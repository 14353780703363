import { Component, Inject, OnInit } from '@angular/core';
import { IMessageDialogData } from '../../../../core/interfaces/imessage-dialog-data';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent implements OnInit {

  showDetails: boolean = false;
  details: string[] = [];

  constructor(
    private dialog: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IMessageDialogData
  ) { }

  ngOnInit() {
    this.getDetailList();
    if (this.hasOpenedDisplay()) {
      this.toggleDetails();
    }
  }

  hasContinueAction() {
    return this.data.actions && this.data.actions.continueCallback;
  }

  hasCancelAction() {
    return this.data.actions && this.data.actions.cancelCallback;
  }

  hasCloseButton() {
    if (this.data.labels) {
      return !!this.data.labels.hasClose;
    }
    return false;
  }

  hasOpenedDisplay() {
    return !!this.data.detailsOpened;
  }

  handleContinue() {
    if (this.hasContinueAction()) {
      this.data.actions.continueCallback();
    }
    this.close();
  }

  handleCancel() {
    if (this.hasCancelAction()) {
      this.data.actions.cancelCallback();
    }
    this.close();
  }

  handleClose() {
    this.close();
  }

  toggleDetails() {
    this.showDetails = !this.showDetails;
  }

  getDetailList() {
    if (this.data.details) {
      Object.keys(this.data.details)
        .forEach(key => {
          this.details = [...this.details, ...this.data.details[key]];
        });
    }
  }

  close() {
    this.dialog.close();
  }
}
