import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { ConfigService } from "../../config/services/config.service";
import { loggerCallback } from "../logger/logger";
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";
import { REQUESTED_URL } from "../../session/services/auth/auth.service";

const isIE =
    window.navigator.userAgent.indexOf('MSIE ') > -1 ||
    window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

export function MSALInstanceFactory(configService: ConfigService): IPublicClientApplication {
    const config = configService.get('oauth2');
    return new PublicClientApplication({
        auth: {
            clientId: config.azure.clientId,
            authority: "https://login.microsoftonline.com/common",
            redirectUri: window.location.origin,
            postLogoutRedirectUri: window.location.origin
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
        },
        system: {
            allowNativeBroker: false,
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Warning,
                piiLoggingEnabled: false,
            },
        },
    });
}

export function MSALInterceptorConfigFactory(configService: ConfigService): MsalInterceptorConfiguration {
    let config = configService.get('oauth2');

    const protectedResourceMap = new Map<string, Array<string> | null>([
        [`${configService.get('api.url')}accounts/ResetAADPassword`, null],
        [`${configService.get('api.url')}SupportingDocument/PublicOrderDetail`, null],
        [`${configService.get('api.url')}Document/qr/transaction`, null],
        [configService.get('api.url').replace('/api/', ''), [`${config.azure.clientId}/.default`]],
        [config.apiConfig.uri, config.apiConfig.scopes]
    ]);
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
        authRequest: {
            state: localStorage.getItem(REQUESTED_URL),
            prompt: 'select_account'
        }
    };
}

export function MSALGuardConfigFactory(configService: ConfigService): MsalGuardConfiguration {
    let config = configService.get('oauth2');
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: [`${config.azure.clientId}/.default`],
            state: localStorage.getItem(REQUESTED_URL),
            prompt: 'select_account'
        }
    };
}
