import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { SchoolService } from "../../../../core/api/catalogs/school/school.service";
import { StudentService } from "../../../../core/api/user/student/student.service";
import { MessageService } from "../../../../core/services/message/message.service";
import { IStudentData, IStudentFilters } from "../../../../core/redux/user/interfaces/istudent-data";
import { ICampusData } from "../../../../core/interfaces/catalogues/icampus-data";
import { ISchoolData } from "../../../../core/interfaces/catalogues/ischool-data";
import { ICareerData } from "../../../../core/interfaces/catalogues/icareer-data";
import { IPagination } from "../../../../core/http/interfaces/ipagination";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { MatPaginator } from "@angular/material/paginator";
import { MatSelectChange } from "@angular/material/select";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CampusService } from "src/app/core/api/catalogs/campus/campus.service";
import { CareerService } from "src/app/core/api/catalogs/career/career.service";

@UntilDestroy()
@Component({
	selector: "app-search-student-form",
	templateUrl: "./search-student-form.component.html",
	styleUrls: ["./search-student-form.component.scss"],
})
export class SearchStudentFormComponent implements OnInit, OnDestroy {
	private destroyed$: Subject<{}> = new Subject();

	searchForm: FormGroup;

	campusList: ICampusData[] = [];

	schoolList: ISchoolData[] = [];

	careerList: ICareerData[] = [];

	@Output("result") result: EventEmitter<IStudentData[]> = new EventEmitter<IStudentData[]>();
	@Input("paginator") paginator: MatPaginator;
	@Input("filtrable") filtrable: boolean = true;
	@Input("initialLoad") initialLoad: boolean = true;
	constructor(private formBuilder: FormBuilder, private campusService: CampusService, private careerService: CareerService, private schoolService: SchoolService, private subjectsService: StudentService, private messenger: MessageService) {}

	ngOnInit() {
		this.initSearchForm();
		if (this.filtrable) {
			this.fetchCampusList();
			this.onPaginatorChanges();
		}
		if (this.initialLoad) this.submit();
	}

	ngOnDestroy() {
		this.destroyed$.next({});
	}

	initSearchForm() {
		this.searchForm = this.formBuilder.group({
			sisAccount: new FormControl(null, [Validators.minLength(8), Validators.maxLength(9)]),
			textFilter: new FormControl(null, [Validators.minLength(4)]),
			campus: new FormControl(null, []),
			school: new FormControl(null, []),
			career: new FormControl(null, []),
		});
	}

	fetchCampusList() {
		this.campusService
			.getCampusList()
			.pipe(untilDestroyed(this))
			.subscribe(
				(list) => (this.campusList = list),
				(response) => this.handleFetchError(response, "Ocurrió un error al intentar cargar la lista de campus.")
			);
	}

	fetchCareerList(campusId: string) {
		this.careerService
			.getCareerList(campusId)
			.pipe(untilDestroyed(this))
			.subscribe(
				(list) => (this.careerList = list),
				(response) => this.handleFetchError(response, "Ocurrió un error al intentar cargar la lista de carreras.")
			);
	}

	fetchSchoolList(campusId: string) {
		this.schoolService
			.getSchoolList(campusId)
			.pipe(untilDestroyed(this))
			.subscribe(
				(list) => (this.schoolList = list),
				(response) => this.handleFetchError(response, "Ocurrió un error al intentar cargar la lista de escuelas.")
			);
	}

	fetchSubjectList(query: IStudentFilters) {
		this.subjectsService
			.getStudentList(query)
			.pipe(untilDestroyed(this))
			.subscribe(
				(response) => {
					this.result.emit(response.data);
					this.updatePaginator(response.additionalData);
				},
				(response) => /*this.handleFetchError(response, 'Ocurrió un error al intentar cargar los datos.')*/ this.manageError(response)
			);
	}

	manageError(response) {
		//console.log('manageError: ', response);
		if (response.status == 403) {
			this.handleFetchError(response, "No estas autorizado.");
		} else {
			this.handleFetchError(response, "Ocurrió un error al intentar cargar los datos.");
		}
	}

	handleFetchError(response, defaultMessage: string) {
		response.error && response.error.message ? this.messenger.error(response.error.message, response.error.error) : this.messenger.error(defaultMessage);
	}

	clearFilters() {
		this.searchForm.reset();
		this.resetPaginator();
		this.submit(this.searchForm.getRawValue());
	}

	submit(query?: IStudentFilters) {
		this.fetchSubjectList({ ...this.searchForm.getRawValue(), ...query });
	}

	onSubmit() {
		if (this.searchForm.invalid) {
			return false;
		}
		this.resetPaginator();
		this.submit(this.searchForm.getRawValue());
	}

	onSelectCampus(event: MatSelectChange) {
		this.fetchCareerList(event.value);
		this.fetchSchoolList(event.value);
	}

	resetPaginator() {
		if (this.paginator) {
			this.paginator.firstPage();
		}
	}

	updatePaginator(pagination: IPagination) {
		if (this.paginator) {
			this.paginator.length = pagination.total;
			this.paginator.pageSize = pagination.take;
		}
	}

	onPaginatorChanges() {
		if (this.paginator) {
			this.paginator.page.pipe(untilDestroyed(this)).subscribe((page) => {
				const skip = page.pageIndex * page.pageSize;
				const take = page.pageSize;
				this.submit({ skip, take });
			});
		}
	}
}
