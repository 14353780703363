import { createAction, props } from '@ngrx/store';
import { INewMenuItemModel } from '../interfaces/imenu-config-model';
import { ICatData } from '../interfaces/imenu-admin.state';
import { IDownTypeModelView, IStudentStatusModelView } from 'src/app/core/interfaces/catalogues/istatus';

const SET_FULL_CONTROL = createAction('[MENU_ADMIN] set fullcontrol', props<{ payload: boolean }>());

const SET_ACTIVE = createAction('[MENU_ADMIN] set session active', props<{ payload: boolean }>());

const SET_CURRENT = createAction('[MENU_ADMIN] set current item menu', props<{ payload: INewMenuItemModel | undefined }>());

const SET_CAT_DATA = createAction('[MENU_ADMIN] set campus cat data', props<{ payload: ICatData }>());

const SET_DOWN_TYPES = createAction('[MENU_ADMIN] set down types data', props<{ payload: IDownTypeModelView[] }>());

const SET_STUDENT_STATUS = createAction('[MENU_ADMIN] set student status data', props<{ payload: IStudentStatusModelView[] }>());

export { SET_FULL_CONTROL, SET_ACTIVE, SET_CURRENT, SET_CAT_DATA, SET_DOWN_TYPES, SET_STUDENT_STATUS }