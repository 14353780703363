import { Injectable } from "@angular/core";
import { AppHttp } from "../../../../http/services/app-http/app-http.service";
import { EndpointsService } from "../../../../http/services/endpoints/endpoints.service";
import { Endpoint } from "../../../../http/models/endpoint/endpoint";
import { IResponse } from "../../../../http/interfaces/iresponse";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { IStudentStatusModelView, IDownTypeModelView } from "../../interfaces/istatus";

@Injectable({
	providedIn: "root",
})
export class StatusService {

	statusEnpoint: {
		getStudentStatus: Endpoint,
		getDownTypes: Endpoint,
	};

	constructor(private http: AppHttp, private endpoints: EndpointsService) {
		this.statusEnpoint = this.endpoints.catalogs;
	}

	getStudentStatus(): Observable<IStudentStatusModelView[]> {
		return this.http.get<IResponse<IStudentStatusModelView>>(this.statusEnpoint.getStudentStatus).pipe(map((response) => response.data));
	}

	getDownTypes(): Observable<IDownTypeModelView[]> {
		return this.http.get<IResponse<IDownTypeModelView[]>>(this.statusEnpoint.getDownTypes).pipe(map((response) => response.data));
	}
}
