import { createAction, props } from '@ngrx/store';
import { IStudentData } from 'src/app/core/redux/user/interfaces/istudent-data';

const SELECT_STUDENT = createAction('[CREATE TUTOR] select student', props<{ payload: IStudentData }>());

const SET_CURRENT_STEP = createAction('[CREATE TUTOR] set step', props<{ payload: number }>());

const SELECT_TUTOR = createAction('[CREATE TUTOR] select tutor', props<{ payload: any }>());

export { SELECT_STUDENT, SET_CURRENT_STEP, SELECT_TUTOR }
