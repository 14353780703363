// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep button#openFile {
  display: none !important;
  visibility: hidden !important;
}`, "",{"version":3,"sources":["webpack://./src/app/ui/document-viewer/pdf-viewer/pdf-viewer.component.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;EACA,6BAAA;AACJ","sourcesContent":["::ng-deep button#openFile {\r\n    display: none !important;\r\n    visibility: hidden !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
