// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: inline-block;
  width: 100%;
}
:host:not(.file-input-disabled) {
  cursor: pointer;
}

input {
  width: 0px;
  height: 0px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.filename {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/app/ui/ngx-material-file-input/file-input/file-input.component.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,WAAW;AACb;AACA;EACE,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,UAAU;EACV,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,uBAAuB;EACvB,gBAAgB;EAChB,WAAW;AACb","sourcesContent":[":host {\r\n  display: inline-block;\r\n  width: 100%;\r\n}\r\n:host:not(.file-input-disabled) {\r\n  cursor: pointer;\r\n}\r\n\r\ninput {\r\n  width: 0px;\r\n  height: 0px;\r\n  opacity: 0;\r\n  overflow: hidden;\r\n  position: absolute;\r\n  z-index: -1;\r\n}\r\n\r\n.filename {\r\n  display: inline-block;\r\n  text-overflow: ellipsis;\r\n  overflow: hidden;\r\n  width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
