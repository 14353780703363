// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .mat-card-header-text {
  width: 100%;
  text-align: center;
}

.panel {
  padding: 15px;
}

.margin-content {
  margin-top: 30px !important;
  margin-bottom: 30px;
}

.label-price {
  font-size: 50px;
  font-family: monospace;
}

.label-amount {
  margin-top: 40px;
}

.total {
  padding: 9px !important;
  background-color: #f44336;
  color: white;
  border-radius: 5px;
}

.summary-payment {
  background-color: #f1f1f1;
}

button {
  margin: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/payment/pay/components/positive-balance/positive-balance.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,kBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,2BAAA;EACA,mBAAA;AACJ;;AAEA;EACI,eAAA;EACA,sBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,uBAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,YAAA;AACJ","sourcesContent":["::ng-deep .mat-card-header-text {\r\n    width: 100%;\r\n    text-align: center; \r\n}\r\n\r\n.panel{\r\n    padding: 15px;\r\n}\r\n\r\n.margin-content{\r\n    margin-top: 30px !important;\r\n    margin-bottom: 30px;\r\n}\r\n\r\n.label-price{\r\n    font-size: 50px;\r\n    font-family: monospace;\r\n}\r\n\r\n.label-amount{\r\n    margin-top: 40px;\r\n}\r\n\r\n.total{\r\n    padding:9px !important;\r\n    background-color: #f44336;\r\n    color: white;\r\n    border-radius: 5px;\r\n}\r\n\r\n.summary-payment{\r\n    background-color:#f1f1f1;\r\n}\r\n  \r\nbutton{\r\n    margin: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
