import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { PaymentDataService } from '../../../../../../core/api/payment/payment-data.service';
import { MessageService } from 'src/app/core/services/message/message.service';
import { Subscription, Observable, timer, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IDataEventPayment, IEventPyment } from '../../interfaces/ievent-payment';
import { PAYMENT_CHANNELS_CAT } from 'src/app/modules/payment/catalogs/payment-channels';
declare var Checkout: any;
declare var banamexHcGlobal: any;
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseComponent } from 'src/app/shared/layouts/components/base-component/base-component.component';

@UntilDestroy()
@Component({
  selector: 'app-request-bank-payment',
  templateUrl: './request-bank-payment.component.html',
  styleUrls: ['./request-bank-payment.component.scss']
})
export class RequestBankPaymentComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input('paymentId') paymentId?: any;
  @Output() complete: EventEmitter<IEventPyment> = new EventEmitter();
  Checkout = Checkout;
  banamexHcGlobal = banamexHcGlobal;
  isEventEmitted = false;

  paymentData: any;
  localSuscription: Subscription;
  constructor(
    private paymentDataService: PaymentDataService,
    private messageService: MessageService
  ) {
    super();
    this.banamexHcGlobal.addEventListener('onComplete', this.completeCallback);
    this.banamexHcGlobal.addEventListener('onCancel', this.cancelCallback);
    this.banamexHcGlobal.addEventListener('onError', this.errorCallback);
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    if (this.localSuscription) {
      this.localSuscription.unsubscribe();
      this.clearLocalStorage();
    }
  }

  init() {
    this.clearLocalStorage();
    if (this.paymentId == null || typeof this.paymentId === 'undefined') {
      this.complete.emit({ success: false, event: 'error', data: null , paymentMethode: PAYMENT_CHANNELS_CAT.codi});
      return;
    }
    this.paymentDataService.get(this.paymentId).pipe(untilDestroyed(this)).subscribe(data => {
      this.paymentData = data;
      this.continueWithPayment();
    }, error => {
      this.messageService.error(`No fué posible obtener respuesta del servidor de pagos
      <br> Por favor intente mas tarde.`, { error }, () => {
          this.complete.emit({
            success: false,
            data: null,
            event: 'error',
            additionaleData: error,
            paymentMethode: PAYMENT_CHANNELS_CAT.codi
          });
        });
    });
  }

  clearLocalStorage() {
    this.isEventEmitted = false;
    if (window.localStorage.getItem('bank_operation') != null && typeof window.localStorage.getItem('bank_operation') !== 'undefined') {
      window.localStorage.removeItem('bank_operation');
    }
  }

  continueWithPayment() {
    Checkout.configure({
      merchant: this.paymentData.Merchant,
      session: {
        id: this.paymentData.SessionId,
        version: this.paymentData.SessionVersion
      }
    });

    this.pay();
    this.checkLocalStorage();
  }

  completeCallback(resultIndicator, sessionVersion) {
    if (window.localStorage.getItem('bank_operation') != null && typeof window.localStorage.getItem('bank_operation') !== 'undefined') {
      window.localStorage.removeItem('bank_operation');
    }
    const onComplete: IEventPyment = { success: true, event: 'complete',
    data: { resultIndicator: resultIndicator, sessionVersion: sessionVersion }, paymentMethode: PAYMENT_CHANNELS_CAT.online };
    window.localStorage.setItem('bank_operation', JSON.stringify(onComplete));
  }

  cancelCallback() {
    if (window.localStorage.getItem('bank_operation') != null && typeof window.localStorage.getItem('bank_operation') !== 'undefined') {
      window.localStorage.removeItem('bank_operation');
    }
    const onCancel: IEventPyment = { success: false, event: 'cancel',
    data: { resultIndicator: null, sessionVersion: null }, paymentMethode: PAYMENT_CHANNELS_CAT.online };
    window.localStorage.setItem('bank_operation', JSON.stringify(onCancel));
  }

  errorCallback(error) {
    const ret = typeof (error) === 'string' ? error : JSON.stringify(error);
    if (window.localStorage.getItem('bank_operation') != null && typeof window.localStorage.getItem('bank_operation') !== 'undefined') {
      window.localStorage.removeItem('bank_operation');
    }
    const onError: IEventPyment = { success: false, event: 'error',
    data: { resultIndicator: null, sessionVersion: ret }, paymentMethode: PAYMENT_CHANNELS_CAT.online };
    window.localStorage.setItem('bank_operation', JSON.stringify(onError));
  }

  pay() {
    Checkout.showLightbox();
  }

  checkLocalStorage() {
    this.localSuscription = timer(0, 1000 * 1).pipe(
      switchMap(() => this.localSuscriber())).pipe(untilDestroyed(this)).subscribe(data => {
        if (data != null && typeof data !== 'undefined') {
          this.emitEvent(JSON.parse(data));
        }
      });
  }

  emitEvent(event: IEventPyment) {
    if (!this.isEventEmitted) {
      this.isEventEmitted = true;
      if (this.localSuscription) {
        this.localSuscription.unsubscribe();
        this.clearLocalStorage();
      }
      if (event) {
        this.paymentDataService.save(this.paymentId, (event.data as IDataEventPayment).resultIndicator, event.event)
        .pipe(untilDestroyed(this)).subscribe(data => {
          this.complete.emit(event);
        });
      }
    }
  }

  localSuscriber(): Observable<any> {
    return of(window.localStorage.getItem('bank_operation'));
  }

}
