import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReenrollmentFacade } from './facades/reenrollment-facade';
import { StoreModule } from '@ngrx/store';
import { ReenrollmentReducer } from './store/reenrollment.reducer';


@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('reenrollment', ReenrollmentReducer),
  ],
  declarations: [],
  providers: [
    ReenrollmentFacade
  ]
})
export class ReenrollmentStoreModule { }
