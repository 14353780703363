import { Injectable } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { interval } from 'rxjs';
import { MessageService } from '../message/message.service';

@Injectable({
    providedIn: 'root'
})
export class UpdateService {

    constructor(
        public updates: SwUpdate,
        private messageService: MessageService
    ) {
        if (updates.isEnabled) {
            interval(6 * 60 * 60).subscribe(() => updates.checkForUpdate()
                .then(() => console.log('checking for updates')));
        }
    }

    public checkForUpdates(): void {
        this.updates.versionUpdates.subscribe((event: VersionEvent) => {
            console.log('checkForUpdates', event);
            if (event.type == 'VERSION_DETECTED') {
                this.promptUser();
            }
        });
    }

    private promptUser(): void {
        this.messageService.info('Se ha encontrado una nueva version de la Ventanilla en línea, se actualizará la página para su instalación', {error: ['Service Worker'] }, () => {
            this.updates.activateUpdate().then(() => document.location.reload());
        });
    }
}
