import { Component, Input, OnInit } from '@angular/core';
import { INotificationData, NotificationTypes } from '../../../../core/redux/notification/interfaces/inotification-data';
import { NotificationFacade } from '../../../../core/redux/notification/facades/notification-facade';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  isCollapsed: boolean = true;
  @Input('notification') notification: INotificationData;
  constructor(
    private notificationFacade: NotificationFacade,
  ) { }

  ngOnInit() {
  }

  toggle(event: Event) {
    event.stopPropagation();
    this.isCollapsed = !this.isCollapsed;
  }

  remove() {
    this.notificationFacade.remove(this.notification);
  }

  get type() {
    let typeDesc = '';
    switch (this.notification.type) {
      case NotificationTypes.success:
        typeDesc = 'Finalizado exitosamente';
        break;
      case NotificationTypes.error:
        typeDesc = 'Finalizado con errores';
        break;
      case NotificationTypes.info:
        typeDesc = 'Finalizado correctamente';
        break;
      case NotificationTypes.warning:
        typeDesc = 'Finalizado con mensajes';
        break;
      default:
        typeDesc = 'Finalizado';
        break;
    }
    return typeDesc;
  }
}
