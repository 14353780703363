import { createAction, props } from '@ngrx/store';
import { IDocumentState, IMetadataDoc, IRegulationState, IDocumentsState, ISetSessionActive, ISetDocumentId, ISetDocumentType } from '../interfaces/idocument-status';

const SET_DOCUMENT = createAction('[DOCUMENT] set init Document data', props<{ payload: IDocumentState }>());

const SET_METADATA = createAction('[DOCUMENT] set metada Document data', props<{ payload: IMetadataDoc }>());

const SET_DOCUMENT_ID = createAction('[DOCUMENT] set Id Document data', props<{ payload: ISetDocumentId }>());

const SET_DOCUMENT_TYPE = createAction('[DOCUMENT] set Type Document data', props<{ payload: ISetDocumentType }>());

const SET_DOCUMENT_REGULATION = createAction('[DOCUMENT] add Document regulation data', props<{ payload: IRegulationState }>());

const SET_DOCUMENTS = createAction('[DOCUMENT] set documents of document', props<{ payload: IDocumentsState[] }>());

const SET_SESSION = createAction('[DOCUMENT] set session of document', props<{ payload: ISetSessionActive }>());

const REMOVE_DOCUMENT = createAction('[DOCUMENT] remove document data', props<{ payload: any }>());

export { SET_DOCUMENT, SET_METADATA, SET_DOCUMENT_ID, SET_DOCUMENT_TYPE, SET_DOCUMENT_REGULATION, SET_DOCUMENTS, SET_SESSION, REMOVE_DOCUMENT}
