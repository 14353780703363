import { ComponentFactoryResolver, Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IApplicationState } from '../interfaces/application.state';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { getState } from '../store/application.selectors';
import * as APPLICATION_ACTIONS from '../store/application.actions';
import { ConfigService } from '../../../config/services/config.service';

@Injectable()
export class AppFacade implements OnDestroy {

  state$: Observable<IApplicationState> = this.store.select(getState).pipe(filter(state => !!state));
  initialized$: Observable<boolean> = this.state$.pipe(select(state => state.init));
  title$: Observable<string> = this.state$.pipe(select(state => state.title));

  intervalTimer;
  localIPs: any = {};

  constructor(
    private store: Store<IApplicationState>,
    private navigationSevice: NavigationService,
    private configService: ConfigService
  ) { }

  ngOnDestroy() {
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }
  }

  setInitialized() {
    //console.log('initializing...');
    this.store.dispatch(APPLICATION_ACTIONS.INIT());
  }

  setTitle(title: string) {
    this.store.dispatch(APPLICATION_ACTIONS.SET_TITLE({ title: title }));
  }

  initNavigation() {
    if (!this.navigationSevice.isInitialized()) {
      this.navigationSevice.loadRouting();
    }
  }

  initIp() {
    setTimeout(() => {
      this.saveAppIp();
      this.getIp();
    }, 5000);
  }

  getIp() {
    setInterval(() => {
      this.configService.loadIP(this.configService.get('ip.url'), 0, this.saveAppIp());
    }, 5 * 60 * 1000);
  }

  saveAppIp() {
    const currentIp = window.localStorage.getItem('__current_ip');
    if (!!currentIp) {
      this.store.dispatch(APPLICATION_ACTIONS.SET_IP({ ipInfo: JSON.parse(currentIp) }));
    }
  }

}
