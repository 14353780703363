import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subject, Subscription } from 'rxjs';
import { NotificationFacade } from 'src/app/core/redux/notification/facades/notification-facade';
import { INotificationData } from 'src/app/core/redux/notification/interfaces/inotification-data';
import { UserFacade } from 'src/app/core/redux/user/facades/user-facade';
import { IUserData } from 'src/app/core/redux/user/interfaces/iuser-data';
import { IEventPyment } from 'src/app/modules/payment/modules/bank-bridge/interfaces/ievent-payment';
import { IPaymentCodi } from 'src/app/modules/payment/interfaces/ipayment-codi';
import { qrImage } from '../../data/image';
import { takeUntil } from 'rxjs/operators';
import { MessageService } from 'src/app/core/services/message/message.service';
import { PaymentService } from 'src/app/core/api/payment/payment.service';
import { PAYMENT_CHANNELS_CAT } from 'src/app/modules/payment/catalogs/payment-channels';
import { IMinResponse } from 'src/app/core/http/interfaces/iresponse';
import { Guid } from "guid-typescript";

export interface ITimerViewer {
  hours: string;
  minutes: string;
  seconds: string;
}

export interface INotificationCodiData {
  codiId: string;
  request: string;
  additionalData: string;
}import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { default_responsiveOptions } from 'src/app/modules/auth/components/slider/slider.component';

@UntilDestroy()
@Component({
  selector: 'app-request-codi-payment',
  templateUrl: './request-codi-payment.component.html',
  styleUrls: ['./request-codi-payment.component.scss']
})
export class RequestCodiPaymentComponent implements OnInit, OnDestroy {

  @Input('paymentInfo') paymentInfo?: IPaymentCodi;
  @Input('amount') amount?: string = '8000';
  @Input('paymentRequestId') paymentRequestId: string;
  @Output() complete: EventEmitter<IEventPyment> = new EventEmitter();

  timer: ITimerViewer = { minutes: '0', seconds: '0' } as ITimerViewer;
  counterInterval: NodeJS.Timeout;

  image = qrImage;
  isExpired = true;

  displayCodi = false;
  fatalError = false;

  notificationSubscriptor: Subscription = null;
  codiNotifications$: Observable<INotificationData> = null;
  timeOut: NodeJS.Timeout = null;
  private destroyed$: Subject<{}> = new Subject();

  profile: IUserData;

  isCodiNotificationHere = false;
  codiRequestId: string;

  @ViewChild('slickModal', { static: false }) slick: any;

  slideInfoConfig: {
    slidesToShow: 1,
    slideToScroll: 1,
    autoPlay: true,
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
  };

  responsiveOptions = default_responsiveOptions;

  constructor(
    private domSanitizer: DomSanitizer,
    private userFacade: UserFacade,
    private notificationFacade: NotificationFacade,
    private messageService: MessageService,
    private paymentService: PaymentService,
  ) {
    this.codiNotifications$ = this.notificationFacade.getLastNotificationFor('payment.codi.notification');
    this.userFacade.profile$.pipe(untilDestroyed(this)).subscribe(profile => this.profile = profile);
  }

  ngOnInit(): void {
    console.log('RequestCodiPaymentComponent paymentInfo: ', this.paymentInfo);
    if (this.paymentInfo == null || typeof this.paymentInfo === 'undefined') {
      this.complete.emit({ success: false, event: 'error', data: null, paymentMethode: PAYMENT_CHANNELS_CAT.codi });
      return;
    }
    this.initCounter(this.paymentInfo.qrExpirationTimeMinutes);
    this.displayCodi = true;
    let counterTime = (this.paymentInfo.qrExpirationTimeMinutes / 2) * 60000;
    this.timeOut = setTimeout(() => this.initRequestHandler(), counterTime);
    this.handleNotifications(this.codiNotifications$, "notificationCompleted");
  }

  ngOnDestroy(): void {
    this.onDestroying();
  }

  notificationCompleted(notificationData: INotificationCodiData) {
    this.isCodiNotificationHere = true;
    const onComplete: IEventPyment = {
      success: true, event: 'complete',
      data: <IMinResponse<any, any>>{ data: notificationData.request, additionalData: notificationData.additionalData },
      paymentMethode: PAYMENT_CHANNELS_CAT.codi
    };
    this.complete.emit(onComplete);
  }

  initRequestHandler() {
    this.handleRequest('isCodiNotificationHere', this.paymentRequestId, this.profile.id, 'notificationCompleted');
    return;
  }

  handleNotifications(notificationObserver$: Observable<INotificationData>, callback: any) {
    this.handleNotification('isCodiNotificationHere', notificationObserver$, `${this.paymentInfo.apiCoDiId}`, callback, 'Ocurrio un error en la notificación de pago');
  }

  handleNotification(flag: string, notificationObserver$: Observable<INotificationData>, requestId: string, cbFname: any, errorMsg: string = '') {
    this.notificationSubscriptor = notificationObserver$
      .pipe(untilDestroyed(this)).subscribe((notification: INotificationData) => {
        if (!this[flag]) {
          if (notification != null && typeof notification !== 'undefined') {
            if (`${notification.data.channelId}` === `${requestId}`) {
              if (notification.type == 'success') {
                this[cbFname]({
                  codiId: this.paymentInfo.apiCoDiId,
                  request: notification.data.request,
                  additionalData: notification.data
                });
              }
              else if (notification.type == 'error') {
                this.messageService.error(errorMsg, { details: [notification.message] });
                this[flag] = true;
                this.fatalError = true;
                this.onDestroying();
              }
            }
          }
        }
      });
  }

  handleRequest(flag: string, requestId: string, profileId: string, cbFname: string) {
    if (!this[flag]) {
      let isSuccess = true;
      this.paymentService.requestPaymentChannelStatus(requestId, profileId, `${this.paymentInfo.apiCoDiId}`, 'codi')
        .pipe(takeUntil(this.destroyed$))
        .subscribe((data: IMinResponse<any>) => {
          isSuccess = false;
          if (data && data.data) {
            if (Guid.isGuid(data.data) && !Guid.parse(data.data).isEmpty()) {
              this[cbFname]({
                codiId: this.paymentInfo.apiCoDiId,
                request: data.data,
                additionalData: data.additionalData
              });
            }
          }
        }, (error: any) => {
          isSuccess = false;
        },
          () => {
            if (!isSuccess)
              this.timeOut = setTimeout(() => this.handleRequest(flag, requestId, profileId, cbFname), 30000);
          });
    }
  }

  private onDestroying() {
    this.destroyed$.next({});
    this.clearTimeout();
  }

  private clearTimeout() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
  }

  backToPaymentItems() {
    this.complete.emit({ success: false, event: 'error', data: null, paymentMethode: PAYMENT_CHANNELS_CAT.codi });
  }

  initCounter(minutes: number = 5) {
    this.isExpired = false;
    let countDownDate = new Date().getTime() + (1000 * 60 * minutes);
    this.counterInterval = setInterval(() => {

      let now = new Date().getTime();
      let distance = countDownDate - now;

      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.timer = {
        hours: hours < 10 ? `0${hours}` : `${hours}`,
        minutes: minutes < 10 ? `0${minutes}` : `${minutes}`,
        seconds: seconds < 10 ? `0${seconds}` : `${seconds}`
      }

      if (distance < 0) {
        this.isExpired = true;
        clearInterval(this.counterInterval);
        this.timer = {
          hours: `00`,
          minutes: `00`,
          seconds: `00`
        }
      }
    }, 1000);
  }

  getImage() {
    return this.domSanitizer.bypassSecurityTrustUrl(this.paymentInfo.qrBase64);
  }

}
