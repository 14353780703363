import { Injectable } from '@angular/core';
import { AppHttp } from '../../../http/services/app-http/app-http.service';
import { EndpointsService } from '../../../http/services/endpoints/endpoints.service';
import { Endpoint } from '../../../http/models/endpoint/endpoint';
import { IResponse } from '../../../http/interfaces/iresponse';
import { DEFAULT_PAGINATION } from '../../../http/interfaces/ipagination';
import { IRelationship } from '../../../redux/user/interfaces/irelationship';
import { catchError, map } from 'rxjs/operators';
import { ITutorMe } from 'src/app/core/http/interfaces/itutor';

@Injectable({
  providedIn: 'root'
})
export class TutorServiceService {

  private tutorEndpoints: {
    main: Endpoint,
    students: Endpoint,
    voucherFile: Endpoint,
    credentialsFile: Endpoint,
    me: Endpoint
  };

  constructor(
    private http: AppHttp,
    private endpoints: EndpointsService,
  ) {
    this.tutorEndpoints = this.endpoints.users.tutor;
  }

  getTutor(tutorId) {
    this.tutorEndpoints.main.useWildcardParams({tutorId});
    this.tutorEndpoints.main.setCache(0);
    return this.http.get<IResponse<any>>(this.tutorEndpoints.main)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo cargar los datos del tutor')));
  }

  getTutorList(filters) {
    const params = this.http.buildQueryParams({ ...DEFAULT_PAGINATION, ...filters });
    this.tutorEndpoints.main.setCache(0);
    return this.http.get<IResponse<any>>(this.tutorEndpoints.main, { params })
      .pipe(catchError(response => this.http.handleError(response, 'Ocurrió un error al obtener la lista de tutores')));
  }

  createTutor(body) {
    return this.http.post(this.tutorEndpoints.main, body)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo completar el registro')));
  }

  updateTutor(tutorId, body) {
    this.tutorEndpoints.main.useWildcardParams({tutorId});
    return this.http.patch(this.tutorEndpoints.main, body)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo actualizar el registro')));
  }

  updateTutorMe(body: ITutorMe) {
    return this.http.patch(this.tutorEndpoints.me, body)
    .pipe(map(response => response.data))
    .pipe(catchError(response => this.http.handleError(response, 'No se pudo actualizar el registro')));
  }

  deleteTutor(tutorId: string) {
    this.tutorEndpoints.main.useWildcardParams({tutorId});
    return this.http.delete(this.tutorEndpoints.main)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo eliminar el registro')));
  }

  deleteTutorStudentRelationship(tutorId: string, studentId: string) {
    this.tutorEndpoints.students.useWildcardParams({tutorId, studentId});
    return this.http.delete<IResponse<any>>(this.tutorEndpoints.students)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'Ocurrio un error inesperado al borrar la relación')));
  }

  addTutorStudentRelationship(tutorId, studentId, body: IRelationship) {
    this.tutorEndpoints.students.useWildcardParams({tutorId, studentId});
    return this.http.post<IResponse<any>>(this.tutorEndpoints.students, body)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'Ocurrio un error inesperado al agregar la relación')));
  }

  updateTutorStudentRelationship(tutorId: string, studentId: string, body) {
    this.tutorEndpoints.students.useWildcardParams({tutorId, studentId});
    return this.http.patch<IResponse<any>>(this.tutorEndpoints.students,  body)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo actualizar la relación')));
  }

  getVoucherFile(tutorId: string, studentId: string) {
    this.tutorEndpoints.voucherFile.useWildcardParams({tutorId, studentId});
    const responseType = 'blob';
    return this.http.get(this.tutorEndpoints.voucherFile, { responseType });
  }

  getCredentialsFile(tutorId: string) {
    this.tutorEndpoints.credentialsFile.useWildcardParams({tutorId});
    const responseType = 'blob';
    return this.http.get(this.tutorEndpoints.credentialsFile, { responseType });
  }
}
