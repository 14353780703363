import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, type OnInit } from '@angular/core';
import * as _ from 'lodash';
import { take } from 'rxjs';
import { NavigationFacade } from 'src/app/core/redux/navigation/facades/navigation-facade';
import { INavigationNode, INavigationNodeModel } from 'src/app/core/redux/navigation/interfaces/inavigation-node';
import { ActionType, INavigationNodeData, INodeTimer } from 'src/app/core/redux/navigation/interfaces/inavigation-node-data';
import { INavigationTree } from 'src/app/core/redux/navigation/interfaces/inavigation-tree';
import { UserFacade } from 'src/app/core/redux/user/facades/user-facade';
import { IUserData } from 'src/app/core/redux/user/interfaces/iuser-data';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {

	@Input('nodes') nodes: INavigationNode[];
	@Output('opened') opened: EventEmitter<INavigationNodeModel> = new EventEmitter<INavigationNodeModel>();

	activeNodes: INavigationNode[];
	hidenNodes: INavigationNode[];
	nodesEtt: INodeTimer[] = [];
	profile: IUserData;

	public constructor(
		private navigationFacade: NavigationFacade,
		private userFacade: UserFacade
	  ) {
		this.userFacade.profile$.subscribe(profile => this.profile = profile);
	  }

	ngOnInit(): void { }

	ngAfterViewInit(): void {

	}

	ngOnChanges(changes: SimpleChanges): void {
		//console.log('ngOnChanges', changes);
		this.activeNodes = this.nodes.filter(node => node.action != ActionType.hide);
		this.hidenNodes = this.nodes.filter(node => node.action == ActionType.hide);
		//console.log('NavListComponent ', this.activeNodes, this.hidenNodes);
		this.middlewareHidenNodes();
	}

	onOpened(openedNode: INavigationNodeModel) {
		this.nodes.forEach(node => {
			if (node.id !== openedNode.id) {
				//node.close();
			}
		});
		this.opened.emit(openedNode);
	}

	onActivated(openedNode: INavigationNodeModel) {
		this.nodes.forEach(node => {
			if (node.id !== openedNode.id) {
				node.desactive();
			}
		});
	}

	middlewareHidenNodes() {
		//console.log('middlewareHidenNodes', this.hidenNodes);
		this.hidenNodes.forEach(node => {
			this.handleNodeSchedule(node);
		});
	}

	handleNodeSchedule(node: INavigationNode) {
		//console.log('handleNodeSchedule', node);
		if (node.hasNextAction()) {
			if (node.getScheduledEndTime()) {
				let time = node.getScheduledEndTime().split(':');
				let currentDate = new Date();
				let endTime = new Date();
				endTime.setHours(Number(time[0]), Number(time[1]), Number(time[2]));
				if (endTime.getTime() <= currentDate.getTime()) {
					//console.log('NO SE APLICA REGLA');
					return;
				}
				let executeTime = endTime.getTime();
				let currentTime = (new Date()).getTime();
				//console.log(`menu ${this.node.id} should ${this.actualAction} in ${executeTime} from ${currentTime} so in ${(executeTime - currentTime) / 1000} `);
				let tidindex = this.nodesEtt.findIndex(n => n.id == node.id);
				if (tidindex > 1) {
					clearTimeout(this.nodesEtt[tidindex].tid);
					this.nodesEtt.splice(tidindex, 1);
				}

				let timeToExecute = executeTime - currentTime;
				console.log(`will execute executeNextStatus in ${timeToExecute / 1000} seconds `, node);
				let currentTTNode = <INodeTimer>{
					id: node.id
				}
				currentTTNode.tid = setTimeout(() => {
					this.executeNextStatus(node);
				}, timeToExecute);
			}
		}
	}

	executeNextStatus(node: INavigationNode) {
		console.log('executeNextStatus', node);
		this.navigationFacade.configState$.pipe(take(1)).subscribe((navigationNodes: INavigationTree<INavigationNodeData>) => {
			this.changeNextStatus(_.cloneDeep(navigationNodes), node);
		})
	}

	changeNextStatus(navigationNodes: INavigationTree<INavigationNodeData>, node: INavigationNode) {
		console.log('changeNextStatus ', navigationNodes, node);
		let currentNavigation: INavigationTree<INavigationNodeData> = JSON.parse(JSON.stringify(navigationNodes))
		if (node.hasNextAction()) {
			if (currentNavigation.main.find(n => n.id == node.id) != undefined) {
				currentNavigation.main.find(n => n.id == node.id).action = node.getNextAction();
				currentNavigation.main.find(n => n.id == node.id).data = node.getNextActionData();
			}
			else {
				let parentId = this.getParentId(node.id);
				currentNavigation.main.find(n => n.id == parentId).children.find(nd => nd.id == node.id).action = node.getNextAction();
				currentNavigation.main.find(n => n.id == parentId).children.find(nd => nd.id == node.id).data = node.getNextActionData();
			}
			console.log('node update', currentNavigation);
			this.navigationFacade.setConfig(currentNavigation);
			let tidindex = this.nodesEtt.findIndex(n => n.id == node.id);
			if (tidindex > 1) {
				clearTimeout(this.nodesEtt[tidindex].tid);
				this.nodesEtt.splice(tidindex, 1);
			}
			let menuSub = setTimeout(() => {
				this.navigationFacade.restoreNavigation(this.profile.id);
				clearTimeout(menuSub);
				menuSub = null;
			}, 30000)
		}
	}

	getParentId(id: string) {
		let idSplited = id.split('.');
		idSplited.pop();
		return idSplited.join('.');
	}

}
