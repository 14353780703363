import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { NotificationFacade } from '../../../../core/redux/notification/facades/notification-facade';
import { SyncService } from 'src/app/core/api/user/sync/sync.service';
import { MessageService } from 'src/app/core/services/message/message.service';
import { IUserData } from 'src/app/core/redux/user/interfaces/iuser-data';
import { UserFacade } from 'src/app/core/redux/user/facades/user-facade';
import { ConfigService } from 'src/app/core/config/services/config.service';
import { CacheService } from 'src/app/core/cache/services/cache.service';
import { INotificationData } from 'src/app/core/redux/notification/interfaces/inotification-data';

import { BlockuiService } from 'src/app/core/services/blockui/blockui.service';
import { SidenavComponentService, componentsInSidenav } from 'src/app/core/services/sidenav-component/sidenav-component';
import { AuthService } from 'src/app/core/session/services/auth/auth.service';
import { IAuthData } from 'src/app/core/redux/user/interfaces/iauth-data';
import { IResponse } from 'src/app/core/http/interfaces/iresponse';
import { SidenavService } from 'src/app/core/services/sidenav/sidenav.service';
import { UserInitializer } from 'src/app/core/bootstrap/user/user-initializer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-tool-bar',
  templateUrl: './tool-bar.component.html',
  styleUrls: ['./tool-bar.component.scss']
})
export class ToolBarComponent implements OnInit, OnDestroy {

  icons = {
    bell: "fa-duotone fa-bell",
    user: "fa-duotone fa-user",
    list: "fa-duotone fa-list"
  };

  notifications$: Observable<number> = this.notificationFacade.unread$;
  profileSyncNotifications$: Observable<INotificationData> = this.notificationFacade.getLastNotificationFor('account.sync');
  user$: Observable<IUserData> = this.userFacade.profile$;
  auth$: Observable<IAuthData> = this.userFacade.auth$;

  cleanSubscription: Subscription;

  isStudent: boolean = false;
  isTutor: boolean = false;
  isAdmin: boolean = false;
  isAllowed: string = "false";
  profileSyncId: string;
  profileUserId: string;
  timer: any;
  counter: number;
  time: number = 300000;
  retry: number = 120000;
  isFromToken = false;
  updated = false;

  @Input('notificationCenter') notificationCenter: MatSidenav;
  constructor(
    private userFacade: UserFacade,
    private syncService: SyncService,
    private messageService: MessageService,
    protected config: ConfigService,
    private notificationFacade: NotificationFacade,
    private sidenavService: SidenavService,
    private blockuiService: BlockuiService,
    protected cacheService: CacheService,
    private sidenavComponentService: SidenavComponentService,
    private authService: AuthService,
    private userBootstrap: UserInitializer,
  ) { }

  ngOnInit() {
    this.isStudent = this.authService.isStudentTenant();
    this.isTutor = this.authService.isTutorTenant();
    this.isAdmin = this.authService.isAdminTenant();
    this.isAllowed = this.config.get("synchronization.avaliable");
    console.log("Sincronizacion disponible ", this.isAllowed)
    this.isFromToken = true;
    this.user$.pipe(untilDestroyed(this)).subscribe((s: IUserData) => {
      this.isStudent = s.isStudent;
      this.isTutor = s.isTutor;
      this.isAdmin = s.isAdmin;
      this.isFromToken = false;
    });
    this.profileSyncNotifications$.pipe(untilDestroyed(this)).subscribe(notification => {
      this.blockuiService.stop();
      if (notification.type == 'success') {
        this.counter = 0;
        clearTimeout(this.timer);
        this.cleanCache(this.profileUserId);
      } else {
        clearTimeout(this.timer);
        this.sidenavService.open();
        this.blockuiService.stop();
        this.messageService.error(`Al parecer tenemos un problema al actualizar sus datos.
                Favor de intentarlo más tarde, si el problema persiste favor de contactar a sistemas escolares.`);
      }
    });
  }

  sinchronizationValidation(): boolean{
    if(this.isStudent){
      return this.authService.isAdminTenant() || this.config.get("synchronization.avaliable") === 'true';
    }
    return false;
  }


  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  openNotificationCenter() {
    this.sidenavComponentService.setComponent(componentsInSidenav.notification);
    this.notificationFacade.openContainer();
  }

  update() {
    //console.log('-> update()');
    this.messageService
      .warning(`La actualización de datos detendrá las operaciones.
      Y solo funcionará si previamente reportó un error con sus datos en servicios escolares
      ¿Usted está seguro de querer continuar con la actualización de datos?`, null,
        () => {
          //console.log(`isFromToken: ${this.isFromToken}`);
          if (this.isFromToken) {
            this.auth$.pipe(first()).pipe(untilDestroyed(this)).subscribe(auth => {
              this.sync(auth.username);
            });
          } else {
            this.user$.pipe(first()).pipe(untilDestroyed(this)).subscribe(profile => {
              this.sync(profile.id);
            });
          }
        },
        () => { });
  }

  sync(profileId) {
    //console.log(`-> sycn(${profileId})`);
    this.counter = 0;
    this.profileUserId = profileId;
    this.syncService
      .updateStudent(profileId)
      .pipe(untilDestroyed(this)).subscribe(queue => {
        // this.profileSyncId = queue.queueId;
        //console.log(`queue: ${queue}`);
        if (queue) {
          this.sidenavService.close();
          this.blockuiService.stop();
          this.blockuiService.start('Actualizando datos...');
          this.timer = setTimeout(() => this.verifyUpdate(), this.time);
        } else {
          this.messageService.error(`Al parecer tenemos un problema al actualizar sus datos.
                Favor de intentarlo más tarde, si el problema persiste favor de contactar a sistemas escolares.`);
        }
      });
  }

  verifyUpdate() {
    //console.log('-> verifyUpdate()');
    clearTimeout(this.timer);
    this.syncService.cleanCacheStudent(this.profileUserId).pipe(untilDestroyed(this)).subscribe(result => {
      // this.blockuiService.stop();
      //console.log('result', result);
      this.sidenavService.close();
      this.blockuiService.stop();
      this.blockuiService.start(`La actualización parece tardar más de lo esperado.
        Pero no se preocupe, su actualización está en proceso.`);

      this.counter++;
      if (this.counter < 30) {
        this.syncService.verifyUpdate(this.profileUserId).pipe(untilDestroyed(this)).subscribe((verified: IResponse<boolean>) => {
          //console.log('-> syncService.verifyUpdate()');
          //console.log('verified:', verified);
          if (verified) {
            if (verified.data) {
              if (verified.error == null || typeof verified.error === 'undefined') {
                clearTimeout(this.timer);
                this.cleanCache(this.profileUserId);
              } else {
                clearTimeout(this.timer);
                this.sidenavService.open();
                this.blockuiService.stop();
                this.messageService.error(`Al parecer tenemos un problema al actualizar sus datos.
                Favor de intentarlo más tarde, si el problema persiste favor de contactar a sistemas escolares.`);
              }
            } else {
              if (verified.error == null || typeof verified.error === 'undefined') {
                this.timer = setTimeout(() => this.verifyUpdate(), this.retry);
              }
              else {
                clearTimeout(this.timer);
                this.sidenavService.open();
                this.blockuiService.stop();
                this.messageService.error(`Al parecer tenemos un problema al actualizar sus datos.
                Favor de intentarlo más tarde, si el problema persiste favor de contactar a sistemas escolares.`);
              }
            }
          } else {
            this.timer = setTimeout(() => this.verifyUpdate(), this.retry);
          }
        }, () => {
          this.timer = setTimeout(() => this.verifyUpdate(), this.retry);
        });
      } else {
        this.sidenavService.open();
        this.blockuiService.stop();
        this.messageService.error(`Al parecer tenemos un problema al actualizar sus datos.
          Favor de intentarlo más tarde, si el problema persiste favor de contactar a sistemas escolares.`);
      }
    });
  }

  cleanCache(profileUserId: string) {
    //console.log(`-> cleanCache(${profileUserId})`);
    //console.log(`updated: ${this.updated}`);
    this.blockuiService.stop();
    if (this.updated) {
      return;
    }

    this.updated = true;
    const apiURL = this.config.get('api.url', '');
    this.cacheService.clear(apiURL);

    this.cleanSubscription = this.syncService.cleanCacheStudent(profileUserId).pipe(untilDestroyed(this)).subscribe(result => {
      //console.log(`syncService.cleanCacheStudent(${profileUserId})`);
      //console.log('result: ', result);
      this.stopBlock();
      this.messageService.success(`¡Actualización finalizada!`, null, () => {
        this.userBootstrap.reloadProfile();
        //console.log(`-> this.userBootstrap.reloadProfile()`);
        // window.location.reload();
      });
      //console.log(`All is ok`);
      this.blockuiService.stop();
      this.sidenavService.open();
    },
      response => {
        this.stopBlock();
        this.handleFetchError(response,
          `Ocurrió un error al intentar borrar datos temporales,
        favor de reintentar la actualización, si el problema persiste
        salir del sistema y esperar 10 minutos para revisar sus datos actualizados.`);
      },
      () => {
        this.stopBlock();
      });
  }

  stopBlock() {
    this.blockuiService.stop();
  }

  clearSubscriptions() {
    if (this.cleanSubscription) {
      this.cleanSubscription.unsubscribe();
    }
  }

  handleFetchError(response, defaultMessage: string) {
    this.sidenavService.open();
    this.blockuiService.stop();
    (response.error && response.error.message)
      ? this.messageService.error(response.error.message, response.error.error)
      : this.messageService.error(defaultMessage);
  }

  openTransactionCenter() {
    this.sidenavComponentService.setComponent(componentsInSidenav.transactions);
    this.notificationFacade.openContainer();
  }

}
