import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FileSaverService } from 'ngx-filesaver';
import { Observable, Subscription } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { BillingFacade } from 'src/app/core/redux/billing/facades/billing-facade';
import { IRebillingData } from 'src/app/core/redux/billing/interfaces/ibilling-status';
import { NotificationFacade } from 'src/app/core/redux/notification/facades/notification-facade';
import { INotificationData, NotificationTypes } from 'src/app/core/redux/notification/interfaces/inotification-data';
import { UserFacade } from 'src/app/core/redux/user/facades/user-facade';
import { IUserData } from 'src/app/core/redux/user/interfaces/iuser-data';
import { MessageService } from 'src/app/core/services/message/message.service';
import { StringService } from 'src/app/core/services/string/string.service';
import { _billingDownloadOrigin } from '../../interfaces/billin-origins';
import { IInvoice, IInvoiceFile } from '../../interfaces/ibillbox';
import { BillingService } from '../../../../../core/api/billing/billing.service';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-billing-download',
  templateUrl: './billing-download.component.html',
  styleUrls: ['./billing-download.component.scss']
})
export class BillingDownloadComponent implements OnInit, OnDestroy {

  @Input('invoice') invoice: IInvoice;
  @Input('invoiceId') invoiceId: string;
  @Input('uuid') uuid: string;
  @Input('requestId') requestId: string;
  @Input('mailBoxRequestId') mailBoxRequestId: string;
  @Input('showRebilling') showRebilling: boolean = false;
  @Input('showDownload') showDownload: boolean = false;
  @Input('showBack') showBack: boolean = true;

  @Output('onBackEvent') onBackEvent = new EventEmitter<boolean>();

  disableBtns = true;

  allBillBoxNotifications$: Observable<INotificationData[]> = this.notificationFacade.getAllFor(_billingDownloadOrigin);
  allBillBoxNotificationsSubscription: Subscription;

  profile: IUserData;
  validateTimerSub;

  proccesBillBoxHadFinished = false;
  alreadyBillBoxApplied = false;

  loading = true;
  downloaded = false;

  invoiceDocuments: IInvoiceFile;

  pdfURLReady: boolean;
  base64src: string;
  timeBeforeRender: number = 2000;
  pdfURL: Uint8Array;

  hasRoleToRebill: boolean = false;

  //Subcriptors
  validateRequestStatusSub: Subscription;
  getInvoicesSub: Subscription;
  sendEmailSub: Subscription;

  constructor(
    private notificationFacade: NotificationFacade,
    private userFacade: UserFacade,
    private billingService: BillingService,
    private messageService: MessageService,
    private stringService: StringService,
    private fileSaver: FileSaverService,
    private billingFacade: BillingFacade,
    private router: Router
  ) {
    this.hasRoleToRebill = this.billingFacade.isAllowedTenant();
    this.userFacade.profile$.pipe(untilDestroyed(this)).subscribe(profile => {
      this.profile = profile;
    });
  }

  ngOnInit() {
    //console.log('invoiceId: ', this.invoiceId);
    //console.log('requestId: ', this.requestId);
    this.handlePostBillBoxNotifications();
    this.validateRequest();
  }

  ngOnDestroy() {
    //console.log('BillingDownloadComponent ngOnDestroy');
    if (this.validateRequestStatusSub) {
      this.validateRequestStatusSub.unsubscribe();
    }
    if (this.getInvoicesSub) {
      this.getInvoicesSub.unsubscribe();
    }

    if (this.allBillBoxNotificationsSubscription) {
      this.allBillBoxNotificationsSubscription.unsubscribe();
    }
    this.clearValidateRequest();
  }

  validateRequest() {
    this.validateTimerSub = setTimeout(() => {
      this.getValidateRequest();
    }, 12000);
  }

  clearValidateRequest() {
    if (this.validateTimerSub) {
      clearTimeout(this.validateTimerSub);
    }
  }

  getValidateRequest() {
    this.validateRequestStatusSub =
      this.billingService.validateRequestStatus(this.requestId, this.profile.id)
        .pipe(finalize(() => {
          if (this.validateRequestStatusSub) {
            this.validateRequestStatusSub.unsubscribe();
          }
        }))
        .pipe(untilDestroyed(this)).subscribe(data => {
          if (!!data && !!data.data) {
            if (!this.proccesBillBoxHadFinished) {
              if (!this.alreadyBillBoxApplied) {
                this.dispatchGetBillBoxSucced();
              }
            }
          } else {
            if (!this.proccesBillBoxHadFinished) {
              if (!this.alreadyBillBoxApplied) {
                this.validateRequest();
              }
            }
          }
        }, err => {
          if (!this.proccesBillBoxHadFinished) {
            if (!this.alreadyBillBoxApplied) {
              this.validateRequest();
            }
          }
        })
  }

  handlePostBillBoxNotifications() {
    this.allBillBoxNotificationsSubscription = this.allBillBoxNotifications$.pipe(untilDestroyed(this)).subscribe(notifications => {
      if (notifications != null && typeof notifications !== 'undefined') {
        notifications.forEach(notification => {
          //console.log(notification);
          if (this.requestId) {
            if (!this.proccesBillBoxHadFinished) {
              if (this.requestId === notification.data['request']) {
                if (!this.alreadyBillBoxApplied) {
                  if (notification.type == NotificationTypes.success) {
                    this.dispatchGetBillBoxSucced();
                  } else {
                    this.dispatchGetBillBoxFail(notification.message);
                  }
                }
              }
            }
          }
        });
      }
    });
  }

  getBillDocuments() {
    this.getInvoicesSub =
      this.billingService.getInvoiceFiles(this.invoiceId, this.profile.id, this.requestId)
        .pipe(finalize(() => {
          if (this.getInvoicesSub) {
            this.getInvoicesSub.unsubscribe();
          }
        }))
        .pipe(untilDestroyed(this)).subscribe((data: IInvoiceFile) => {
          if (!!data && !!data.billPdf) {
            this.invoiceDocuments = data;
            this.disableBtns = false;
            this.initPdfRender();
          } else {
            this.proccesBillBoxHadFinished = false;
            this.alreadyBillBoxApplied = false;
          }
        }, error => {
          this.proccesBillBoxHadFinished = false;
          this.alreadyBillBoxApplied = false;
          this.showDownload = false;
          this.showBack = true;
          this.backToMainPage();
        });
  }

  initPdfRender() {
    try {
      setTimeout(() => {
        (<any>window).PDFViewerApplication.setTitleUsingUrl(this.invoiceDocuments.pdfName);
      }, this.timeBeforeRender);
    } catch (error) { }
    if (this.invoiceDocuments.billPdf) {
      this.handlePdfURL(this.invoiceDocuments.billPdf);
      this.handleTime();
    }
  }

  handleTime() {
    const self = this;
    setTimeout(() => {
      self.pdfURLReady = true;
    }, 0);
  }

  handlePdfURL(base64PDF: string) {
    this.base64src = base64PDF;
    this.pdfURL = this.stringService.base64ToUint8Array(base64PDF);
  }

  dispatchGetBillBoxSucced() {
    this.applyNotification();
    this.getBillDocuments();
  }

  dispatchGetBillBoxFail(message) {
    this.applyNotification();
    this.loading = false;
    this.messageService.error('Ocurrió un error al obtener la información', [message]);
  }

  applyNotification() {
    this.clearValidateRequest();
    this.alreadyBillBoxApplied = true;
    this.proccesBillBoxHadFinished = true;
  }

  restartNotification() {
    this.alreadyBillBoxApplied = false;
    this.proccesBillBoxHadFinished = false;
  }

  handleDownloadPdf() {
    this.dispatchMail();
    this.handleDownloadPdfs(this.invoiceDocuments.billZip);
  }

  dispatchMail() {
    this.sendEmailSub =
      this.billingService.sendEmail(this.invoiceId, this.profile.id, this.requestId)
        .pipe(finalize(() => {
          if (this.sendEmailSub) {
            this.sendEmailSub.unsubscribe();
          }
        }))
        .pipe(untilDestroyed(this)).subscribe(data => {
          if (!!data) {
            this.messageService.success('Te enviamos una copia de la factura a tu correo myunitec')
          }
        });
  }

  handleDownloadPdfs(base64File: string) {
    const pdfBase64 = base64File;
    const file = this.stringService.base64toBlob(pdfBase64);

    this.fileSaver.save(file, this.invoiceDocuments.zipName);

    this.downloaded = true;
  }

  backToMainPage() {
    this.onBackEvent.emit(true);
  }

  rebill() {
    if (this.invoiceId) {
      this.billingFacade.setRebilling(<IRebillingData>{
        active: true,
        additionalData: {},
        data: this.invoice,
        folio: this.invoiceId,
        transactionId: this.mailBoxRequestId,
        uuid: this.uuid
      });
      setTimeout(() => {
        this.router.navigate(['financial-info/billing/rebilling'])
      }, 100);
    }
  }

}
