import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../../../core/api/user/user.service';
import { interval } from 'rxjs';
import { UserFacade } from '../../../../core/redux/user/facades/user-facade';
import { SECOND } from '../../../../core/cache/services/cache.service';
import { Router } from '@angular/router';
import { ServerAvailability } from '../../../../core/session/services/server-availability/server-availability.service';
import { AuthService } from 'src/app/core/session/services/auth/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-server-busy-page',
  templateUrl: './server-busy-page.component.html',
  styleUrls: ['./server-busy-page.component.scss']
})
export class ServerBusyPageComponent implements OnInit, OnDestroy {

  protected isRequesting: boolean = false;
  protected timer;
  protected profile;
  protected time = 45 * SECOND;
  protected queueStateSubscription;
  protected profileStateSubscription;

  private _requestedUrl: string;

  public icon = "fa-duotone fa-mug-hot";
  public okIcon = "fa-duotone fa-check";
  public queueData;

  constructor(
    private userFacade: UserFacade,
    private user: UserService,
    private router: Router,
    private queue: ServerAvailability,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this._requestedUrl = this.authService.getRequestedUrl();
    if (!this.queue.isEnabled()) {
      this.queueData = {
        canEnter: true,
        ticket: 0,
      };
      return this.redirect();
    }

    this.queueStateSubscription = this.userFacade.queue$
      .pipe(untilDestroyed(this)).subscribe(queueData => this.handleQueueData(queueData));

    this.profileStateSubscription = this.userFacade.profile$
      .pipe(untilDestroyed(this)).subscribe(profile => this.profile = profile);
    this.setTimer();
  }

  ngOnDestroy() {
    if (this.queue.isEnabled()) {
      this.timer.unsubscribe();
      this.queueStateSubscription.unsubscribe();
      this.profileStateSubscription.unsubscribe();
    }
  }

  setTimer() {
    this.timer = interval(this.time).pipe(untilDestroyed(this)).subscribe(() => this.checkAvailability());
  }

  checkAvailability() {
    if (!this.isRequesting && this.profile) {
      this.isRequesting = true;
      this.user.getQueueStatus(this.profile.id).pipe(untilDestroyed(this)).subscribe(
        () => this.isRequesting = false,
        () => this.isRequesting = false
      );
    }
  }

  handleQueueData(queueData) {
    this.queueData = queueData;
    if (queueData.canEnter) {
      this.redirect();
    }
  }

  redirect() {
    if(this._requestedUrl != '' && typeof this._requestedUrl !== 'undefined' && this._requestedUrl) {
      const _url = this._requestedUrl;
      this._requestedUrl = null;
      setTimeout(() => this.router.navigate([_url]), SECOND * 2);
      return;
    }
    setTimeout(() => this.router.navigate(['/']), SECOND * 2);
  }
}
