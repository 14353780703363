// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prev-img {
  min-width: 710px;
  min-height: 535px;
  border: solid;
  background-image: url('bg-preview.png');
}`, "",{"version":3,"sources":["webpack://./src/app/shared/layouts/components/image-editor/image-editor.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,uCAAA;AACJ","sourcesContent":[".prev-img {\r\n    min-width: 710px; \r\n    min-height: 535px; \r\n    border: solid; \r\n    background-image: url('../../../../../assets/img/bg-preview.png');\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
