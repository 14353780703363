import { createReducer, on } from '@ngrx/store';
import * as LOGGER_ACTIONS from './logs.actions';
import { LOGS_STATE } from '../interfaces/logger-state';
import { ILogsState } from '../interfaces/ilogger-state';

const _logsReducer = createReducer(LOGS_STATE,
    on(LOGGER_ACTIONS.ADD_LOG, (state: ILogsState, { log }) => {
        const history = [...state.history, log];
        const logs = [...state.logs, log.message];
        return { ...state, history, logs };
    })
);

export function LogsReducer(state, action) {
    return _logsReducer(state, action);
}