import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericDocumentResolve } from '../../routing/resolver/generic-document/generic-document.resolve';
import { GenericDocumentFactory } from './factories/generic-document-factory';
import { DocumentFactory } from './factories/document-factory';
import { DocumentControllFactory } from './factories/document-controll-factory';
import { DocumentFacade } from './facades/document-facade';
import { GenericDocumentCacheResolve } from '../../routing/resolver/generic-document/generic-document-cache.resolve';
import { StoreModule } from '@ngrx/store';
import { DocumentReducer } from './store/document.reducer';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('document', DocumentReducer),
  ],
  providers:[
    DocumentFacade,
    GenericDocumentResolve,
    GenericDocumentCacheResolve,
    GenericDocumentFactory,
    DocumentFactory,
    DocumentControllFactory
  ]
})
export class DocumentStoreModule { }
