import { createReducer, on } from '@ngrx/store';
import { MENU_ADMIN_STATE } from './menu-admin.state';
import * as MENU_ADMIN_ACTIONS from './menu-admin.actions';
import { IMenuAdminState } from '../interfaces/imenu-state';

const _menuAdminReducer = createReducer(MENU_ADMIN_STATE,
	on(MENU_ADMIN_ACTIONS.SET_CONFIG, (state: IMenuAdminState, { payload }) => ({ ...state, config: payload })),
	on(MENU_ADMIN_ACTIONS.SET_ROLES, (state: IMenuAdminState, { payload }) => ({ ...state, roles: payload })),
	on(MENU_ADMIN_ACTIONS.SET_CURRENT, (state: IMenuAdminState, { payload }) => ({ ...state, current: payload })),
	on(MENU_ADMIN_ACTIONS.SET_ACTION, (state: IMenuAdminState, { payload }) => ({ ...state, action: payload })),
	on(MENU_ADMIN_ACTIONS.SET_CURRENT_ID, (state: IMenuAdminState, { payload }) => ({ ...state, currentId: payload })),
	on(MENU_ADMIN_ACTIONS.SET_ADDITIONAL, (state: IMenuAdminState, { payload }) => ({ ...state, additional: payload })),
	on(MENU_ADMIN_ACTIONS.SET_CAMPUS, (state: IMenuAdminState, { campus }) => ({ ...state, campus })),
	on(MENU_ADMIN_ACTIONS.SET_CAREERS, (state: IMenuAdminState, { careers }) => ({ ...state, careers })),
	on(MENU_ADMIN_ACTIONS.SET_LEVELS, (state: IMenuAdminState, { levels }) => ({ ...state, levels })),
	on(MENU_ADMIN_ACTIONS.SET_DOWN_TYPES, (state: IMenuAdminState, { downTypes }) => ({ ...state, downTypes })),
	on(MENU_ADMIN_ACTIONS.SET_STUDENT_STATUS, (state: IMenuAdminState, { studentStatus }) => ({ ...state, studentStatus })),
	on(MENU_ADMIN_ACTIONS.RESET, () => MENU_ADMIN_STATE)
);

export function MenuAdminReducer(state, action) {
	return _menuAdminReducer(state, action);
}
