import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FullScreenService {

  private fullScreenSubject = new Subject<boolean>();
  private isFullScreen = false;

  isFullScreen$ = this.fullScreenSubject.asObservable();

  constructor() {
    this.next();
  }

  private next() {
    this.fullScreenSubject.next(this.isFullScreen);
  }

  public open() {
    this.isFullScreen = true;
    this.next();
  }

  public dimiss() {
    this.isFullScreen = false;
    this.next();
  }

  public toggle() {
    this.isFullScreen = !this.isFullScreen;
    this.next();
  }

}
