import { createReducer, on } from "@ngrx/store";
import { MENU_ADMIN_STATE } from "./../interfaces/menu-admin.state";
import * as MENU_ADMIN_ACTIONS from './menu-admin.actions';
import { IMenuAdminState } from "../interfaces/imenu-admin.state";

const _menuAdminReducer = createReducer(MENU_ADMIN_STATE,
    on(MENU_ADMIN_ACTIONS.SET_ACTIVE, (state: IMenuAdminState, { payload }) => ({ ...state, enabled: payload.valueOf() })),
    on(MENU_ADMIN_ACTIONS.SET_FULL_CONTROL, (state: IMenuAdminState, { payload }) => ({ ...state, enabled: payload.valueOf() })),
    on(MENU_ADMIN_ACTIONS.SET_CURRENT, (state: IMenuAdminState, { payload }) => ({ ...state, current: payload })),
    on(MENU_ADMIN_ACTIONS.SET_CAT_DATA, (state: IMenuAdminState, { payload }) => {
        return { ...state, [payload.catName]: payload.data }
    }),
    on(MENU_ADMIN_ACTIONS.SET_DOWN_TYPES, (state: IMenuAdminState, { payload }) => {
        return { ...state, downTypes: payload }
    }),
    on(MENU_ADMIN_ACTIONS.SET_STUDENT_STATUS, (state: IMenuAdminState, { payload }) => {
        return { ...state, studentStatus: payload }
    }));

export function MenuAdminReducer(state, action) {
    return _menuAdminReducer(state, action);
}