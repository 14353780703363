// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transaction-tab .mat-tab-header-pagination {
  display: none;
}
.transaction-tab .mat-tab-label {
  padding: 0 15px;
  min-width: 33%;
  font-size: 12px;
}
.transaction-tab .mat-tab-body-wrapper {
  flex: auto;
  align-self: stretch;
}
td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type, th.mat-header-cell:first-of-type {
  padding-left: 0;
}

td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type, th.mat-header-cell:last-of-type {
  padding-right: 0;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  min-height: 64px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/layouts/components/transaction-history-center/transaction-history-center.component.scss"],"names":[],"mappings":"AACI;EACE,aAAA;AAAN;AAEI;EACE,eAAA;EACA,cAAA;EACA,eAAA;AAAN;AAGI;EACE,UAAA;EACA,mBAAA;AADN;AAQA;EACI,eAAA;AANJ;;AASA;EACE,gBAAA;AANF;;AASA;EACE,gBAAA;AANF","sourcesContent":[".transaction-tab {\r\n    .mat-tab-header-pagination {\r\n      display: none;\r\n    }\r\n    .mat-tab-label {\r\n      padding: 0 15px;\r\n      min-width: 33%;\r\n      font-size: 12px;\r\n    }\r\n  \r\n    .mat-tab-body-wrapper {\r\n      flex: auto;\r\n      align-self: stretch;\r\n    }\r\n  \r\n    .mat-tab-body-content {\r\n    }\r\n  }\r\n\r\ntd.mat-cell:first-of-type, td.mat-footer-cell:first-of-type, th.mat-header-cell:first-of-type {\r\n    padding-left: 0;\r\n}\r\n\r\ntd.mat-cell:last-of-type, td.mat-footer-cell:last-of-type, th.mat-header-cell:last-of-type {\r\n  padding-right: 0;\r\n}\r\n\r\n.mat-toolbar-row, .mat-toolbar-single-row {\r\n  min-height: 64px;\r\n}\r\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
