import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImpersonateFacade } from './facades/impersonate-facade';
import { StoreModule } from '@ngrx/store';
import { ImpersonateReducer } from './store/impersonate.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('impersonate', ImpersonateReducer),
  ],
  providers: [
    ImpersonateFacade,
  ]
})
export class ImpersonateStoreModule { }
