// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hidden {
  display: none;
}

div#rielFrameContainer {
  margin: 16px;
  min-height: 256px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/layouts/components/riel-iframe/riel-iframe.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,YAAA;EACA,iBAAA;AACF","sourcesContent":[".hidden {\r\n  display: none;\r\n}\r\n\r\ndiv#rielFrameContainer {\r\n  margin: 16px;\r\n  min-height: 256px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
