import { createAction, props } from '@ngrx/store';
import { IUserData } from '../interfaces/iuser-data';
import { IQueueData } from '../interfaces/iqueue-data';

const SET_AUTH_DATA = createAction('[USER] set auth data', props<{ payload: string }>());

const SET_SSEL_TOKEN = createAction('[USER] set ssel token', props<{ payload: any }>());

const SET_PROFILE = createAction('[USER] set profile', props<{ payload: IUserData }>());

const SET_PROFILE_PICTURE = createAction('[USER] set profile picture', props<{ payload: string }>());

const SET_PERMISSIONS = createAction('[USER] set permissions', props<{ payload: any }>());

const SET_QUEUE_DATA = createAction('[USER] set queue data', props<{ payload: IQueueData }>());

export { SET_AUTH_DATA, SET_SSEL_TOKEN, SET_PROFILE, SET_PROFILE_PICTURE, SET_PERMISSIONS, SET_QUEUE_DATA,  }
