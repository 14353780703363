import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { CreateTutorReducer } from './store/admin-tutor.reducer';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('createTutor', CreateTutorReducer),
  ],
  providers:[
    
  ]
})
export class CreateTutorStoreModule { }
