// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-wrapper {
  margin: auto;
  border: solid 1px gainsboro;
  padding: 5px;
  max-width: 140px;
  max-height: 140px;
  border-radius: 50%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: inline-block;
}
.avatar-wrapper.flat {
  padding: 0;
  border: none;
}
.avatar-wrapper .avatar {
  background: gainsboro;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/layouts/components/avatar/avatar.component.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,2BAAA;EACA,YAAA;EACA,gBANU;EAOV,iBAPU;EAQV,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,qBAAA;AADF;AAGE;EACE,UAAA;EACA,YAAA;AADJ;AAIE;EACE,qBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAFJ","sourcesContent":["$max_width: 140px;\r\n\r\n.avatar-wrapper {\r\n  margin: auto;\r\n  border: solid 1px gainsboro;\r\n  padding: 5px;\r\n  max-width: $max_width;\r\n  max-height: $max_width;\r\n  border-radius: 50%;\r\n  overflow: hidden;\r\n  width: 100%;\r\n  height: 100%;\r\n  display: inline-block;\r\n\r\n  &.flat {\r\n    padding: 0;\r\n    border: none;\r\n  }\r\n\r\n  .avatar {\r\n    background: gainsboro;\r\n    width: 100%;\r\n    height: 100%;\r\n    border-radius: 50%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
