import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingFacade } from './facades/billing-facade';
import { StoreModule } from '@ngrx/store';
import { BillingReducer } from './store/billing.reducer';


@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('billing', BillingReducer),
  ],
  declarations: [],
  providers: [
    BillingFacade
  ]
})
export class BillingStoreModule { }
