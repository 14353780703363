import { Injectable } from '@angular/core';
import { ConfigService } from '../../config/services/config.service';
import { LogLevel } from '@azure/msal-browser';
import { _log, _log_debug, _log_error, _log_info, _log_trace, _log_warn } from '../../utilities/logger/logger';
import { LogsFacade } from '../../redux/logs/facades/logs.facade';
import * as moment from 'moment';

export const VOID_FUNCTION = (..._: any[]) => { };
export var logsFacadeInjected: LogsFacade | null = null;
export var _logger: LoggerService | null = null;

@Injectable({
    providedIn: 'root'
})
export class LoggerService {

    LOGGER_LEVEL: number = LogLevel['Verbose'];

    LogHistory = [];
    TraceHistory = [];
    VerboseHistory = [];
    InfoHistory = [];
    WarningHistory = [];
    ErrorHistory = [];

    constructor(
        private configService: ConfigService,
        private logsFacade: LogsFacade
    ) {
        logsFacadeInjected = logsFacade;
        this.SetLogLevel(this.configService.get('oauth2.loggerLevel'));
    }

    SetLogLevel(level: string) {
        this.LOGGER_LEVEL = this.GetLogLevel(level);
        this.info('LOGGER_LEVEL: ', this.LOGGER_LEVEL, level);
    }

    GetLogLevel(level: string): LogLevel {
        if (level == 'Error') {
            return LogLevel.Error;
        }
        if (level == 'Warning') {
            return LogLevel.Warning;
        }
        if (level == 'Info') {
            return LogLevel.Info;
        }
        if (level == 'Verbose') {
            return LogLevel.Verbose;
        }
        return LogLevel.Trace;
    }

    LOG(level: LogLevel, className: string, ...args: any[]){
        this.logsFacade.saveLogs({
            createdAt: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            level: LogLevel[level],
            message: JSON.stringify(args),
            className: className,
            saved: false
        });
        return this.log;
    }

    get clear() {
        return console.clear;
    }

    get log() {
        return _log;
    }

    get trace() {
        return LogLevel.Trace > this.LOGGER_LEVEL ? VOID_FUNCTION : _log_trace;
    }

    get verbose() {
        return LogLevel.Verbose > this.LOGGER_LEVEL ? VOID_FUNCTION : _log_debug;
    }

    get info() {
        return this.LOGGER_LEVEL > LogLevel.Info ? VOID_FUNCTION : _log_info;
    }

    get warn() {
        return LogLevel.Warning > this.LOGGER_LEVEL ? VOID_FUNCTION : _log_warn;
    }

    get error() {
        return LogLevel.Error > this.LOGGER_LEVEL ? VOID_FUNCTION : _log_error;
    }
}
