import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ConfigService } from '../../../../core/config/services/config.service';
import { HttpParams } from '@angular/common/http';
import { combineLatest, Subscription } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { AuthService } from '../../../../core/session/services/auth/auth.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { MessageService } from '../../../../core/services/message/message.service';
import { UserService } from '../../../../core/api/user/user.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { UserFacade } from '../../../../core/redux/user/facades/user-facade';


export enum SSELSelectors {
  home = 'menuDinamico',
  logout = 'logout-page',
  doubleSession = 'sActiva',
  sessionKilled = 'endSesion',
  sessionExpired = 'expiraSesion',
  login = 'login',
  unknownUser = 'noregistrado',
} import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseComponent } from '../base-component/base-component.component';

@UntilDestroy()
@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})
export class IFrameComponent extends BaseComponent implements OnInit, OnDestroy {
  protected rawUrl: string;
  public url: SafeUrl = null;
  protected sselHost: string;
  protected loginPath: string;
  protected httpParams: HttpParams;
  protected token: string;
  private popUp;
  public asPopUp: boolean = false;
  public hidden = false;
  public targetName = 'ssel_page';
  private popUpTimer;
  private canSendPostMessages = true;
  private handler: () => void;
  private dataSubscription: Subscription;

  @ViewChild('iframe') iframe;
  @ViewChild('link') link;
  constructor(
    private config: ConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private httpSpinner: SpinnerVisibilityService,
    private messenger: MessageService,
    private userFacade: UserFacade,
    private user: UserService,
    @Inject(LOCAL_STORAGE) private localStorage: StorageService,
  ) {
    super();
    this.sselHost = this.config.get('ssel.url');
    this.loginPath = this.config.get('ssel.loginPath');
    this.httpParams = new HttpParams();
  }

  ngOnInit() {
    this.resolveUrl();
    this.route.data.pipe(untilDestroyed(this)).subscribe(data => {
      // console.log(data);
    });
  }

  resolveUrl() {
    /*
    this.dataSubscription = combineLatest([this.route.data, this.userFacade.SSELToken$])
      .pipe(map(([data, token]) => {
        this.token = token;
        this.asPopUp = data.ssel.popup;
        let urlParams = new HttpParams();
        if (this.asPopUp) {
          urlParams = urlParams.append('showHeaders', 'true');
        }
        return this.httpParams
          .set('id', data.id)
          .set('url', `${this.sselHost}${data.ssel.url}?${urlParams.toString()}`)
          .set('dataenc', token);
      }))
      .pipe(untilDestroyed(this)).subscribe(params => {
        if (params.get('id') === 'logout') {
          this.hidden = true;
          return this.handleUrl(params.get('url'));
        }
        if (params.get('url') && params.get('dataenc')) {
          this.rawUrl = `${this.sselHost}${this.loginPath}?${params.toString()}`;
          return this.handleUrl(this.rawUrl);
        }
      });
      */
  }

  ngOnDestroy() {
    this.httpSpinner.hide();
    this.removeListener();
    this.dataSubscription.unsubscribe();
  }

  setUrl(url) {
    this.httpSpinner.show();
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  handleUrl(url) {
    this.addListener();
    this.setUrl(url);
    if (this.asPopUp) {
      this.handlePopUp();
    }
  }

  handlePopUp() {
    this.openPopUp();
    if (!this.popUp) {
      setTimeout(() => this.messenger.warning(`
        Tienes bloqueadas las ventanas emergentes.
        <div>Te recomendamos hablitarlas para evitar este mensaje en futuras ocasiones.</div>
      `,
        [],
        () => {
          this.openPopUp();
          this.redirectToProfile();
        }
      ), 300);
    } else {
      this.redirectToProfile();
    }
  }

  openPopUp() {
    this.popUp = window.open(this.rawUrl, this.targetName);
    if (this.popUp) {
      this.notifyForExternalProcess();
      this.popUpTimer = setInterval(() => {
        if (this.popUp.closed !== false) {
          this.removePopUpInterval();
          this.messenger.current.close();
        }
        if (this.canSendPostMessages) {
          this.sendMessages(this.popUp);
          this.canSendPostMessages = false;
        }
      }, 500);
    }
  }

  removePopUpInterval() {
    clearInterval(this.popUpTimer);
  }

  addListener() {
    this.handler = this.asPopUp
      ? this.handlePopUpMessages.bind(this)
      : this.handleIFrameMessages.bind(this);
    window.removeEventListener('message', this.handler);
    window.addEventListener('message', this.handler);
  }

  removeListener() {
    window.removeEventListener('message', this.handler);
  }

  getUrl() {
    return this.url;
  }

  handleIFrameMessages(event) {
    if (this.iframe) {
      if (typeof event.data === 'object') {
        if (this.exists(event.data, SSELSelectors.unknownUser)) {
          return this.tryRegisterUser();
        }
        if (this.exists(event.data, SSELSelectors.sessionExpired)) {
          return this.redirectToProfile();
        }
        if (this.exists(event.data, SSELSelectors.home)
          || this.exists(event.data, SSELSelectors.login)
        ) {
          return this.redirectToProfile();
        }
        if (this.exists(event.data, SSELSelectors.doubleSession)) {
          return this.askForClosePreviousSession();
        }
        if (this.exists(event.data, SSELSelectors.logout)) {
          return this.close();
        }
        if (this.exists(event.data, SSELSelectors.sessionKilled)) {
          return this.notifyCloseSessionReason();
        }
      } else {
        const iFrame = this.iframe.nativeElement;
        const height = parseInt(event.data, 10);
        iFrame.style.height = `${height}px`;
      }
    }
  }

  handlePopUpMessages(event) {

    if (this.exists(event.data, SSELSelectors.unknownUser)) {
      this.popUp.close();
      return this.tryRegisterUser();
    }

    if (this.exists(event.data, SSELSelectors.doubleSession)) {
      this.popUp.close();
      this.askForClosePreviousSession();
    }

    if (this.exists(event.data, SSELSelectors.sessionKilled)) {
      this.popUp.close();
      this.notifyCloseSessionReason();
    }
  }

  exists(data, name) {
    return data.selector === name && data.result;
  }

  onLoadIFrame() {
    this.httpSpinner.show();
    if (this.iframe) {
      const iFrame = this.iframe.nativeElement;
      this.sendMessages(iFrame.contentWindow);
      this.httpSpinner.hide();
    }
  }

  sendMessages(target) {
    const host = '*';
    target.postMessage({ type: 'attr', selector: 'html', attr: 'height' }, host);
    target.postMessage({ type: 'exists', selector: SSELSelectors.unknownUser }, host);
    target.postMessage({ type: 'exists', selector: SSELSelectors.home }, host);
    target.postMessage({ type: 'exists', selector: SSELSelectors.logout }, host);
    target.postMessage({ type: 'exists', selector: SSELSelectors.doubleSession }, host);
    target.postMessage({ type: 'exists', selector: SSELSelectors.sessionKilled }, host);
    target.postMessage({ type: 'exists', selector: SSELSelectors.sessionExpired }, host);
    target.postMessage({ type: 'exists', selector: SSELSelectors.login }, host);
  }

  askForClosePreviousSession() {
    setTimeout(() => this.messenger
      .warning('Hay una sesión previa en otro dispositivo' +
        ', si seleccionas "continuar" la sesión anterior se cerrará, de lo contrario se cerrará esta sesión.',
        [],
        () => {
          this.killSession();
        },
        () => {
          this.close();
        }
      ), 200);
  }

  notifyCloseSessionReason() {
    this.hidden = true;
    this.url = null;
    this.messenger.warning(`Tu sesión a expirado o se inició sesión en otro dispositivo
    , si no reconoces esa acción te recomendamos cambiar tu contraseña.`,
      [],
      () => {
        this.messenger.clear();
        this.router.navigate(['/logout']);
      }
    );
  }

  notifyForExternalProcess() {
    setTimeout(() =>
      this.messenger.warning(
        'Hay un proceso activo en otra pestaña, cuando termine puedes cerrarla y continuar aquí'
      ),
      200);
  }

  killSession() {
    this.url = null;
    this.user.killSselSession(this.token).pipe(untilDestroyed(this)).subscribe(response => {
      this.httpSpinner.show();
      this.handleUrl(this.rawUrl);
    }, response => {
      console.log(response.error);
    });
  }

  close() {
    this.authService.logOut();
  }

  redirectToProfile() {
    this.hidden = true;
    this.httpSpinner.show();
    setTimeout(() => this.router.navigate(['/']), 1500);
  }

  notifyRegisterError(error?) {
    (error && error.message)
      ? this.messenger.error('No se pudo cargar el contenido de ésta página. ¿Deseas reintentar?', { details: [error.message] },
        () => { this.tryRegisterUser(); },
        () => { this.redirectIfIsNotPoUp(); }
      )
      : this.messenger.error('Ocurrió un error al validar el registro del usuario.',
        [],
        () => { this.redirectIfIsNotPoUp(); }
      );
  }

  redirectIfIsNotPoUp() {
    if (!this.asPopUp) {
      this.redirectToProfile();
    }
  }

  tryRegisterUser() {
    return;
    /*
    if (!this.localStorage.has('__ssel.signup.try')) {
      this.userFacade.SSELToken$
        .pipe(first())
        .pipe(filter(token => !!token.length))
        .pipe(untilDestroyed(this)).subscribe(token => {
          this.localStorage.set('__ssel.signup.try', 1);
          this.user.signUp(token)
            .pipe(untilDestroyed(this)).subscribe(() => {
              this.handleUrl(this.rawUrl);
            }, response => {
              this.notifyRegisterError(response.error);
            });
        });
    } else {
      this.notifyRegisterError();
    }
    */
  }
}
