import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IHistoryLog, IHistoryLogServer, ILogsState } from '../interfaces/ilogger-state';
import { selectLogsState } from '../store/logs.selectors';
import * as LOGGER_ACTIONS from '../store/logs.actions';

@Injectable()
export class LogsFacade {

  private state$: Observable<ILogsState> = this.store.select(selectLogsState);

  history$: Observable<IHistoryLog[]> = this.state$.pipe(
    select(state => state.history)
  );

  logs$: Observable<string[]> = this.state$.pipe(
    select(state => state.logs)
  );

  constructor(private store: Store<ILogsState>) { }

  saveLogs(log: IHistoryLog) {
    this.store.dispatch(LOGGER_ACTIONS.SAVE_LOG({ log }));
  }

}
