import { Injectable } from "@angular/core";
import { Endpoint } from "src/app/core/http/models/endpoint/endpoint";
import { AppHttp } from "src/app/core/http/services/app-http/app-http.service";
import { EndpointsService } from "src/app/core/http/services/endpoints/endpoints.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { IDocumentTypeMetadaModel } from "../../interfaces/generic-document/idocument-type-data";
import { map, catchError } from 'rxjs/operators';
import { IDocumentMetadataModel } from "../../interfaces/generic-document/idocument-metadata.model";
import { IDocumentPrice } from "../../redux/document/interfaces/idocument.prices";
import { IDocumentsPreview, IDocumentsQr } from "../../redux/document/interfaces/idocuments-preview";

@Injectable({
  providedIn: 'root'
})
export class GenericDocumentService {

  genericDocument: {
    getDocumentTypeMetadata: Endpoint,
    getDocumentMetadata: Endpoint,
    requestDocumentPrices: Endpoint,
    getPaymentStatus: Endpoint,
    getDocumentPrices: Endpoint,
    startDocument: Endpoint,
    getDocumentPreview: Endpoint,
    activityLogs: Endpoint,
    downloadDocument: Endpoint,
    getPaymentId: Endpoint,
    getDebs: Endpoint,
    validateDebsRequets: Endpoint,
    getDocuments: Endpoint,
    getAllStatus: Endpoint,
    getAllowStateChanges: Endpoint,
    getDocumentLogs: Endpoint,
    getPaymentRequest: Endpoint,
    getSummaryRequest: Endpoint,
    getDocumentsQr: Endpoint,
    changeStatus: Endpoint,
    logos: Endpoint,
    sendByEmail:Endpoint
  };

  constructor(
    private http: AppHttp,
    private endpoints: EndpointsService,
    private _http: HttpClient
  ) {
    this.genericDocument = this.endpoints.genericDocument;
  }

  getDocumentTypeMetadata(documentType: string, profileUserId: string): Observable<IDocumentTypeMetadaModel> {
    this.genericDocument.getDocumentTypeMetadata.useWildcardParams({ documentType, profileUserId });
    return this.http.get(this.genericDocument.getDocumentTypeMetadata)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo obtener la lista Tipos documentos.')));
  }

  getDocumentMetadata(documentId: string, profileUserId: string): Observable<IDocumentMetadataModel> {
    //return this.documentMock();
    this.genericDocument.getDocumentMetadata.useWildcardParams({ documentId, profileUserId });
    return this.http.get(this.genericDocument.getDocumentMetadata)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo obtener la lista de Documentos.')));
  }

  requestPaymentList(documentId: string, profileUserId: string): Observable<string> {
    this.genericDocument.requestDocumentPrices.useWildcardParams({ documentId, profileUserId });
    return this.http.post(this.genericDocument.requestDocumentPrices, {})
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo solicitar la lista de precios.')));
  }

  getPaymentStatus(queueId: string): Observable<boolean> {
    this.genericDocument.getPaymentStatus.useWildcardParams({ queueId });
    return this.http.get(this.genericDocument.getPaymentStatus)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo obtener la lista de precios.')));
  }

  getPaymentList(documentId: string, requestId: string, profileUserId: string): Observable<IDocumentPrice[]> {
    this.genericDocument.getDocumentPrices.useWildcardParams({ documentId, requestId, profileUserId });
    return this.http.get(this.genericDocument.getDocumentPrices)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo obtener la lista de precios.')));
  }

  startDocument(profileUserId: string, body: any) {
    this.genericDocument.startDocument.useWildcardParams({ profileUserId });
    return this.http.post(this.genericDocument.startDocument, body)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo solicitar el documento.')));
  }

  getDocumentPreview(orderId: string, profileUserId: string): Observable<IDocumentsPreview[]> {
    this.genericDocument.getDocumentPreview.useWildcardParams({ orderId, profileUserId });
    return this.http.get(this.genericDocument.getDocumentPreview)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo obtener el documento.')));
  }

  downloadDocument(transactionId: any): Observable<IDocumentsPreview[]> {
    this.genericDocument.downloadDocument.useWildcardParams({ transactionId });
    return this.http.get(this.genericDocument.downloadDocument)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fué posible obtener el documento.')));
  }

  getPaymentId(orderId: any, profileUserId: any, body: any) {
    this.genericDocument.getPaymentId.useWildcardParams({ orderId, profileUserId });
    return this.http.post(this.genericDocument.getPaymentId, body)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo solicitar el documento.')));
  }

  getRegulationStorage(url) {
    return this._http.get(url, {
      responseType: 'text'
    });
  }

  validateDebsRequets(procedure: any, requestId: any, profileUserId: any) {
    this.genericDocument.validateDebsRequets.useWildcardParams({ procedure, requestId, profileUserId });
    return this.http.get(this.genericDocument.validateDebsRequets)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo obtener el documento.')));
  }

  getDebs(requestId: any, profileUserId: any) {
    this.genericDocument.getDebs.useWildcardParams({ requestId, profileUserId });
    return this.http.get(this.genericDocument.getDebs)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo obtener el documento.')));
  }

  getPaymentRequest(profileUserId, userId, paymentId) {
    this.genericDocument.getPaymentRequest.useWildcardParams({ profileUserId, paymentId });
    return this.http.get(this.genericDocument.getPaymentRequest)
      .pipe(map(response => ({ data: response.data, additionalData: response.additionalData })))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo recuperar los datos de pago.')));
  }

  getSummaryRequest(profileId, transactionId) {
    this.genericDocument.getSummaryRequest.useWildcardParams({ profileId, transactionId });
    return this.http.get(this.genericDocument.getSummaryRequest)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo obtener la información solicitada.')));
  }


  /**
   * Admin screens
   */
  getDocuments(documentType: string, deliveryType: string, body: any) {
    this.genericDocument.getDocuments.useWildcardParams({ documentType, deliveryType });
    return this.http.post(this.genericDocument.getDocuments, body)
      .pipe(map(response => ({ data: response.data, additionalData: response.additionalData })))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo recuperar la lista de documentos.')));
  }

  getAllStatus(documentType: string, deliveryType = 'Campus') {
    this.genericDocument.getAllStatus.useWildcardParams({ documentType, deliveryType });
    return this.http.get(this.genericDocument.getAllStatus)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo obtener la lista de estatus.')));
  }

  getAllowStateChanges(transactionId: string) {
    this.genericDocument.getAllowStateChanges.useWildcardParams({ transactionId });
    return this.http.get(this.genericDocument.getAllowStateChanges)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo obtener la información de estatus.')));
  }

  getDocumentLogs(transactionId: string) {
    this.genericDocument.getDocumentLogs.useWildcardParams({ transactionId });
    return this.http.get(this.genericDocument.getDocumentLogs)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo obtener la lista de estatus.')));
  }

  getDocumentsQr(transactionId: string, captchaReq: string): Observable<IDocumentsQr> {
    this.genericDocument.getDocumentsQr.useWildcardParams({ transactionId, captchaReq });
    return this.http.get(this.genericDocument.getDocumentsQr)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo obtener la lista de documentos.')));
  }

  changeStatus(body: any) {
    return this.http.post(this.genericDocument.changeStatus, body)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo realizar el cambio de estatus.')));
  }

  getInformativeFileStorage(url) {
    return this._http.get(url, {
      responseType: 'text'
    });
  }

  getImages(documentType: string) {
    this.genericDocument.logos.useWildcardParams({ documentType });
    return this.http.get(this.genericDocument.logos)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo obtener la lista de imagenes del documento.')));
  }

  saveImages(documentType: string, body: any) {
    this.genericDocument.logos.useWildcardParams({ documentType });
    return this.http.patch(this.genericDocument.logos, { ...body })
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo guardar la configuración')));
  }

  sendByEmail(transactionId: string, recreate : boolean){
    this.genericDocument.sendByEmail.useWildcardParams({ transactionId, recreate });
    return this.http.post(this.genericDocument.sendByEmail, {})
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo enviar solicitud')));
  }
}



