import { Injectable } from "@angular/core";
import { EndpointsService } from "../../../http/services/endpoints/endpoints.service";
import { AppHttp } from "../../../http/services/app-http/app-http.service";
import { Endpoint } from "../../../http/models/endpoint/endpoint";
import { IRol, IRule, IRolActions } from "../interfaces/imenu-model";
import { catchError, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { IOriginalMenuModel, INewMenuModel, INewMenuItemModel, IStudentStatusConfig, IBaseMenuStatusConfiguration } from "../interfaces/imenu-config-model";
import { IMinResponse } from "src/app/core/http/interfaces/iresponse";
import { MenuModel, MenuRolesModel } from "../interfaces/imenu-api-model";

@Injectable({
	providedIn: "root",
})
export class MenuAdminService {
	protected menuEndpoints: {
		menuTest: Endpoint;
		configuration: Endpoint;
		updateRoles: Endpoint;
		updateStatus: Endpoint;
		globalConfig: Endpoint;
		menu: Endpoint;
		saveTransformation: Endpoint;
		deleteMenu: Endpoint;
		sortMenu: Endpoint;
	};

	constructor(private http: AppHttp, private endpoints: EndpointsService) {
		this.menuEndpoints = this.endpoints.menu;
	}

	menuTest(userId: string): Observable<any> {
		this.menuEndpoints.menuTest.useWildcardParams({ userId });
		return this.http
			.get(this.menuEndpoints.menuTest)
			.pipe(map((response) => response.data))
			.pipe(catchError((response) => this.http.handleError(response, "No se pudo recuperar el menu de prueba")));
	}

	getMenuConfiguration(fullControl: boolean): Observable<IMinResponse<MenuModel, MenuRolesModel>> {
		this.menuEndpoints.configuration.useWildcardParams({ fullControl });
		return this.http
			.get(this.menuEndpoints.configuration)
			.pipe(map((response) => ({ data: response.data, additionalData: response.additionalData })))
			.pipe(catchError((response) => this.http.handleError(response, "No se pudo cargar la configuración del menu")));
	}

	updateRoles(idItem: string, roles: IRol[]) {
		this.menuEndpoints.updateRoles.useWildcardParams({ idItem: idItem });
		return this.http
			.put(this.menuEndpoints.updateRoles, roles)
			.pipe(map((response) => response.data))
			.pipe(catchError((response) => this.http.handleError(response, "No se pudo actulizar los roles del menu")));
	}

	updateMenu(menu: INewMenuItemModel) {
		return this.http
			.patch(this.menuEndpoints.menu, menu)
			.pipe(map((response) => response.data))
			.pipe(catchError((response) => this.http.handleError(response, "No se pudo actualizar la información del menu")));
	}

	saveMenu(menu: any) {
		return this.http
			.post(this.menuEndpoints.menu, menu)
			.pipe(map((response) => response.data))
			.pipe(catchError((response) => this.http.handleError(response, "No se pudo actualizar la información del menu")));
	}

	deleteMenu(menus: any) {
		return this.http
			.post(this.menuEndpoints.deleteMenu, menus)
			.pipe(map((response) => response.data))
			.pipe(catchError((response) => this.http.handleError(response, "No fué posible eliminar el menu")));
	}

	updateStatus(idItem: string, config: IStudentStatusConfig) {
		this.menuEndpoints.updateStatus.useWildcardParams({ idItem: idItem });
		return this.http
			.put(this.menuEndpoints.updateStatus, config)
			.pipe(map((response) => response.data))
			.pipe(catchError((response) => this.http.handleError(response, "No fue posible guardar la configuración de estatus del menu")));
	}

	getGlobalConfig(): Observable<IBaseMenuStatusConfiguration> {
		return this.http
		.get(this.menuEndpoints.globalConfig)
		.pipe(map((response) => response.data))
		.pipe(catchError((response) => this.http.handleError(response, "No fue posible obtener la configuración global de estatus del menu")));
	}

	updateGlobalConfig(config: IBaseMenuStatusConfiguration) {
		return this.http
		.put(this.menuEndpoints.globalConfig, config)
		.pipe(map((response) => response.data))
		.pipe(catchError((response) => this.http.handleError(response, "No fue posible guardar la configuración global de estatus del menu")));
	}


	saveTransformation(idItem, body) {
		this.menuEndpoints.saveTransformation.useWildcardParams({ idItem });

		return this.http
			.put(this.menuEndpoints.saveTransformation, body)
			.pipe(map((response) => response.isSuccessStatusCode))
			.pipe(catchError((response) => this.http.handleError(response, "No se pudo cargar la configuración del menu")));
	}

	saveMenuOrdered(menus: any) {
		return this.http
			.post(this.menuEndpoints.sortMenu, menus)
			.pipe(map((response) => response.data))
			.pipe(catchError((response) => this.http.handleError(response, "No se pudo actualizar el menu")));
	}

	private gtMenuConfigurationMoc() {
		return of(this.gtMenuConfigurationDataMoc());
	}

	private gtMenuConfigurationDataMoc() {
		return <IOriginalMenuModel>{
			main: [
				{
					id: "Level 0",
					text: "Información Financiera",
					translateKey: "financial-info",
					action: "show",
					iconKey: "fas fa-dollar-sign",
					academicFilter: null,
					data: null,
					roles: {
						Student: {},
						Admin: {
							GAE: "show",
							SuperUser: "show",
							ConfigAdmin: "show",
							CampusCoord: "show",
							CampusAssist: "show",
						},
					},
					transformations: [],
					children: [
						{
							id: "Level 1 0",
							text: "Pagos en bancos",
							translateKey: "bank-payments",
							action: "show",
							iconKey: "fas fa-money-check-alt",
							academicFilter: null,
							data: null,
							roles: {
								Student: {},
								Admin: {
									GAE: "show",
									SuperUser: "show",
									ConfigAdmin: "show",
									CampusCoord: "show",
									CampusAssist: "show",
								},
							},
							transformations: [],
							children: [
								{
									id: "Level 2 0",
									text: "Pagos en bancos",
									translateKey: "bank-payments",
									action: "show",
									iconKey: "fas fa-money-check-alt",
									academicFilter: null,
									data: null,
									roles: {
										Admin: {},
									},
									transformations: [],
									children: [
										{
											id: "Level 3 0",
											text: "Pagos en bancos",
											translateKey: "bank-payments",
											action: "show",
											iconKey: "fas fa-money-check-alt",
											academicFilter: {
												campus: [
													{
														id: "1",
														name: "marina",
													},
												],
												levels: [
													{
														id: "BC",
														name: "Bachillerato",
													},
												],
												levelsCrm: [
													{
														id: "P",
														name: "Prepa",
													},
												],
												schools: [
													{
														id: "36",
														name: "Prepa Unam",
													},
												],
												careers: [
													{
														id: "0966",
														name: "Unam",
													},
												],
											},
											data: null,
											roles: {
												Student: {},
											},
											transformations: [],
											children: [],
										},
									],
								},
							],
						},
						{
							id: "Level 1 1",
							text: "Pagos en línea",
							translateKey: "online-payments",
							action: "show",
							iconKey: "fas fa-shopping-cart",
							academicFilter: null,
							data: null,
							roles: {
								Student: {
									adult: "hide",
								},
								Admin: {
									CampusAssist: "show",
								},
							},
							transformations: [
								{
									startDate: "2019-10-08T00:00:00Z",
									endDate: "2019-10-13T20:59:59.999Z",
									type: "rules",
									id: null,
									text: "Nuevo texto en el meni",
									translateKey: "online-payments",
									action: "message",
									iconKey: "fas fa-calculator",
									academicFilter: {
										campus: [],
										levels: [],
										levelsCrm: [
											{
												id: "P",
												name: "Prepa",
											},
										],
										schools: [],
										careers: [],
									},
									data: {
										message: "Por el momento esta opción no se encuentra disponible",
										url: null,
										target: null,
									},
									roles: {
										Student: {},
										Admin: {
											GAE: "show",
											SuperUser: "show",
										},
									},
									rules: [],
									transformations: [],
									children: [],
								},
							],
							children: [],
						},
						{
							id: "Level 1 2",
							text: "Calculadora de colegiatura",
							translateKey: "tuition-calculator",
							action: "show",
							iconKey: "fas fa-calculator",
							academicFilter: {
								campus: [
									{
										id: "1",
										name: "marina",
									},
								],
								levels: [
									{
										id: "BC",
										name: "Bachillerato",
									},
								],
								levelsCrm: [
									{
										id: "P",
										name: "Prepa",
									},
								],
								schools: [
									{
										id: "36",
										name: "Prepa Unam",
									},
								],
								careers: [
									{
										id: "0966",
										name: "Unam",
									},
								],
							},
							data: {
								message: "Camara Valedor",
								url: null,
								target: null,
							},
							roles: {
								Student: {},
							},
							transformations: [
								{
									startDate: "2019-10-08T00:00:00Z",
									endDate: "2019-10-08T20:59:59.999Z",
									type: "admin",
									id: null,
									text: "Calculadora de Transformación",
									translateKey: "ha cambiado morro",
									action: "message",
									iconKey: "fas fa-calculator",
									academicFilter: {
										campus: [
											{
												id: "1",
												name: "marina",
											},
										],
										levels: [
											{
												id: "BC",
												name: "Bachillerato",
											},
										],
										levelsCrm: [
											{
												id: "P",
												name: "Prepa",
											},
										],
										schools: [
											{
												id: "36",
												name: "Prepa Unam",
											},
										],
										careers: [
											{
												id: "0966",
												name: "Unam",
											},
										],
									},
									data: {
										message: "Camara Valedor",
										url: null,
										target: null,
									},
									roles: {
										Student: {},
										Admin: {
											GAE: "show",
											SuperUser: "show",
											ConfigAdmin: "show",
											CampusCoord: "show",
											CampusAssist: "show",
										},
									},
									rules: [],
									transformations: [],
									children: [],
								},
							],
							children: [],
						},
					],
				},
			],
			perfil: [],
			additionalData: {
				Admin: {
					AsesorRegreso: "show",
					CampusAdmin: "show",
					CampusAssist: "show",
					CampusCoord: "show",
					ConfigAdmin: "show",
					ConfigCxC: "show",
					CoordAcad: "show",
					CoordCxC: "show",
					DirectorCarrera: "show",
					GAE: "show",
					GerenteRegreso: "show",
					SuperUser: "show",
				},
				Student: {
					adult: "show",
				},
				Tutor: {},
			},
		};
	}
}
