import { createReducer, on } from '@ngrx/store';
import { CONFIRMATION_STATE } from './../interfaces/confirmation.state';
import { IConfirmationState, TypeToken } from '../interfaces/iconfirmation-status';
import * as CONFIRMATION_ACTIONS from './confirmation.actions';

const _confirmationReducer = createReducer(CONFIRMATION_STATE,
    on(CONFIRMATION_ACTIONS.SET_CONFIRMATION, (state: IConfirmationState, { payload }) => {
        const token = null;
        const active = true;
        const user = null;
        const finished = false;
        return { ...state, idToken: token, active, user, finished };
    }),
    on(CONFIRMATION_ACTIONS.SET_CONFIRMATION_ID, (state: IConfirmationState, { payload, active, user, finished }) => {
        const token = { ...payload };
        return { ...state, idToken: token, active, user, finished };
    }),
    on(CONFIRMATION_ACTIONS.SET_CONFIRMATION_ACCESS, (state: IConfirmationState, { payload, active, user, finished }) => {
        const token = { ...payload };
        return { ...state, accessToken: token, active, user, finished };
    }),
    on(CONFIRMATION_ACTIONS.ADD_CONFIRMATION, (state: IConfirmationState, { payload, typeToken }) => {
        const token = { ...payload };
        if (typeToken == TypeToken.idToken) {
            return { ...state, idToken: token };
        } else {
            return { ...state, accessToken: token };
        }
    }),
    on(CONFIRMATION_ACTIONS.REMOVE_CONFIRMATION, (state: IConfirmationState, { payload }) => {
        const idToken = null;
        const accessToken = null;
        const active = false;
        const user = null;
        const error = null;
        const finished = false;
        return { idToken, accessToken, user, active, error, finished };
    }),
    on(CONFIRMATION_ACTIONS.SET_CONFIRMATION_ERROR, (state: IConfirmationState, { payload, finished }) => {
        const error = { ...payload };
        return { ...state, error, finished };
    })
);

export function ConfirmationReducer(state, action) {
    return _confirmationReducer(state, action);
}