import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { INotificationState } from '../interfaces/inotification-state';
import { BehaviorSubject, Observable } from 'rxjs';
import { INotificationData } from '../interfaces/inotification-data';
import { filter } from 'rxjs/operators';
import { getState } from '../store/notification.selectors';
import * as NOTIFICATION_ACTIONS from '../store/notification.actions';

@Injectable()
export class NotificationFacade {

  state$: Observable<INotificationState> = this.store.select(getState);
  enabled$: Observable<boolean> = this.state$.pipe(select(state => state.enabled));
  containerIsActive$: Observable<boolean> = this.state$.pipe(select(state => state.containerIsActive));
  unread$: Observable<number> = this.state$.pipe(select(state => state.unread));
  all$: Observable<INotificationData[]> = this.state$.pipe(select(state => state.notifications));
  _lastNotificationSource: BehaviorSubject<INotificationData> = new BehaviorSubject(null);
  last$: Observable<INotificationData> = this._lastNotificationSource.asObservable();

  constructor(
    private store: Store<INotificationState>
  ) { }

  getAllFor(namespace: string) {
    return this.all$.pipe(select(notifications => notifications.filter(notification =>
      notification.origin === namespace)));
  }

  getLastNotificationFor(namespace: string) {
    return this.last$.pipe(filter(notification => notification && notification.origin === namespace));
  }

  openContainer() {
    this.store.dispatch(NOTIFICATION_ACTIONS.OPEN_CONTAINER());
  }

  closeContainer() {
    this.store.dispatch(NOTIFICATION_ACTIONS.CLOSE_CONTAINER());
  }

  enable() {
    this.store.dispatch(NOTIFICATION_ACTIONS.ENABLE());
  }

  disable() {
    this.store.dispatch(NOTIFICATION_ACTIONS.DISABLE());
  }

  add(notification: INotificationData) {
    this.store.dispatch(NOTIFICATION_ACTIONS.ADD({ payload: notification }));
    this._lastNotificationSource.next(notification);
  }

  remove(notification: INotificationData) {
    this.store.dispatch(NOTIFICATION_ACTIONS.REMOVE({ payload: notification }));
  }
}
