import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppHttp } from 'src/app/core/http/services/app-http/app-http.service';
import { EndpointsService } from 'src/app/core/http/services/endpoints/endpoints.service';
import { Endpoint } from 'src/app/core/http/models/endpoint/endpoint';
import { IResponse, IMinResponse } from 'src/app/core/http/interfaces/iresponse';
import { map, catchError } from 'rxjs/operators';
import { IAdditionalData } from '../../../modules/payment/interfaces/iadditional-data';
import { IBBPaymentViewModel } from '../../../modules/payment/interfaces/ipayment-new';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private paymentEndpoints: {
    requestList: Endpoint,
    savePaymentRequest: Endpoint,
    validatePayment: Endpoint,
    requestPaymentProcess: Endpoint,
    requestPaymentProcessV2: Endpoint,
    requestPaymentFinalize: Endpoint,
    requestPaymentFinalizeV2: Endpoint,
    requestPaymentStatus: Endpoint,
    bankbridge: any,
    proofOfPayment: Endpoint,
    validatePostPayment: Endpoint,
    getPrices: Endpoint,
    cancelPayment: Endpoint,

    requestPaymentChannelProcess: Endpoint,
    requestPaymentChannelStatus: Endpoint
  };

  constructor(
    private http: AppHttp,
    private endpointsService: EndpointsService) {
    this.paymentEndpoints = this.endpointsService.payment;
  }

  savePaymentRequest(body, userId, profileUserId) {
    this.paymentEndpoints.savePaymentRequest.useWildcardParams({ userId, profileUserId });
    this.paymentEndpoints.savePaymentRequest.setCache(0);
    return this.http.post<IResponse<any>>(this.paymentEndpoints.savePaymentRequest, { ...body })
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo generar la solicitud de pago.')));
  }

  validatePayment(paymentId, profileUserId) {
    this.paymentEndpoints.validatePayment.useWildcardParams({ paymentId, profileUserId });
    this.paymentEndpoints.validatePayment.setCache(0);
    return this.http.get<IResponse<string>>(this.paymentEndpoints.validatePayment)
      .pipe(map(response => { return <IMinResponse<string, IAdditionalData>>{ data: response.data, additionalData: response.additionalData } }))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo recuperar la información de pago.')));
  }

  requestPaymentProcess(body, paymentId, profileUserId): Observable<any> {
    this.paymentEndpoints.requestPaymentProcess.useWildcardParams({ paymentId, profileUserId });
    this.paymentEndpoints.requestPaymentProcess.setCache(0);
    return this.http.post<any>(this.paymentEndpoints.requestPaymentProcess, { ...body })
      .pipe(map(response => response))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo recuperar la información de pago.')));
  }

  requestPaymentProcessV2(body, paymentId, profileUserId): Observable<IMinResponse<IBBPaymentViewModel, string>> {
    this.paymentEndpoints.requestPaymentProcessV2.useWildcardParams({ paymentId, profileUserId });
    this.paymentEndpoints.requestPaymentProcessV2.setCache(0);
    return this.http.post<any>(this.paymentEndpoints.requestPaymentProcessV2, { ...body })
      .pipe(map((response: any) => ({ data: response.data, additionalData: response.additionalData })))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo recuperar la información de pago.')));
  }

  validatePostPayment(queueId) {
    this.paymentEndpoints.validatePostPayment.useWildcardParams({ queueId });
    this.paymentEndpoints.validatePostPayment.setCache(0);
    return this.http.get<IResponse<any>>(this.paymentEndpoints.validatePostPayment)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo recuperar la información de pago.')));
  }

  requestPaymentStatus(userId, paymentId, profileUserId) {
    this.paymentEndpoints.requestPaymentFinalize.useWildcardParams({ userId, paymentId, profileUserId });
    this.paymentEndpoints.requestPaymentFinalize.setCache(0);
    return this.http.get<IResponse<any>>(this.paymentEndpoints.requestPaymentFinalize)
      .pipe(map((response: IResponse<any>) => <IMinResponse<any>>{ data: response.data, additionalData: response.additionalData }))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo recuperar la información de pago.')));
  }

  requestPaymentStatusV2(userId, paymentId, profileUserId) {
    this.paymentEndpoints.requestPaymentFinalizeV2.useWildcardParams({ userId, paymentId, profileUserId });
    this.paymentEndpoints.requestPaymentFinalizeV2.setCache(0);
    return this.http.get<IResponse<any>>(this.paymentEndpoints.requestPaymentFinalizeV2)
      .pipe(map((response: IResponse<any>) => <IMinResponse<any>>{ data: response.data, additionalData: response.additionalData }))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo recuperar la información de pago.')));
  }

  proofOfPayment(paymentId, profileUserId) {
    this.paymentEndpoints.proofOfPayment.useWildcardParams({ paymentId, profileUserId });
    this.paymentEndpoints.proofOfPayment.setCache(0);
    return this.http.get<IResponse<any, IAdditionalData>>(this.paymentEndpoints.proofOfPayment)
      .pipe(map(response => { return { data: response.data, additionalData: response.additionalData } }))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo recuperar la información de pago.')));
  }

  cancelPayment(paymentId, profileUserId) {
    this.paymentEndpoints.cancelPayment.useWildcardParams({ paymentId, profileUserId });
    this.paymentEndpoints.cancelPayment.setCache(0);
    return this.http.delete<IResponse<any>>(this.paymentEndpoints.cancelPayment)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fué posible cancelar el proceso de pago.')));
  }





  requestPaymentChanelProcess(body: any, paymentId: string, profileUserId: string, channel: string): Observable<any> {
    this.paymentEndpoints.requestPaymentChannelProcess.useWildcardParams({ paymentId, profileUserId, channel });
    this.paymentEndpoints.requestPaymentChannelProcess.setCache(0);
    return this.http.post<any>(this.paymentEndpoints.requestPaymentChannelProcess, { ...body })
      .pipe(map(response => response))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo recuperar la información de pago.')));
  }

  requestPaymentChannelStatus(paymentId: string, profileUserId: string, apiCoDiId: string, channel: string) {
    this.paymentEndpoints.requestPaymentChannelStatus.useWildcardParams({ paymentId, profileUserId, apiCoDiId, channel });
    this.paymentEndpoints.requestPaymentChannelStatus.setCache(0);
    return this.http.get<IResponse<any>>(this.paymentEndpoints.requestPaymentChannelStatus, {}, { detailsOpen: false, label:'', redirectToProfile: true })
      .pipe(map((response: IResponse<any>) => <IResponse<any>>response))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo recuperar la información de pago.')));
  }


}
