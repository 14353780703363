import { Inject, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../../environments/environment';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { UserFacade } from '../redux/user/facades/user-facade';
import { ImpersonateFacade } from '../redux/impersonate/facades/impersonate-facade';
import { NavigationFacade } from '../redux/navigation/facades/navigation-facade';
import { Router } from '@angular/router';
import { ConfirmationFacade } from '../redux/confirmation/facades/confirmation-facade';
import { EffectsModule } from '@ngrx/effects';
import { ApplicationStoreModule } from './application/application-store.module';
import { NavigationEffects } from './navigation/store/navigation.effect';
import { provideBootstrapEffects } from './bootstrap-effect-provider';
import { AppFacade } from './application/facades/app-facade';
import { BillingStoreModule } from './billing/billing-store.module';
import { ChatbotStoreModule } from './chatbot/chatbot-store.module';
import { ConfirmationStoreModule } from './confirmation/confirmation-store.module';
import { DocumentStoreModule } from './document/document-store.module';
import { EarlyEnrollmentStoreModule } from './early-enrollment/early-enrollment-store.module';
import { ImpersonateStoreModule } from './impersonate/impersonate-store.module';
import { MenuAdminStoreModule } from './menu-admin/menu-admin-store.module';
import { NavigationStoreModule } from './navigation/navigation-store.module';
import { NotificationStoreModule } from './notification/notification-store.module';
import { PaymentStoreModule } from './payment/payment-store.module';
import { ReenrollmentStoreModule } from './reenrollment/reenrollment-store.module';
import { ReportReenrollmentStoreModule } from './report-reenrollment/report-reenrollment-store.module';
import { TitleRequestStoreModule } from './title-request/title-request-store.module';
import { TransactionStoreModule } from './transactions-engine/transaction-store.module';
import { UserStoreModule } from './user/user-store.module';
import { CreateTutorStoreModule } from './create-tutor/create-tutor-store.module';
import { LogsStoreModule } from './logs/logs-store.module';
import { LogsEffects } from './logs/store/logs.effects';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forRoot([]),
    ApplicationStoreModule,
    BillingStoreModule,    
    ChatbotStoreModule,
    ConfirmationStoreModule,
    CreateTutorStoreModule,
    DocumentStoreModule,
    EarlyEnrollmentStoreModule,
    ImpersonateStoreModule,
    LogsStoreModule,
    MenuAdminStoreModule,
    NavigationStoreModule,
    NotificationStoreModule.forRoot(),
    PaymentStoreModule,
    ReenrollmentStoreModule,
    ReportReenrollmentStoreModule,
    TitleRequestStoreModule,
    TransactionStoreModule,
    UserStoreModule,
    StoreDevtoolsModule.instrument({
      maxAge: 5, // Retains last 5 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
  ],
  declarations: [],
  providers: [
    provideBootstrapEffects([
      NavigationEffects,
      LogsEffects
    ])
  ],
})
export class ReduxModule {
  
}
