export const WINDOW_TITLES = {

    /**
     * @home
     */
    'main': 'Ventanilla Virtual',
    'main.home': 'Inicio',

    /**
     * @profile
     */
    'main.user.profile': 'Perfil',

    /**
     * @impersonate
     */
    'main.impersonate': 'Impersonificar',

    /**
     * @financialInformation
     */
    'main.financial-info.bank-payments': 'Pagos en bancos',
    'main.financial-info.bank-payments-new': 'Pagos en bancos',
    'main.financial-info.online-payments': 'Pagos en línea',
    'main.financial-info.tuition-calculator': 'Calculadora de colegiatura',
    'main.financial-info.debts': 'Estado de Cuenta Resumido',
    'main.financial-info.early-enrollment': 'Pago anticipado',
    'main.finantial-info.reenrollment': 'Inscripción anticipada',

    /**
     * @schoolServices
     */
    'main.school-services.topic-list': 'Tira de materias',
    'main.school-services.topic-list-ssel': 'Tira de materias (SSEL)',
    'main.school-services.schedule': 'Horario',
    'main.school-services.history': 'Historial académico',
    'main.school-services.high-school-certificate': 'Boleta de preparatoria',
    'main.school-services.attendance': 'Detalle de asistencia',
    'main.school-services.social-service': 'Empresas servicio social',
    'main.school-services.school-calendar': 'Calendario escolar',
    'main.school-services.school-rules': 'Reglamento de alumnos',
    'main.school-services.next-schedule': 'Horarios próximos ciclos',
    'main.school-services.reinscription': 'Reinscripción (SSEL)',
    'main.school-services.re-enrollment': 'Reinscripción',
    'main.school-services.reprint-riel-ticket': 'Reimpresión comprobantes RIEL',
    'main.school-services.interquarterly': 'Inscripción intercuatrimestral',

    /**
     * @ServiceWindow
     */
    'main.service-window.supporting-document': 'Constancias',
    'main.service-window.academic-historial': 'Historial Académico',
    'main.service-window.report-card': 'Boleta',
    'main.service-window.class-withdraw': 'Baja de Materias',

    /**
     * @admin
     */
    'main.admin.tutors': 'Tutores',
    'main.admin.student-requests': 'Solicitudes de constancias',
    'main.admin.document-requests-historial': 'Solicitudes de Historial',
    'main.admin.document-requests-supporting': 'Solicitud de Constancias',
    'main.admin.schoolar-service-jobs': 'Puestos',
    'main.admin.schoolar-service-manager': 'Perfiles',
    'main.admin.schoolar-service-signatories': 'Firmantes',
    'main.admin.support-document-manager': 'Configuración de constancias',
    'main.admin.logos-manager': 'Logotipos de Constancias',
    'main.admin.blob-manager': 'Administración de Blobs',
    'main.admin.campus': 'Información del Campus',
    'main.admin.config-guidelines': 'Administración de Lineamientos',
    'main.admin.config-menu': 'Configuración de menú',
    'main.admin.informative-files-regulation': 'Administración de Reglamentos',
    'main.admin.caption-config': 'Configuración de leyendas',
    'main.admin.document-type-manager': 'Configuración de Tipos de Documentos',
    'main.admin.document-manager-of-historial': 'Configuración de Historial',
    'main.admin.document-manager-of-reportcard': 'Configuración de Boletas',
    'main.admin.document-manager-of-email': 'Configuración de Email',
    'main.additional-services.book-sale': 'Venta de Libros',
    'main.admin.reports.reenrollment': 'Reporte de Preinscritos',
    'main.admin.schoolar-service-carousel': 'Administración de carrusel',
    'main.admin.schoolar-service-circular': 'Administración de calendario y circulares',
    'main.admin.reports.interquarterly': 'Reporte Intercuatrimestral',
}

