import { Injectable } from '@angular/core';
import { Endpoint } from '../../../http/models/endpoint/endpoint';
import { AppHttp } from '../../../http/services/app-http/app-http.service';
import { EndpointsService } from '../../../http/services/endpoints/endpoints.service';
import { IResponse } from '../../../http/interfaces/iresponse';
import { IStudentData, IStudentFilters } from '../../../redux/user/interfaces/istudent-data';
import { DEFAULT_PAGINATION } from '../../../http/interfaces/ipagination';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StudentService {

  protected studentEndpoints: {
    list: Endpoint,
    tutors: Endpoint,
    update: Endpoint
  };
  constructor(
    private http: AppHttp,
    private endpoints: EndpointsService,
  ) {
    this.studentEndpoints = this.endpoints.users.student;
  }

  public getStudentList(filters: IStudentFilters) {
    const query = { ...DEFAULT_PAGINATION, ...filters };
    const params = this.http.buildQueryParams(query);
    this.studentEndpoints.list.setCache(0);
    return this.http.get<IResponse<IStudentData[]>>(this.studentEndpoints.list, { params });
  }

  public getTutors(idStudent: string) {
    this.studentEndpoints.tutors.useWildcardParams({ idStudent });
    this.studentEndpoints.tutors.setCache(0);
    return this.http.get<IResponse<any[]>>(this.studentEndpoints.tutors)
      .pipe(map(response => response.data));
  }
}
