import { createReducer, on } from '@ngrx/store';
import { TRANSACTION_STATE } from './../interfaces/transaction.state';
import { ITransactionState } from '../interfaces/itransactions-filter';
import * as TRANSACTION_ACTIONS from './transaction.actions';

const _transactionReducer = createReducer(TRANSACTION_STATE,
    on(TRANSACTION_ACTIONS.SET_TRANSACTIONS, (state: ITransactionState, { payload, payload2 }) => {
        const transactions = [...payload];
        const total = payload2;
        return { ...state, transactions, total };
    }),
    on(TRANSACTION_ACTIONS.ADD_TRANSACTIONS, (state: ITransactionState, { payload }) => {
        const transactions = [...state.transactions, ...payload];
        return { ...state, transactions };
    }),
    on(TRANSACTION_ACTIONS.ADD_TRANSACTION, (state: ITransactionState, { payload }) => {
        const transactions = [...state.transactions, payload];
        return { ...state, transactions };
    }),
    on(TRANSACTION_ACTIONS.REMOVE_TRANSACTION, (state: ITransactionState, { payload }) => {
        const transactions = [...state.transactions];
        const index = transactions.indexOf(payload);
        transactions.splice(index, 1);
        return { ...state, transactions };
    })
);

export function TransactionReducer(state, action) {
    return _transactionReducer(state, action);
}