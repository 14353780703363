import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';
import { PaymentSummaryRoutingModule } from './summary-routing.module';
import { PaymentSummaryComponent } from './pages/summary/summary.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PaymentSummaryRoutingModule
  ],
  declarations: [
      PaymentSummaryComponent
  ],
  exports: [
    PaymentSummaryComponent
  ]
})
export class PaymentSummaryModule { }
