import { Injectable } from "@angular/core";
import { Endpoint } from "../../http/models/endpoint/endpoint";
import { AppHttp } from "../../http/services/app-http/app-http.service";
import { EndpointsService } from "../../http/services/endpoints/endpoints.service";
import { Observable, of } from "rxjs";
import { map, catchError, delay } from "rxjs/operators";
import { ICalculatorResponse, IEnrollmentData, IEnrollmentPayAccessories, IEnrollmentPaymentDetails, IEnrollmentSubjectsBox } from "../../interfaces/early-enrollment/iearly-enrollment";
import { IMinResponse } from "../../http/interfaces/iresponse";

@Injectable({
  providedIn: 'root'
})
export class EarlyEnrollmentService {

  enrollmentEndpoints: {
    requestEnrollment: Endpoint,
    getEnrollment: Endpoint,
    requestCalculator: Endpoint,
    getCalculator: Endpoint,
    requestAccessoriesPayment: Endpoint,
    requestPayment: Endpoint,
    getPayment: Endpoint,
    validateRequest: Endpoint,
    getPoofofPayment: Endpoint
  }

  constructor(
    private http: AppHttp,
    private endpoints: EndpointsService,
  ) {
    this.enrollmentEndpoints = this.endpoints.earlyEnrollment;
  }

  requestEnrollment(profileId: string): Observable<string> {
    this.enrollmentEndpoints.requestEnrollment.useWildcardParams({ profileId });
    return this.http.get(this.enrollmentEndpoints.requestEnrollment)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fué posible solicitar los adeudos.')));
  }

  getEnrollment(profileId: string, requestId: string): Observable<IEnrollmentData> {
    //return of(this.getMockResponse()).pipe(delay(3000));
    this.enrollmentEndpoints.getEnrollment.useWildcardParams({ requestId, profileId });
    return this.http.get(this.enrollmentEndpoints.getEnrollment)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fué posible obtener los adeudos.')));
  }

  requestCalculator(profileId: string, requestId: string, body: any = {}): Observable<string> {
    //return of('3cd7d43a-4173-4aa8-9963-ed09d74ba54e');
    this.enrollmentEndpoints.requestCalculator.useWildcardParams({ requestId, profileId });
    return this.http.post(this.enrollmentEndpoints.requestCalculator, body)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fué posible obtener servicios.')));
  }

  getCalculator(profileId, requestId): Observable<ICalculatorResponse> {
    //return of(this.getCalculatorMock());
    this.enrollmentEndpoints.getCalculator.useWildcardParams({ requestId, profileId });
    return this.http.get(this.enrollmentEndpoints.getCalculator)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo recuperar los datos de pago.')));
  }

  requestAccessoriesPayment(profileId, requestId, body: IEnrollmentPayAccessories = {} as IEnrollmentPayAccessories): Observable<IMinResponse<string, boolean>> {
    //return of('3cd7d43a-4173-4aa8-9963-ed09d74ba54e');
    this.enrollmentEndpoints.requestAccessoriesPayment.useWildcardParams({ requestId, profileId });
    return this.http.post(this.enrollmentEndpoints.requestAccessoriesPayment, body)
      .pipe(map(response => { return { data: response.data, additionalData: response.additionalData } }))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo recuperar los datos de pago.')));
  }

  requestPayment(profileId, requestId, OnlineAmount, PositiveBalanceAmount, pbCantModify, body: any = {}): Observable<IMinResponse<string, boolean>> {
    //return of('3cd7d43a-4173-4aa8-9963-ed09d74ba54e');
    this.enrollmentEndpoints.requestPayment.useWildcardParams({ requestId, profileId, OnlineAmount, PositiveBalanceAmount, pbCantModify });
    return this.http.post(this.enrollmentEndpoints.requestPayment, body)
      .pipe(map(response => { return { data: response.data, additionalData: response.additionalData } }))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo recuperar los datos de pago.')));
  }

  getPaymentRequest(userId, profileId, paymentId): Observable<any> {
    console.log(userId, profileId, paymentId);
    this.enrollmentEndpoints.getPayment.useWildcardParams({ paymentId, profileId });
    return this.http.get(this.enrollmentEndpoints.getPayment)
      .pipe(map(response => { return { data: response.data, additionalData: response.additionalData } }))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo recuperar los datos de pago.')));
  }

  validateRequest(requestId: string, profileId: string): Observable<boolean> {
    if (requestId == '3cd7d43a-4173-4aa8-9963-ed09d74ba54e') {
      return of(true).pipe(delay(2000));
    }
    this.enrollmentEndpoints.validateRequest.useWildcardParams({ requestId, profileId });
    return this.http.get(this.enrollmentEndpoints.validateRequest)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No fué posible comprobar el estatus de los adeudos.')));
  }

  getPoofofPayment(profileId, requestId): Observable<any> {
    this.enrollmentEndpoints.getPoofofPayment.useWildcardParams({ profileId, requestId });
    return this.http.get(this.enrollmentEndpoints.getPoofofPayment)
      .pipe(map(response => response.data))
      .pipe(catchError(response => this.http.handleError(response, 'No se pudo recuperar los datos de pago.')));
  }


  private getMockResponse(): IEnrollmentData {
    return <IEnrollmentData>{
      studentInfo: {
        "fullUserName": "AGUIRRE MORALES AARON ALONSO",
        "sisAccount": "319009397",
        "generalAverage": "   8.88",
        "lastCycleAverage": "  10.00",
        "progress": "  37.30",
        "campusName": "GUADALAJARA",
        "timestamp": "05/10/2021 11:18:17"
      },
      subjectsBox: [
        <IEnrollmentSubjectsBox>{ id: '1', description: 'FACS Colegiatura', subjectsOffer: 1, usedSubjects: 0, paidSubjects: 0, toPaid: 0 },
        <IEnrollmentSubjectsBox>{ id: '2', description: 'Ingles', subjectsOffer: 2, usedSubjects: 0, paidSubjects: 0, toPaid: 0 }
      ],
      positiveBalance: 27701.0,
      schoolarshipPercent: 35,
      nextCycle: '2022-2',
      alternatives: [
        {
          id: 'PA01',
          description: 'Pago una exhibición'
        },
        {
          id: 'PA002',
          description: 'Pago en tres exhibiciones'
        }
      ],
      accessories: []
    };
  }

  private getCalculatorMock() {
    return <IEnrollmentPaymentDetails[]>[
      { balance: 6500, description: 'Colegiatura', schoolarShip: 1000, discount: 0, subtotal: 5500 }
    ];
  }

}
