import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ConfigService } from '../../../../core/config/services/config.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@UntilDestroy()
@Component({
  selector: 'app-landing-layout',
  templateUrl: './landing-layout.component.html',
  styleUrls: ['./landing-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LandingLayoutComponent implements OnInit {

  open: boolean = false;
  isHandset = false;
  isHandset$: Observable<boolean> =
  this.breakpointObserver.observe(
    [Breakpoints.XSmall,
    Breakpoints.Handset])
    .pipe(map(result => result.matches));

  constructor(
    private config: ConfigService,
    private breakpointObserver: BreakpointObserver
  ) { 
    this.isHandset$.subscribe(handset => this.isHandset = handset);
  }

  urlLegacy: string = `${this.config.get('ssel.url')}${this.config.get('ssel.legacyLogin')}`;

  ngOnInit() {
  }

  toggle() {
    this.open = !this.open;
  }

}
