import { createReducer, on } from '@ngrx/store';
import { IReenrollmentState } from '../interfaces/ireenrollment-status';
import * as REENROLLMENT_ACTIONS from './reenrollment.actions';
import { REENROLLMENT_STATE } from './../interfaces/reenrollment.state';

const _reenrollmentReducer = createReducer(
  REENROLLMENT_STATE,
  on(REENROLLMENT_ACTIONS.SET_TOKEN_DATA, (state: IReenrollmentState, { payload }) => ({
    ...state,
    tokenData: payload,
  })),
  on(REENROLLMENT_ACTIONS.SET_KEY_CEMDI_DATA, (state: IReenrollmentState, { payload }) => ({
    ...state,
    apiKeyCemdi: payload,
  })),
  on(REENROLLMENT_ACTIONS.SET_STUDENT_DATA, (state: IReenrollmentState, { payload }) => ({
    ...state,
    studentData: payload,
  })),
  on(REENROLLMENT_ACTIONS.SET_SUBJECTS_DATA, (state: IReenrollmentState, { payload }) => ({
    ...state,
    subjectsData: payload,
  })),
  on(REENROLLMENT_ACTIONS.SET_PAYMENT_RESUME_DATA, (state: IReenrollmentState, { payload }) => ({
    ...state,
    paymentResumeData: payload,
  })),
  on(REENROLLMENT_ACTIONS.SET_PAYMENT_DATA, (state: IReenrollmentState, { payload }) => ({
    ...state,
    paymentResumeData: payload,
  })),
  on(REENROLLMENT_ACTIONS.SET_ALTERNATIVE_DATA, (state: IReenrollmentState, { payload }) => ({
    ...state,
    alternatives: payload
  })),
  on(REENROLLMENT_ACTIONS.SET_PAYMENT_PROOF_DATA, (state: IReenrollmentState, { payload }) => ({
    ...state,
    paymentProof: payload
  })),
on(REENROLLMENT_ACTIONS.REMOVE_DATA, (state: IReenrollmentState, { payload }) => ({
    ...REENROLLMENT_STATE,
    active: false,
    tokenData: null,
    studentData: null
  }))
);

export function ReenrollmentReducer(state, action) {
  return _reenrollmentReducer(state, action);
}
