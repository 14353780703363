// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-unitec-logo {
  height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/layouts/components/unitec-logo/unitec-logo.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF","sourcesContent":[".nav-unitec-logo {\r\n  height: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
