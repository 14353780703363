// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep #outerContainer .hiddenMediumView {
  display: none !important;
}

body {
  overflow: auto !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/payment/summary/pages/summary/summary.component.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;AACJ;;AAEA;EACE,yBAAA;AACF","sourcesContent":["::ng-deep #outerContainer .hiddenMediumView {\r\n    display: none !important;\r\n}\r\n\r\nbody{\r\n  overflow: auto !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
