import { CommonModule } from '@angular/common';
import { Component, type OnInit } from '@angular/core';

@Component({
	selector: 'app-sidebar-toolbar',
	templateUrl: './sidebar-toolbar.component.html',
	styleUrl: './sidebar-toolbar.component.scss',
})
export class SidebarToolbarComponent implements OnInit {

	ngOnInit(): void { }

}
