// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reset-filed-suffix {
  cursor: pointer;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/form/components/reset-field/reset-field.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kBAAA;AACF","sourcesContent":[".reset-filed-suffix {\r\n  cursor: pointer;\r\n  border-radius: 50%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
