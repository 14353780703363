import { Component, Input, OnChanges, type OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserFacade } from 'src/app/core/redux/user/facades/user-facade';
import { IUserData } from 'src/app/core/redux/user/interfaces/iuser-data';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';

@UntilDestroy()
@Component({
	selector: 'app-sidebar-profile',
	templateUrl: './sidebar-profile.component.html',
	styleUrl: './sidebar-profile.component.scss',
})
export class SidebarProfileComponent implements OnInit, OnChanges {

	avatar: any;
	@Input('flat') flat: boolean = false;

	user!: IUserData | undefined;
  
	avatars = {
	  man: 'assets/img/avatars/avatar-man.png',
	  woman: 'assets/img/avatars/avatar-woman.png',
	  generic: 'assets/img/avatars/avatar-generic.png',
	};
  
	constructor(
	  private userFacade: UserFacade,
	  private sanitizer: DomSanitizer,
	  private navigationService: NavigationService
	) { }
  
	ngOnInit() {
	  this.avatar = this.getAvatar();
	  this.userFacade.picture$.pipe(untilDestroyed(this)).subscribe((picture: string) => {
		// if (this.navigationService.getCurrentUrl().includes('user/profile')) {
		//   this.avatar = this.sanitizer.bypassSecurityTrustUrl(picture);
		// }
	  });
	  this.userFacade.profile$.subscribe(user => this.user = user);
  
	}
  
	ngOnChanges() {
	  this.avatar = this.getAvatar();
	}
  
	getAvatar() {
	  if (this.user?.gender) {
		return this.user.gender.toLowerCase() === 'f'
		  ? this.avatars.woman
		  : this.avatars.man;
	  }
	  return this.avatars.generic;
	}
  
  }