import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IReportReenrollmentState } from '../interfaces/ireport-reenrollment-status';
import * as REPORT_REENROLLMENT_ACTIONS from '../store/report-reenrollment.actions';
import { getState } from '../store/report-reenrollment.selectors';

@Injectable()
export class ReportReenrollmentFacade {
  private state$: Observable<IReportReenrollmentState> =
    this.store.select(getState);

  enrollment$: Observable<IReportReenrollmentState> = this.state$.pipe(
    select((state) => state)
  );

  tokenData$: Observable<string> = this.state$.pipe(
    select((state) => state.tokenData)
  );

  constructor(private store: Store<IReportReenrollmentState>) {}

  tokenData(data: string) {
    this.store.dispatch(REPORT_REENROLLMENT_ACTIONS.SET_TOKEN_DATA({ report: data }));
  }

  removeData(active: boolean) {
    this.store.dispatch(REPORT_REENROLLMENT_ACTIONS.REMOVE_DATA({ report: active }));
  }
}
