import { createReducer, on } from '@ngrx/store';
import { NAVIGATION_STATE } from './../interfaces/navigation.state';
import * as NAVIGATION_ACTIONS from './navigation.actions';
import { INavigationState } from '../interfaces/inavigation-state';

const _navigationReducer = createReducer(NAVIGATION_STATE,
    on(NAVIGATION_ACTIONS.SET_CONFIG, (state: INavigationState, { payload }) => ({ ...state, config: payload })),
    on(NAVIGATION_ACTIONS.SET_CURRENT, (state: INavigationState, { payload }) => ({ ...state, current: payload })),
    on(NAVIGATION_ACTIONS.OPEN_SIDEBAR, (state: INavigationState, { payload }) => {
        console.log('OPEN_SIDEBAR', payload)
        return { ...state, sidebarOpened: payload }
    })
);

export function NavigationReducer(state, action) {
    return _navigationReducer(state, action);
}