import { createAction, props } from '@ngrx/store';
import { INavigationTree } from '../interfaces/inavigation-tree';
import { INavigationNodeData } from '../interfaces/inavigation-node-data';

const SET_CONFIG = createAction('[NAVIGATION] set menu config', props<{ payload: INavigationTree<INavigationNodeData> }>());

const SET_CURRENT = createAction('[NAVIGATION] set current', props<{ payload: INavigationNodeData }>());

const OPEN_SIDEBAR = createAction('[NAVIGATION] open sidebar', props<{ payload: boolean }>());

const GET_NAVIGATION = createAction('[NAVIGATION] get campus cat', props<{ userId: string }>());

export { SET_CONFIG, SET_CURRENT, OPEN_SIDEBAR, GET_NAVIGATION }
