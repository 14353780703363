import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MenuAdminFacade } from "./facades/menu-admin.facade";
import { StoreModule } from "@ngrx/store";
import { MenuAdminReducer } from "./store/menu-admin.reducers";

@NgModule({
    imports: [
      CommonModule,
      StoreModule.forFeature('menuAdmin', MenuAdminReducer),
    ],
    providers: [
        MenuAdminFacade
    ]
  })
  export class MenuAdminStoreModule {
  }