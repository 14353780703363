import { Injectable } from '@angular/core';
import { Endpoint } from 'src/app/core/http/models/endpoint/endpoint';
import { AppHttp } from 'src/app/core/http/services/app-http/app-http.service';
import { EndpointsService } from 'src/app/core/http/services/endpoints/endpoints.service';
import { IResponse } from 'src/app/core/http/interfaces/iresponse';
import { map } from 'rxjs/operators';
import { ConfigService } from 'src/app/core/config/services/config.service';
import { HttpHeaders } from '@angular/common/http';
import { IAzureAD } from '../../redux/chatbot/interfaces/ichatbot-status';
import { IChatbotModel } from '../../redux/chatbot/interfaces/ichatbot-model';

@Injectable({
    providedIn: 'root'
})
export class ChatBotService {

    protected chatbotEndpoints: {
        authAzureAD: Endpoint,
        authBot: Endpoint,
        meBot: Endpoint
    };

    constructor(
        private http: AppHttp,
        private endpoints: EndpointsService,
        private configService: ConfigService
    ) {
        this.chatbotEndpoints = this.endpoints.chatbot;
    }

    getMeBot(userId) {
        this.chatbotEndpoints.meBot.useWildcardParams({ userId });
        return this.http.get<IResponse<IChatbotModel>>(this.chatbotEndpoints.meBot)
            .pipe(map((response: IResponse<IChatbotModel>) => response.data));
    }

    getAuthADToken() {
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        let configBody = this.configService.get('chatbot.azure', '');
        const body = {
            client_id: configBody.clientId,
            resource: configBody.resource,
            client_secret: configBody.clientSecret,
            grant_type: configBody.grantType
        };
        return this.http.post<IAzureAD>(this.chatbotEndpoints.authAzureAD, body, { headers: headers });
    }

    getBotToken() {
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        let configBody = this.configService.get('chatbot.azure', '');
        const body = {
            client_id: configBody.clientId,
            resource: configBody.resource,
            client_secret: configBody.clientSecret,
            grant_type: configBody.grantType
        };
        return this.http.post<IAzureAD>(this.chatbotEndpoints.authAzureAD, body, { headers: headers });
    }

}
