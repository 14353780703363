import { Injectable } from '@angular/core';
import { IResponse } from '../../http/interfaces/iresponse';
import { EndpointsService } from '../../http/services/endpoints/endpoints.service';
import { Endpoint } from '../../http/models/endpoint/endpoint';
import { Observable } from 'rxjs';
import { INavigationTree } from '../../redux/navigation/interfaces/inavigation-tree';
import { map } from 'rxjs/operators';
import { INavigationNodeData } from '../../redux/navigation/interfaces/inavigation-node-data';
import { HttpParams } from '@angular/common/http';
import { AppHttp } from '../../http/services/app-http/app-http.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private endpointMenu: Endpoint;
  constructor(
    private http: AppHttp,
    private endpoints: EndpointsService
  ) {
    this.endpointMenu = this.endpoints.account.menu;
  }

  /**
   * Fetch navigation nodes configuration
   */
  public getMenu(userId: string, resetCache: boolean = false): Observable<INavigationTree<INavigationNodeData>> {
    console.log('getMenu fromCache: ', resetCache);
    this.endpointMenu.useWildcardParams({ userId });
    let params = new HttpParams({
      fromObject: {
        isCch: !!resetCache
      }
    });
    if (!resetCache) {
      this.endpointMenu.setCache(1);
    }
    return this.http.get<IResponse<INavigationTree<INavigationNodeData>>>(this.endpointMenu, { params })
      .pipe(map(response => response.data));
  }
}
