import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { ApplicationReducer } from './store/application.reducer';
import { metaReducers } from './../hydration-reducer';
import { AppFacade } from './facades/app-facade';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot({'application': ApplicationReducer}, { metaReducers })
  ],
  providers:[
    AppFacade
  ]
})
export class ApplicationStoreModule { }
